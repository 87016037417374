import { Button, ButtonProps } from '@mui/material';
import { useFormikContext } from 'formik';

export default function SubmitButton({
  label = 'Submit',
  variant = 'contained',
  ...props
}: ButtonProps & { label: string }): JSX.Element {
  const formikContext = useFormikContext();
  const { isSubmitting: disabled } = formikContext;

  return (
    <Button
      sx={{
        borderRadius: '3px',
        display: 'block',
        width: '100%',
        maxWidth: '500px',
        margin: 'auto',
        padding: '10px 16px',
        color: '#fff',
        boxShadow: 'none',
        marginBottom: '20px',
        textTransform: 'unset',
        fontSize: '18px',
        backgroundColor: 'primary.main',
        '&:disabled': {
          backgroundColor: '#B3BAC5',
          color: 'white',
        },
      }}
      type="submit"
      // fullWidth={fullWidth}
      variant={variant}
      {...props}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
