import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import { Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import {
  addNewMobile,
  AddNewNumberRequestBody,
  generateOTP,
  GenerateOTPRequestBody,
  getMobileNumbers,
  GetMobilesRequestBody,
  GetMobilesResponseBody,
  MobileList,
} from '../../api/banks';
import { useTimer } from '../../hooks/useTimer';
import { browserName, logEvent } from '../../lib/helper';
import { RootStateType } from '../../store/reducers';
import { activeCategorySelector } from '../../store/selectors/multiCategory';
import { AuthState } from '../../store/types/login';
import { FormOTPInput } from '../forms/FormOTPInput';
import FormSubmitButton from '../forms/FormSubmitButton';
import { LoginParagraph } from '../login/components';
import { ResendOtp } from '../otpField';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Values {
  otp: string;
}
const initialValues: Values = { otp: '' };

const validationSchema = yup.object().shape({
  otp: yup.number().min(6, 'OTP should be 6 characters').required('OTP is required'),
});

const useStyles = makeStyles(() => ({
  btnWidth: {
    width: '130px',
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }: { theme: Theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    width: '100%',
    marginRight: 0,
    marginLeft: theme.spacing(0),
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  }),
);

const SubtleCard = styled(Card)({
  '&.MuiPaper-elevation1': {
    boxShadow:
      '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);',
  },
});

type Options = 'existing' | 'new';

export default function BottomDrawer({ flag, setFlag }: { flag: boolean; setFlag: any }) {
  const dispatch = useDispatch();
  const [otpDrawer, setOtpDrawer] = useState(false);
  const [mobilesList, setMobilesList] = useState<GetMobilesResponseBody>();
  const { decrypt, dynData } = useSelector<RootStateType, AuthState>((state) => state.auth);

  const activeCategory = useSelector(activeCategorySelector);

  const [newNumber, setNewNumber] = React.useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    async function callApi(getMobilesRequestBody: GetMobilesRequestBody) {
      try {
        const mobilesResponse = await getMobileNumbers(getMobilesRequestBody);
        setMobilesList(mobilesResponse);
      } catch (err) {
        console.error(err);
      }
    }

    const requestBody: GetMobilesRequestBody = {
      I_MOBILENUMBER: decrypt.mobile,
      I_BROWSER: browserName,
      I_ConsentHandle: dynData
    };

    callApi(requestBody);
  }, []);

  const onSubmit = async (
    values: Values,
    { setSubmitting, resetForm }: FormikHelpers<Values>,
  ) => {
    logEvent({
      category: 'OTP Verification page',
      action: 'New Mobile No. Verification',
      label:
        'Unique Customer user_id/session id on entering OTP and clicking on Confirm button',
      value: 1,
    });
    try {
      let addNewNumberRequestBody: AddNewNumberRequestBody = {
        I_BROWSER: browserName,
        I_MOBILENUMBER: decrypt.mobile,
        I_SECONDARY_MOBILE_NUMBER: newNumber,
        I_MOBOTP: values.otp,
        I_Flag: 'M',
        I_ConsentHandle: dynData,
      };
      const addMobileResponse = await addNewMobile(addNewNumberRequestBody);
      if (addMobileResponse.RESULT_CODE === '200') {
        setMessage('Verified Successfully');
        setIsVerified(true);
        setFlag(false);
        setOtpDrawer(false);
        setSubmitting(false);
        updateAlternateMobileNumber(newNumber);
        return;
      }
      throw new Error('Invalid OTP');
    } catch (e) {
      resetForm();
      setMessage('Invalid OTP');
      setHasError(true);
      //do nothing
      setSubmitting(false);
    }
  };

  const updateAlternateMobileNumber = (mobileNumber: string) => {
    dispatch({
      type: 'UPDATE_ALTERNATE_MOBILE',
      body: {
        alternateNumber: decrypt.mobile === mobileNumber ? '' : mobileNumber,
        category: activeCategory,
      },
    });

    dispatch({
      type: 'TOGGLE_FIP_DISCOVERY',
      body: { category: activeCategory, isFipDiscovery: true },
    });
    setFlag(false);
  };

  const { timer, startTimer, stopTimer, resetTimer } = useTimer({});

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, []);

  const generateNewOTP = async () => {
    stopTimer();
    let generateOTPBody: GenerateOTPRequestBody = {
      I_BROWSER: browserName,
      I_MOBILENUMBER: decrypt.mobile,
      I_SECONDARY_MOBILE_NUMBER: newNumber,
      I_ConsentHandle: dynData,
    };
    const generateOTPResponse = await generateOTP(generateOTPBody);
    if (generateOTPResponse.RESULT_CODE === '200') {
      setOtpDrawer(true);
      resetTimer();
    }
  };

  return (
    <Box>
      <Dialog
        open={flag}
        onClose={() => setFlag(false)}
        TransitionComponent={Transition}
        sx={{
          '& .MuiPaper-root': {
            // margin: 0,
            width: '100%',
            maxWidth: '500px',
            margin: 'auto',
          },
        }}
      >
        {otpDrawer ? (
          <Grid
            container
            my={2}
            sx={{ width: '100%', margin: 0, marginBottom: '20px' }}
            spacing={2}
          >
            <Grid item>
              <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
                OTP Verification
              </Typography>
            </Grid>
            <Grid item sx={{ width: '100%', padding: '16px' }}>
              <LoginParagraph>
                We have sent an OTP to your given mobile number
              </LoginParagraph>
              <Typography
                sx={{
                  fontSize: 21,
                  fontWeight: 700,
                  color: '#333',
                  marginBottom: '45px',
                }}
              >
                +91 {newNumber}
              </Typography>
              <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ handleSubmit }) => (
                  <Box component="form" noValidate onSubmit={handleSubmit}>
                    <FormOTPInput
                      name="otp"
                      label="Enter OTP"
                      isVerified={isVerified}
                      message={message}
                      hasError={hasError}
                      onSubmit={() => {
                        handleSubmit();
                      }}
                    />
                    <ResendOtp
                      counter={timer}
                      startTimer={startTimer}
                      onResendOtp={generateNewOTP}
                    />
                    {/* <Typography
                    sx={{ fontSize: '14px', color: '#00A859', marginBottom: '20px' }}
                  >
                    Verified Sucessfully
                  </Typography> */}
                    <Box sx={{ marginTop: '50px' }}>
                      <FormSubmitButton label="Confirm" />
                      <Typography sx={{ fontSize: 10, color: '#333' }}>
                        Once this number is verified, you can discover new accounts using
                        this number.
                      </Typography>
                      <Button
                        sx={{
                          fontSize: '18px',
                          color: 'primary.main',
                          fontWeight: 400,
                          textTransform: 'unset',
                          display: 'flex',
                          margin: 'auto',
                        }}
                        onClick={() => setFlag(false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                )}
              </Formik>
            </Grid>
          </Grid>
        ) : (
          <MobileNumberContainer
            onContinue={(option: Options) => {
              if (option === 'new') {
                generateNewOTP();
              } else {
                updateAlternateMobileNumber(option);
              }
            }}
            setNewNumber={setNewNumber}
            newNumber={newNumber}
            mobilesList={mobilesList}
            onCancel={() => setFlag(false)}
          />
        )}
      </Dialog>
    </Box>
  );
}

// eslint-disable-next-line no-unused-vars
type MobileNumberContainerCallback = (x: Options) => void;
// eslint-disable-next-line no-unused-vars
type setNewNumberCallback = (number: string) => void;

function MobileNumberContainer({
  onContinue,
  onCancel,
  mobilesList,
  setNewNumber,
  newNumber,
}: {
  onContinue: MobileNumberContainerCallback;
  onCancel: () => void;
  setNewNumber: setNewNumberCallback;
  newNumber: string;
  mobilesList: GetMobilesResponseBody | undefined;
}) {
  const [option, setOption] = React.useState<any>(
    mobilesList?.lst[0].MobileNumber || 'new',
  );
  const handleChange = (event: any) => {
    setOption(event.target.value);
  };
  const classes = useStyles();

  return (
    <Grid container spacing={2} sx={{ width: '100%', margin: 0, marginBottom: '20px' }}>
      <Grid item sx={{ width: '100%', padding: '0 16px' }}>
        <Box>
          <Typography variant="h6" sx={{ color: 'primary.main' }}>
            Add More Account
          </Typography>
          <Typography variant="body1">
            Select/enter mobile number registered with your bank account
          </Typography>
        </Box>
      </Grid>
      <Grid item sx={{ width: '100%', padding: '0 16px' }}>
        <RadioGroup value={option} onChange={handleChange}>
          {mobilesList?.lst.map((mobile, index) => {
            return (
              <SubtleCard key={index} style={{ marginTop: '16px' }}>
                <StyledFormControlLabel
                  value={mobile.MobileNumber}
                  control={<Radio />}
                  label={
                    <Box sx={{ ml: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ color: '#acadad', fontSize: '13px' }}
                      >
                        Already attached
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: '15px' }}>
                        +91 {mobile.MobileNumber}
                      </Typography>
                    </Box>
                  }
                />
              </SubtleCard>
            );
          })}
          <SubtleCard elevation={1} style={{ marginTop: '16px' }}>
            <StyledFormControlLabel
              value="new"
              control={<Radio />}
              disabled={mobilesList?.lst && mobilesList?.lst.length >= 5}
              label={
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <Typography variant="body1">Discover with new number</Typography>
                </Box>
              }
            />
          </SubtleCard>
          {mobilesList?.lst && mobilesList?.lst.length >= 5 && (
            <Typography color={'error'} style={{ paddingTop: '8px' }}>
              You can link only upto 5 mobile numbers
            </Typography>
          )}
        </RadioGroup>
      </Grid>
      {option === 'new' && (
        <Grid item sx={{ width: '100%', padding: '0 16px' }}>
          <TextField
            type="text"
            variant="standard"
            value={newNumber}
            fullWidth
            placeholder="Enter mobile number"
            color="primary"
            size="medium"
            onChange={(e) => {
              const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
              if (e.target.value === '') {
                setNewNumber('');
              } else {
                if (regex.test(e.target.value)) {
                  setNewNumber(e.target.value);
                }
              }
            }}
            inputProps={{ inputMode: 'numeric', maxLength: 10 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'black' }}>
                    +91-
                  </Typography>
                </InputAdornment>
              ),
            }}
          ></TextField>
          {mobilesList?.lst &&
            mobilesList.lst.filter(function (number: MobileList) {
              return number.MobileNumber === newNumber;
            }).length > 0 && (
              <Typography color={'error'} style={{ paddingTop: '8px' }}>
                The entered number is already linked.
              </Typography>
            )}
        </Grid>
      )}
      <Grid item sx={{ width: '100%', padding: '0 16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            variant="outlined"
            className={classes.btnWidth}
            sx={{
              textTransform: 'none',
              fontSize: '15px',
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btnWidth}
            sx={{ textTransform: 'none', fontSize: '15px', color: 'white' }}
            onClick={() => {
              if (
                newNumber.length < 10 ||
                (mobilesList?.lst &&
                  mobilesList.lst.filter(function (number: MobileList) {
                    return number.MobileNumber === newNumber;
                  }).length > 0)
              ) {
                logEvent({
                  category: 'Add More Account Popup',
                  action: 'Changed Mobile Number',
                  label:
                    'Unique Customer user_id/session id where customer enters different mobile number and clicks on Continue button',
                  value: 1,
                });
              }
              onContinue(option);
            }}
            disabled={
              option === 'new' &&
              (newNumber.length < 10 ||
                (mobilesList?.lst &&
                  mobilesList.lst.filter(function (number: MobileList) {
                    return number.MobileNumber === newNumber;
                  }).length > 0))
            }
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
