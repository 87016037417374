import { AnyAction } from 'redux';

import { IndBank } from '../../api/banks';
import {
  ADD_SELECTED_BANK_LIST,
  BANK_LIST,
  BankState,
  REMOVE_SELECTED_BANK_LIST,
  SET_CONSUMER_DETAILS,
} from '../types/bankListType';

const initialState: BankState = {
  bankList: null,
  selectedBank: {},
  consumer: { logo: '', consumer_id: '', purpose: '' },
};

export default function bankListReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case BANK_LIST: {
      return { ...state, bankList: action.body };
    }
    case ADD_SELECTED_BANK_LIST: {
      // TODO: to remove ability to select only one

      // const selectedBanks = state.selectedBank[action.number]
      //   ? [...state.selectedBank[action.number]]
      //   : [];
      // selectedBanks.push(action.body);
      // let selectedBank = { ...state.selectedBank, [action.number]: selectedBanks };

      const existingBanks = (state.selectedBank[action.number] as Array<IndBank>) || [];
      const selectedBank = {
        ...state.selectedBank,
        [action.number]: existingBanks.concat(action.body),
      };

      return {
        ...state,
        selectedBank: selectedBank,
      };
    }
    case REMOVE_SELECTED_BANK_LIST: {
      // TODO: to remove ability to select only one
      // const selectedBanks = state.selectedBank[action.number].filter(function (
      //   bank: IndBank,
      // ) {
      //   return bank.FIPACCREFNUM !== action.body.FIPACCREFNUM;
      // });

      // return {
      //   ...state,
      //   selectedBank: { ...state.selectedBank, [action.number]: selectedBanks },
      // };

      return {
        ...state,
        selectedBank: { ...state.selectedBank, [action.number]: [] },
      };
    }
    case SET_CONSUMER_DETAILS:{
      return {
        ...state,
        consumer: action.body
          // logo: action.body.logo,
          // // logo: '/images/fiu/AdityabirlaHF.png',
          // consumer_id: action.body.consumer_id,
          // purpose: action.body.purpose,
      };
    }
    default: {
      return state;
    }
  }
}
