import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootStateType } from '../../store/reducers';
import { MultiCategory } from '../../store/reducers/multiCategory';
import Carousel from '../Carousel/carousel';
import { Close } from '../CustomComponents/Svg';
import RejectBottomDrawer from './rejectBottomDrawer';

function ConsentDetails({
  carouselType = 'postLogin',
  toggleCarousel,
  isConsentDrawerVisible,
  consentData
}: CarouselProps) {
  const { consentDetails = null } = useSelector<RootStateType, MultiCategory>(
    (state) => state.multiCategory,
  );
  const [confirm, setConfirm] = useState(false);
  const [consentChecked, setConsentChecked] = useState(true);

  const navigate = useNavigate();

  const closeDrawer = () => {
    toggleCarousel(false);
  };

  return (
    <>
      <Drawer
        anchor={'bottom'}
        open={isConsentDrawerVisible}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: '25px 25px 0 0',
            maxWidth: '500px',
            overflowX: 'hidden',
          },
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
            margin: 'auto',
          },
        }}
      >
        <RejectBottomDrawer
          type={'reject'}
          confirm={confirm}
          setConfirm={setConfirm}
          closeDrawer={closeDrawer}
          sentvalue={1}
        />
        <Box
          style={{
            display: 'flex',
            backgroundColor: '#EBF6FF',
            fontWeight: 700,
            padding: '12px 0',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" width={'100%'}>
            <Box width="50%" />
            <Box display="flex" width="100%" justifyContent={'space-between'}>
              <Typography
                component="p"
                style={{
                  lineHeight: '2.5',
                  fontWeight: '600',
                  fontSize: '19px',
                }}
                color="primary.main"
              >
                Consent Details
              </Typography>
              <Button
                sx={{
                  textTransform: 'unset',
                  textDecoration: carouselType === 'preLogin' ? 'underline' : 'none',
                  color: '#F57071',
                }}
                onClick={() => {
                  carouselType === 'preLogin' ? setConfirm(true) : closeDrawer();
                }}
              >
                {carouselType === 'preLogin' ? 'Reject' : <Close />}
              </Button>
            </Box>
          </Box>
        </Box>

          <>
            {consentDetails ? <Carousel consentHandle={consentData[0]} /> : null}

            {carouselType === 'preLogin' ? (
              <FormGroup>
                <FormControlLabel
                  sx={{
                    margin: 0,
                    marginBottom: '25px',
                    marginLeft: '20px',
                    marginRight: '20px',
                    alignItems: 'flex-start',
                    '& .MuiCheckbox-root': {
                      padding: 0,
                      marginRight: '12px',
                    },
                    '& .MuiFormControlLabel-label': {
                      color: '#333',
                      fontSize: '14px',
                      lineHeight: '19px',
                      fontWeight: 400,
                    },
                  }}
                  control={
                    <Checkbox
                      checked={consentChecked}
                      required={true}
                      onLoad={focus}
                      onClick={() => {
                        setConsentChecked(!consentChecked);
                      }}
                    />
                  }
                  label={`By clicking Continue, I authorize  to use my financial data for the purpose of ${consentDetails?.PURPOSEDESC} on the above mentioned consent parameters`}
                />

                <Button
                  sx={{
                    borderRadius: '3px',
                    display: 'block',
                    width: '90%',
                    maxWidth: '500px',
                    margin: 'auto',
                    padding: '10px 16px',
                    color: '#fff',
                    boxShadow: 'none',
                    marginBottom: '8px',
                    marginLeft: '20px',
                    marginRight: '20px',
                    textTransform: 'unset',
                    fontSize: '18px',
                    backgroundColor: 'primary.main',
                    '&:disabled': {
                      backgroundColor: '#B3BAC5',
                      color: 'white',
                    },
                  }}
                  type="submit"
                  onClick={() => {
                    navigate('/link-account');
                  }}
                  variant={'contained'}
                  disabled={!consentChecked ? true : false}
                >
                  Continue
                </Button>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <img
                    src="/images/lock.svg"
                    alt="encrypted"
                    style={{ paddingBottom: '2px' }}
                  />
                  <Typography
                    sx={{
                      marginLeft: '4px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    All your information will be end-to-end encrypted
                  </Typography>
                </Box>
              </FormGroup>
            ) : null}
          </>
      </Drawer>
    </>
  );
}
const MemoizedConsentDetails = memo(
  ConsentDetails,
  (prev, next) => prev.isConsentDrawerVisible === next.isConsentDrawerVisible,
);

export type CarouselProps = {
  toggleCarousel: React.Dispatch<React.SetStateAction<boolean>>;
  isConsentDrawerVisible: boolean;
  carouselType: 'preLogin' | 'postLogin';
  consentData: any;
};

export default MemoizedConsentDetails;
