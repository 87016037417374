import { Checkbox, FormControlLabel, InputAdornment, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { getFipNames } from '../src/api/banks';
import { Loader, LoaderProps } from './components/CustomComponents';
import FormSelect, { FormMultiSelect, SelectOption } from './components/forms/FormSelect';
import FormTextInput from './components/forms/FormTextInput';
import Header from './components/header';
import { CustomButton } from './components/login/components';

const WIDTH = (window.innerWidth * 2) / 3;
const HEIGHT = (window.innerHeight * 2) / 3;
const LEFT = (window.innerWidth - WIDTH) / 2;
const TOP = (window.innerHeight - HEIGHT) / 2;

const MESSAGE_MAP = {
  S: 'Consent is accepted',
  F: 'Consent is rejected',
  N: 'Window is closed by user',
};

const REDIRECT_URL = 'https://uatapp.finduit.in/api/FIU/RedirectAA';
const AUTHENTICATE_URL = 'https://uatapp.finduit.in/api/FIU/Authentication';

const CATEGORY_OPTIONS: SelectOption[] = [
  { label: 'Banks', value: 'BANK' },
  { label: 'NPS', value: 'NPS' },
  { label: 'Mutual Funds', value: 'Mutual_Funds' },
  { label: 'Stocks', value: 'Equities' },
  { label: 'Insurance', value: 'Insurance_policies' },
  { label: 'GST', value:'GSTR' }
];

const USECASE_OPTIONS: SelectOption[] = [
  { label: 'Banks', value: '6' },
  { label: 'Mutual Funds', value: '2' },
  { label: 'Mutual Funds - Stocks', value: '38' },
  { label: 'Banks - Mutual Funds - NPS', value: '3' },
  { label: 'Banks - Mutual Funds - NPS - Insurance - Stocks', value: '30' },
  { label: 'Banks - GST', value: '108' },
  { label: 'Multi consent', value: '188'}
];

type Values = {
  mobileNumber: string;
  panNumber: string;
  categories: string[];
  fiNames: string[];
  usecaseId: string;
};

interface Message extends MessageEvent {
  data: {
    status: 'S' | 'F';
  };
}

const Demo = () => {
  const [displayMessage, setDisplayMessage] = useState<string>('');
  const [fipOptions, setFipOptions] = useState<any[]>([]);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [loader, setLoader] = useState<LoaderProps>({ isOpen: false, info: '' });

  useEffect(() => {
    (async () => {
      const response = await getFipNames();
      const { lst = [] } = response;
      const options = lst.map((opt: any) => {
        return {
          category: opt.FI_CATEGORY,
          value: opt.FIPID,
          label: opt.FIP_NAME,
        };
      });
      setFipOptions(options);
    })();
  }, []);

  const getRedirectionUrl = async (args: {
    aaId: string;
    fipId: string;
    addfip: 'True' | 'False';
    useCaseid: string;
    token: string;
    sessionId: string;
    pan: string;
  }) => {
    try {
      setLoader({ ...loader, isOpen: true, info: 'Redirecting...' });
      const { aaId, fipId, addfip, useCaseid, token, sessionId,pan } = args;
      const body = {
        clienttrnxid: '097c78d5-c0b4-425b-9207-f36831f3117b',
        fiuID: 'STERLING-FIU-UAT',
        userId: 'vaibhav.m@camsonline.com',
        aaCustomerHandleId: `${aaId}@CAMSAA`,
        aaCustomerMobile: aaId,
        sessionId,
        fipid: fipId ? fipId : '',
       // fipid: 'CAMSGST_UAT',
        pan: pan,
        addfip,
        useCaseid,
        redirect: 'https://google.com',
    //   clienttrnxid:"e0ce3efb-3498-4eea-bc51-5205fd7e8571",
    // fiuID:"PRUDENTADVISORY",
    // userId:"PRUDENTADVISORY_PROD",
    // aaCustomerHandleId:"9080344821@CAMSAA",
    // aaCustomerMobile:"9080344821",
    // sessionId:"72c66b19-7c96-4c91-858c-2f6044295844",
    // useCaseid:"45",
    // Integrated_trigger_sms_email:"N",
    // Pan:"GRWPD1817B",
    // account_no:"0000000000004416"
      };
      const options = {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(body),
      };
      const result = await httpRequest({ url: REDIRECT_URL, options });
      const { redirectionurl } = result;
      return redirectionurl;
    } catch (error) {
      console.log({ error });
    } finally {
      setLoader({ ...loader, isOpen: false });
    }
  };

  const authenticate = async () => {
    const options = {
      headers: {
        'Content-Type': 'application/json ',
      },
      method: 'POST',
      body: JSON.stringify({
        fiuID: 'STERLING-FIU-UAT',
        redirection_key: 'DSTKnxbUAlPukv',
        userId: 'aishwarya@fintuple.com',
        // fiuID: "PRUDENTADVISORY",
        // redirection_key: "EfbpOugGWwqqts",
        // userId: "PRUDENTADVISORY_PROD"
      }),
    };
    const response = await httpRequest({ url: AUTHENTICATE_URL, options });
    return response;
  };

  const onSubmit = async (values: Values) => {
    try {
      const { fiNames, mobileNumber, usecaseId, panNumber } = values;
      const fipIds = fiNames.filter((fi) => {
        const category = fipOptions.find((fip) => fi === fip.value).category || '';
        return selectedCategories.some((cat) => cat === category);
      });
      const { token, sessionId } = await authenticate();
      const response = await getRedirectionUrl({
        token,
        sessionId,
        aaId: mobileNumber,
        pan: panNumber,
        fipId: fipIds.join(','),
        addfip: 'True',
        useCaseid: usecaseId,
      });
      if (response) changeListener(response);
      else throw new Error('API Error : Redirect AA');
    } catch (error: any) {
      setDisplayMessage(error?.message);
    }
  };

  const changeListener = async (redirectionUrl: string) => {
    const searchParams : any = new URL(redirectionUrl).searchParams;
    const newWindow = window.open(
      new URL('?' + searchParams.toString(), location.origin),
      '_blank',
      `width=390,height=844,top=${TOP},left=${LEFT},location=yes,scrollbars=yes, status=yes`,
    );
    setDisplayMessage('Awaiting Consent');
    const interval = setInterval(() => {
      if (newWindow?.closed) {
        setDisplayMessage(MESSAGE_MAP['N']);
        clearInterval(interval);
      }
    }, 1000);

    window.addEventListener('message', (e: Message) => {
      if (e.source !== newWindow) return;
      const { status } = e.data || {};
      if (status === 'F' || status === 'S') {
        setDisplayMessage(MESSAGE_MAP[status]);
        clearInterval(interval);
        return;
      }
    });
  };

  const validationSchema = yup.object().shape({
    mobileNumber: yup
      .string()
      .matches(/^[6-9]\d{9}$/, {
        message: 'Please enter valid number.',
      })
      .required('Mobile Number is required'),
      panNumber:  yup
      .string()
      .matches(/^[a-zA-Z]{5}[0-9]{4}[a-zA-z]{1}$/, {
        message: 'Please enter valid number.',
      })
      .required('PAN Number is required'),
    usecaseId: yup.string().required('Usecase ID is required'),
  });

  const initialValues: Values = {
    mobileNumber: '',
    panNumber: '',
    categories: [],
    usecaseId: '',
    fiNames: [],
  };

  const categoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = selectedCategories.findIndex((cat) => cat === value);
    if (index === -1) {
      setSelectedCategories((prev) => [...prev, value]);
    } else {
      const updatedCategories = selectedCategories.filter((cat) => cat !== value);
      setSelectedCategories(updatedCategories);
    }
  };

  return (
    <Box component={'div'} sx={{ backgroundColor: '#f5f8f8' }}>
      <Header title="FIU Mock Page" />
      <Loader {...loader} />

      {displayMessage ? (
        <Box
          sx={{
            marginTop: '50%',
            textAlign: 'center',
            fontSize: '2rem',
          }}
        >
          {displayMessage}
        </Box>
      ) : (
        <Box
          sx={{
            width: '90%',
            marginX: 'auto',
            marginTop: '40px',
          }}
        >
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ handleSubmit }) => {
              const options = selectedCategories.length
                ? fipOptions.filter((opt) =>
                    selectedCategories.some((cat) => cat === opt.category),
                  )
                : fipOptions;

              return (
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  <FormTextInput
                    name="mobileNumber"
                    label=" Mobile Number"
                    placeholder="Enter Mobile Number"
                    margin="none"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                    sx={{ backgroundColor: 'white' }}
                    size="small"
                  />
                  <FormTextInput
                    name="panNumber"
                    label=" PAN Number"
                    placeholder="Enter Pan Number"
                    margin="none"
                    sx={{ backgroundColor: 'white' }}
                    size="small"
                  />

                  <Typography>Choose Financial Category</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    {CATEGORY_OPTIONS.map((category, index) => (
                      <FormControlLabel
                        key={index}
                        value={category.value}
                        control={
                          <Checkbox
                            icon={<img src="/images/circle-unchecked.svg" alt="" />}
                            checkedIcon={<img src="/images/circle-checked.svg" alt="" />}
                            onChange={categoryChange}
                            checked={
                              selectedCategories.findIndex(
                                (cat) => cat === category.value,
                              ) !== -1
                            }
                          />
                        }
                        label={category.label}
                        labelPlacement="start"
                        sx={{
                          bgcolor: 'common.white',
                          padding: '8px 8px 8px 16px',
                          margin: '0 16px 16px 0',
                          borderRadius: '5px',
                          width: '45%',
                          border: '1px solid rgba(0, 0, 0, 0.23)',
                          justifyContent: 'space-between',
                          height: '42px',
                        }}
                      />
                    ))}
                  </Box>

                  <FormSelect
                    name="usecaseId"
                    label=" Usecase ID"
                    placeholder="Select Usecase ID"
                    options={USECASE_OPTIONS}
                    margin="none"
                    size="small"
                    sx={{ backgroundColor: 'white' }}
                  />
                  <FormMultiSelect
                    name="fiNames"
                    label="Select Financial Information Provider (s)"
                    placeholder="Select Financial Information Provider(s)"
                    options={options}
                    margin="none"
                    multiple
                    size="small"
                    sx={{ backgroundColor: 'white' }}
                  />
                  <Box
                    sx={{
                      marginTop: '40px',
                    }}
                  >
                    <CustomButton type="submit" label="Submit" fullWidth />
                  </Box>
                </Box>
              );
            }}
          </Formik>
        </Box>
      )}
    </Box>
  );
};

export default Demo;

export type FiCategory =
  | 'BANK'
  | 'NPS'
  | 'Mutual_Funds'
  | 'Equities'
  | 'Insurance_policies'
  | 'GSTR' ;

export const httpRequest = async (args: { url: string; options: any }) => {
  try {
    const { options, url } = args;
    const result = await fetch(url, options);
    const response = await result.json();
    return response;
  } catch (error) {
    console.error(error);
  }
};
