import { Card, CardMedia, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { IMAGE_BASE_URL } from '../../api/urls';
import { RootState, RootStateType } from '../../store/reducers';
import { BankState } from '../../store/types/bankListType';
import { AuthState } from '../../store/types/login';

export default function Header({ title = '' }) {
  const { consumer } = useSelector<RootStateType, BankState>((state) => state.bank);
  const { decrypt } = useSelector<RootState, Partial<AuthState>>(
    (globalState) => globalState.auth,
  );

  // console.log("decrypt",decrypt)

  return (
    <Grid
      container
      component="header"
      sx={{
        padding: '20px 0 0',
        bgcolor: 'primary.main',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Typography
        sx={{ paddingLeft: '25px', color: '#fff', fontSize: 21, fontWeight: '500' }}
      >
        {title}
      </Typography>
      <Card
        sx={{
          width: '150px',
          background: '#f5f8f8',
          boxShadow: 'none',
          borderRadius: 0,
          height: '50px',
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'flex-end',
          clipPath: 'polygon(50px 0, 100% 0, 101% 101%, 0 101%)',
        }}
      >
        <CardMedia
          sx={{
            padding: '5px 16px',
            paddingLeft: 0,
            maxWidth: 110,
            height: 50,
            marginLeft: 'auto',
            width: 'auto',
            objectFit: 'contain',
          }}
          component="img"
          image={
            decrypt?.logo ? IMAGE_BASE_URL + decrypt.logo : IMAGE_BASE_URL + consumer.logo 
          }
          alt="logo"
        />
      </Card>
    </Grid>
  );
}
