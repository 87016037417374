import { ThemeProvider } from '@emotion/react';
import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { createContext, useContext, useMemo, useState } from 'react';

const AppThemeContext = createContext({
  // eslint-disable-next-line no-unused-vars
  updateColorScheme: (colorScheme: string) => {},
});

export function AppThemeContextProvider({ children }: { children: React.ReactNode }) {
  const [colorScheme, setColorScheme] = useState<string>();
  const colorMode = useMemo(
    () => ({
      updateColorScheme: (_colorScheme: string) => {
        setColorScheme(_colorScheme);
      },
    }),
    [],
  );

  const theme = useMemo(() => {
    const colors = (colorScheme || '#00418C,#FFFFFF').split(',').map((s) => s.trim());
    return createTheme({
      palette: {
        primary: {
          main: colors[0] || '#00418C',
        },
        secondary: {
          main: colors[1] || '#FFFFFF',
        },
        error: {
          main: red.A400,
        },
        text: {
          primary: '#020303',
          secondary: '#777F88',
        },
      },
      typography: {
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      },
    });
  }, [colorScheme]);

  return (
    <AppThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppThemeContext.Provider>
  );
}

export function useAppThemeContext() {
  return useContext(AppThemeContext);
}
