import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function RedirectingModal({
  info = '',
  moreInfo = '',
  subInfo,
  opened = false,
  setModal,
  showDemo = false,
}: {
  info: string;
  subInfo?: string;
  moreInfo?: string;
  opened?: boolean;
  setModal?: any;
  showDemo?: boolean;
}) {
  const navigate = useNavigate();
  const handleClose = () => {
    setModal(false);
  };
  return (
    <Dialog
      open={opened}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      sx={{
        '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
        '& .MuiPaper-root': {
          background: 'transparent',
          boxShadow: 'none',
          textAlign: 'center',
          margin: 1,
        },
      }}
    >
      {!showDemo ? (
        <>
          <Box
            sx={{
              display: 'inline-block',
              position: 'relative',
              width: '110px',
              height: '110px',
              zIndex: 9,
              margin: 'auto',
              marginBottom: '20px',
              '& .MuiGrid-root': {
                boxSizing: 'border-box',
                display: 'block',
                position: 'absolute',
                width: '94px',
                height: '94px',
                margin: '8px',
                border: '8px solid #DCE2F7',
                borderRadius: '50%',
                animation: 'lds-ring 2.5s cubic-bezier(0.5, 0, 0.5, 1) infinite',
                borderColor: '#00418C transparent transparent transparent',
                '&:nth-of-type(1)': {
                  animationDelay: '-0.45s',
                },
                '&:nth-of-type(2)': {
                  animationDelay: '-0.3s',
                },
                '&:nth-of-type(3)': {
                  animationDelay: '-0.15s',
                },
              },
              '@keyframes lds-ring': {
                '0%': {
                  transform: 'rotate(0deg)',
                },
                '100%': {
                  transform: 'rotate(360deg)',
                },
              },
            }}
          >
            <Grid></Grid>
            <Grid></Grid>
            <Grid></Grid>
            <Grid></Grid>
            <Grid style={{ borderColor: '#DCE2F7', zIndex: -1 }}></Grid>
          </Box>
          <Typography
            sx={{ fontSize: '20px', fontWeight: 700, color: '#fff', marginBottom: '5px' }}
          >
            {info}
            {moreInfo && (
              <>
                <br />
                {moreInfo}
              </>
            )}
          </Typography>
          {subInfo && (
            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#fff' }}>
              {subInfo}
            </Typography>
          )}
        </>
      ) : (
        <Button
          variant="contained"
          onClick={() => navigate('/demo')}
          sx={{
            fontWeight: 900,
          }}
        >
          Start Demo
        </Button>
      )}
    </Dialog>
  );
}
