import {
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from '@mui/material';
import { useField } from 'formik';

export type SelectOption = {
  label: string;
  value: any;
};

export type FormSelectProps = SelectProps & {
  name: string;
  options?: SelectOption[];
};

export default function FormSelect({
  name,
  fullWidth = true,
  label,
  margin = 'dense',
  options = [],
  ...rest
}: FormSelectProps): JSX.Element {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  return (
    <>
      <Typography
        sx={{
          fontWeight: '500',
        }}
      >
        {label}
      </Typography>
      <FormControl fullWidth={fullWidth} margin={margin} error={hasError}>
        <Select {...field} {...rest}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </>
  );
}

export function FormMultiSelect({
  name,
  fullWidth = true,
  label,
  margin = 'dense',
  options = [],
  ...rest
}: FormSelectProps): JSX.Element {
  const [field, meta, stateSetters] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;

  const { value } = meta;
  const { setValue } = stateSetters;

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value as string[];
    if (selectedValue[selectedValue.length - 1] === 'Select All') {
      const updatedValue = options.map((opt) => opt.value);
      setValue(value.length === options.length ? [] : updatedValue);
      return;
    }
    setValue(selectedValue);
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: '500',
        }}
      >
        {label}
      </Typography>
      <FormControl fullWidth={fullWidth} margin={margin} error={hasError}>
        <Select
          {...field}
          {...rest}
          multiple
          renderValue={(selectedOptions) => {
            const renderValue =
              options.length === value.length
                ? 'All Options Selected'
                : selectedOptions.join(',');
            return renderValue;
          }}
          onChange={handleChange}
        >
          <MenuItem value={'Select All'}>
            <Checkbox
              checked={options.length === value.length}
              icon={<img src="/images/circle-unchecked.svg" alt="deselect bank" />}
              checkedIcon={<img src="/images/circle-checked.svg" alt="deselect bank" />}
            />
            Select All
          </MenuItem>
          {options.map((option) => {
            const checked = value.includes(option.value);
            return (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  checked={checked}
                  icon={<img src="/images/circle-unchecked.svg" alt="deselect bank" />}
                  checkedIcon={
                    <img src="/images/circle-checked.svg" alt="deselect bank" />
                  }
                />

                {option.label}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </>
  );
}
