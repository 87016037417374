import { Box, Button, ButtonProps, Checkbox, Divider, Typography } from '@mui/material';

import { IMAGE_BASE_URL } from '../../api/urls';
import { CircleChecked, CircleUnchecked } from './Svg';

export const FipCard = ({ isChecked, logo, title, onClick }: FipCardProps) => {
  return (
    <Box
      sx={{
        boxShadow:
          '0px 6px 10px rgba(2, 3, 3, 0.03), 0px 1px 18px rgba(2, 3, 3, 0.02), 0px 3px 5px rgba(2, 3, 3, 0.04);',
        backgroundColor: '#fff',
        borderRadius: '12px',
        padding: '5px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '16px',
        cursor: 'pointer',
        height: '64px',
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', padding: '12px 12px', height: '56px' }}>
        <img
          src={logo ? IMAGE_BASE_URL + logo : '/images/Bank Icon.svg '}
          alt="Bank-Logo"
          width={45}
          height={30}
          //using a fallback image if the image fails to load from server
          onError={(event) => {
            //@ts-ignore
            event.target.src = '/images/Bank Icon.svg';
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: '18px',
            fontWeight: '500',
            padding: '10px',
          }}
        >
          {title}
        </div>
      </Box>

      <Checkbox
        icon={<CircleUnchecked />}
        checkedIcon={<CircleChecked />}
        checked={isChecked}
        color="primary"
        value={title}
      />
    </Box>
  );
};

export const AccountHeader = ({ logo, title }: AccountHeaderProps) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 20px',
        }}
      >
        <Typography
          sx={{
            color: '#000',
            fontSize: '16px',
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
        <img
          src={logo ? IMAGE_BASE_URL + logo : '/images/Bank Icon.svg'}
          onError={(e) => {
            //@ts-ignore
            e.target.src = '/images/Bank Icon.svg';
          }}
          alt="icon"
          width={75}
          height={25}
          style={
            !logo
              ? {
                  paddingLeft: '28px',
                }
              : {}
          }
        />
      </Box>
    </Box>
  );
};

export const Account = ({
  accountName,
  accountNumber,
  isChecked,
  isLinked,
  onClick,
  isLast,
  account
}: AccountProps) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '4px 20px',
          cursor: 'pointer',
          height: '60px',
        }}
        onClick={onClick}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="h6"
            sx={{
              color: 'text.secondary',
              fontSize: 12,
              fontWeight: 400,
              textTransform: 'capitalize',
            }}
          >
            {accountName ? accountName?.toLowerCase() : account?.StateName }
            {isLinked && (
              <span
                style={{
                  fontSize: '14px',
                  color: '#00A859',
                  fontWeight: 400,
                  display: 'inline-block',
                  marginLeft: '5px',
                }}
              >
                (Already linked)
              </span>
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: 'text.primary', fontSize: 14, fontWeight: 500 }}
          >
            {accountNumber}
          </Typography>
        </Box>

        <Checkbox
          icon={<CircleUnchecked />}
          checkedIcon={<CircleChecked />}
          checked={isChecked}
          color="primary"
          value={accountName}
        />
      </Box>
      {!isLast ? (
        <Divider
          sx={{
            margin: '0 20px',
          }}
        />
      ) : null}
    </Box>
  );
};

export const SendOtpButton = ({ title, onClick, disabled, ...props }: OtpButtonProps) => {
  return (
    <Button
      variant="contained"
      fullWidth
      sx={{
        fontSize: '16px',
        bgcolor: '#136E64',
        color: 'white',
        fontWeight: 600,
        textTransform: 'unset',
        lineHeight: 2,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        '&:hover': {
          backgroundColor: '#116057',
        },
      }}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {title}
    </Button>
  );
};

export interface FipCardProps {
  isChecked: boolean;
  onClick: () => any;
  title?: string;
  logo: string;
}

export interface OtpButtonProps extends ButtonProps {
  title?: string;
  onClick: () => any;
}

export interface AccountProps {
  accountNumber: string;
  accountName: string;
  isChecked: boolean;
  isLinked: boolean;
  isLast?: boolean;
  onClick: () => any;
  account: any;
}

export interface AccountHeaderProps {
  title?: string;
  logo?: string;
}

export * from './CategorySelectionButton';
export * from './Loader';
export * from './SkipBottomDrawer';
