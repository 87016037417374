/* eslint-disable no-unused-vars */
import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AlreadyLinkedAccountsList,
  discoverAlreadyLinkedAccount,
  discoverBanks,
  discoverBanksRequestBody,
  FailedFips,
  IndBank,
  LinkDescRequestBody,
} from '../../api/banks';
import { DecryptResponse } from '../../api/login';
import { browserName } from '../../lib/helper';
import { RootState, RootStateType } from '../../store/reducers';
import { FiCategory, MultiCategory } from '../../store/reducers/multiCategory';
import {
  activeCategorySelector,
  alternateNumberSelector,
  categoryStatusSelector,
  discoveredCategoriesSelector,
  Status,
} from '../../store/selectors/multiCategory';
import { AuthState } from '../../store/types/login';
import RejectBottomDrawer from '../ConsentBottomDrawer/rejectBottomDrawer';
import { CategoryButton, Loader, LoaderProps } from '../CustomComponents';
import Header from '../header';
import AccountSelection from './AccountSelection';
import { FipSelection } from './FipSelection';
import { NoAccounts } from './NoAccounts';
import { PanRequired } from './PanRequired';

const MultiCategoryFi = () => {
  const dispatch = useDispatch();

  const phoneNumber = useSelector(alternateNumberSelector);

  const { decrypt, dynData } = useSelector<RootState, Partial<AuthState>>(
    (globalState) => globalState.auth,
  );
  const activeCategory = useSelector(activeCategorySelector);
  const {
    [activeCategory]: { accounts, isFipDiscovery, isTouched },
  } = useSelector<RootState, MultiCategory>((globalState) => globalState.multiCategory);
  // console.log("activeCategory",activeCategory)
  const { failedFips,fiTypes } = useSelector<RootStateType, MultiCategory>(
    (state) => state.multiCategory,
  );
  // console.log("fiTypes",fiTypes)
  const [loader, setLoader] = useState<LoaderProps>({
    info: '',
    subInfo: '',
    moreInfo: '',
    isOpen: false,
  });
  const [isRejectDrawerOpen, toggleRejectDrawer] = useState<boolean>(false);
  const [isPanRequired, setIsPanRequired] = useState<boolean>(false);
  const [noNewAccounts, setNoNewAccounts] = useState<boolean>(false);

  const fetchLinkedAccounts = async (): Promise<AlreadyLinkedAccountsList[]> => {
    try {
      const requestLinked: LinkDescRequestBody = {
        I_MOBILENUMBER: decrypt!.mobile,
        I_BROWSER: browserName,
        I_ConsentHandle: dynData
      };
      const responseLinked = await discoverAlreadyLinkedAccount(requestLinked);
      const { lst } = responseLinked;
      if (lst?.length) return lst;
      throw new Error('No Linked Accounts Found');
    } catch (error) {
      return [];
    }
  };
  const fetchNonLinkedAccounts = async (
    prevFailedFips?: any,
    postLoginDiscovery = false,
  ): Promise<IndBank[]> => {
    const identifiers = decrypt!.pan
      ? [
          {
            I_Flag: 'MOBILE',
            DATA: phoneNumber ? phoneNumber : decrypt!.mobile,
            type: 'STRONG',
          },

          {
            I_Flag: 'PAN',
            DATA: decrypt!.pan,
            type: 'WEAK',
          },
        ]
      : [
          {
            I_Flag: 'MOBILE',
            DATA: phoneNumber ? phoneNumber : decrypt!.mobile,
            type: 'STRONG',
          },
        ];

    try {
      const requestNonLinked: discoverBanksRequestBody = {
        I_MOBILENUMBER: decrypt!.mobile,
        I_BROWSER: browserName,
        I_FIPID: prevFailedFips?.fipIds ? prevFailedFips?.fipIds : decrypt?.fipid || '',
        I_FIPNAME: prevFailedFips?.fipNames
          ? prevFailedFips?.fipNames
          : decrypt?.fipid || '',
        I_Identifier: identifiers,
        I_ConsentHandle: dynData,
      };
     
      const responseDiscovered = await discoverBanks(requestNonLinked);
      const { fip_NewDiscoverelist, failedFips } = responseDiscovered;
      if (accounts?.length || fip_NewDiscoverelist?.length) {
        if (decrypt?.pan) {
          setIsPanRequired(false);
        }
      }
      if (!postLoginDiscovery && failedFips) {
        dispatch({
          type: 'UPDATE_FAILED_FIPS',
          body: {
            failedFips,
          },
        });
      }
      if (fip_NewDiscoverelist?.length) {
        return fip_NewDiscoverelist;
      }

      throw new Error('No New Accounts Discovered');
    } catch (error) {
      if (decrypt?.pan) {
        setNoNewAccounts(true);
        if (!accounts?.length) {
          setIsPanRequired(true);
        }
      }
      return [];
    }
  };

  const discoverAccounts = async (
    postLoginDiscovery = false,
    retry = false,
  ): Promise<void> => {
    try {
      setLoader((prev) => {
        return { ...prev, info: 'Discovering Accounts', isOpen: true };
      });
      const linkedAccounts = await fetchLinkedAccounts();
      const nonLinkedAccounts = await fetchNonLinkedAccounts(
        retry ? failedFips : undefined,
        postLoginDiscovery,
      );
      if (postLoginDiscovery) {
        dispatch({
          type: 'ADD_NEW_ACCOUNTS',
          body: {
            accounts: [
              ...nonLinkedAccounts.map((acc) => {
                return { ...acc, isChecked: true };
              }),
              ...linkedAccounts.map((acc) => {
                return { ...acc, isChecked: true };
              }),
            ],
            fipId: decrypt?.fipid || '',
            category: activeCategory,
          },
        });
      } else {
        dispatch({
          type: 'ADD_ACCOUNTS',
          body: {
            accounts: [
              ...nonLinkedAccounts.map((acc) => {
                return { ...acc, isChecked: true };
              }),
              ...linkedAccounts.map((acc) => {
                return { ...acc, isChecked: true };
              }),
            ],
            fipId: decrypt?.fipid || '',
          },
        });
      }
    } catch (error) {
      alert(error);
      console.trace(error);
    } finally {
      setLoader((prev) => {
        return { ...prev, isOpen: false };
      });
    }
  };

  const discoverAccountsWithPan = async (failedFips?: FailedFips): Promise<void> => {
    if (!failedFips || !failedFips.fipNames || !failedFips.fipIds) {
      setNoNewAccounts(true);
      if (accounts?.length) {
        setIsPanRequired(false);
      }
      return;
    }
    try {
      setLoader((prev) => {
        return { ...prev, info: 'Discovering Accounts', isOpen: true };
      });
      const discoverAccounts = await fetchNonLinkedAccounts(failedFips, true);
      console.log("discoverAccounts", discoverAccounts)
      dispatch({
        type: 'ADD_NEW_ACCOUNTS',
        body: {
          accounts: [
            ...discoverAccounts.map((acc) => {
              return { ...acc, isChecked: false };
            }),
          ],
          fipId: decrypt?.fipid || '',
          category: activeCategory,
        },
      });
    } catch (error) {
      alert(error);
      console.trace(error);
    } finally {
      setLoader((prev) => {
        return { ...prev, isOpen: false };
      });
    }
  };

  useEffect(() => {
    if (decrypt?.fipid) {
      discoverAccounts();
      return;
    }
    dispatch({
      type: 'ADD_ACCOUNTS',
      body: {
        accounts: [],
        fipId: '',
      },
    });
  }, []);

  const isAccountsEmpty = !accounts.length;
  const headerTitle =
    isFipDiscovery || isAccountsEmpty ? 'Discover Accounts' : 'Link Accounts';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeCategory, isFipDiscovery, isAccountsEmpty]);

  return (
    <Box
      className='linkAccount'>
      <Header title={headerTitle} />
      <CategoryHeader setIsPanRequired={setIsPanRequired} />
      <Loader {...loader} />
      <RejectBottomDrawer
        sentvalue={1}
        confirm={isRejectDrawerOpen}
        setConfirm={toggleRejectDrawer}
        type="reject"
        closeDrawer={''}
      />
      <Box
        sx={{
          marginTop: '80px',
        }}
      >
        {loader.isOpen ? (
          <Box />
        ) : !decrypt.pan && isPanRequired ? (
          <PanRequired
            retryDiscovery={discoverAccountsWithPan}
            toggleRejectDrawer={toggleRejectDrawer}
            noNewAccounts={noNewAccounts}
          />
        ) : isFipDiscovery ? (
          <FipSelection />
        ) : isAccountsEmpty ? (
          <NoAccounts retryDiscovery={discoverAccounts} />
        ) : (
          <AccountSelection toggleRejectDrawer={toggleRejectDrawer} />
        )}
      </Box>
    </Box>
  );
};

const CategoryHeader = ({
  setIsPanRequired,
}: {
  setIsPanRequired: Dispatch<SetStateAction<boolean>>;
}) => {
  const categories = useSelector(discoveredCategoriesSelector);
  const statusMap = useSelector(categoryStatusSelector);
  const activeCategory = useSelector(activeCategorySelector);
  const {
    [activeCategory]: { accounts },
  } = useSelector<RootState, MultiCategory>((globalState) => globalState.multiCategory);
  const { decrypt } = useSelector<RootStateType, AuthState>((state) => state.auth);
  const pan = decrypt?.pan;
  const activeButton = useRef<any>(null);

  useEffect(() => {
    activeButton.current.scrollIntoView({ behaviour: 'smooth' });
    if (activeCategory === 'MF' || activeCategory === 'EQUITIES' || activeCategory === 'GSTR' ) {
      if (!pan || accounts.length === 0) {
        setIsPanRequired(true);
      }
    } else {
      setIsPanRequired(false);
    }
  }, [activeCategory]);

  const dispatch = useDispatch();

  const imageMap: ImageMap = {
    BANK: {
      isActive: '/images/Banks_isActive.svg',
      isInactive: '/images/Banks_inActive.svg',
      isCompletelyVerified: '/images/isVerified.svg',
      isPartiallyVerified: '/images/isPartiallyVerified.svg',
    },
    MF: {
      isActive: '/images/MF_isActive.svg',
      isInactive: '/images/MF_inActive.svg',
      isCompletelyVerified: '/images/isVerified.svg',
      isPartiallyVerified: '/images/isPartiallyVerified.svg',
    },
    NPS: {
      isActive: '/images/NPS_isActive.svg',
      isInactive: '/images/NPS_inActive.svg',
      isCompletelyVerified: '/images/isVerified.svg',
      isPartiallyVerified: '/images/isPartiallyVerified.svg',
    },
    EQUITIES: {
      isActive: '/images/Stocks_isActive.svg',
      isInactive: '/images/Stocks_inActive.svg',
      isCompletelyVerified: '/images/isVerified.svg',
      isPartiallyVerified: '/images/isPartiallyVerified.svg',
    },
    INSURANCE_POLICIES: {
      isActive: '/images/Insurnace_isActive.svg',
      isInactive: '/images/Insurance_inActive.svg',
      isCompletelyVerified: '/images/isVerified.svg',
      isPartiallyVerified: '/images/isPartiallyVerified.svg',
    },
    GSTR: {
      isActive: '/images/Gst_isActive.svg',
      isInactive: '/images/Gst_inActive.svg',
      isCompletelyVerified: '/images/isVerified.svg',
      isPartiallyVerified: '/images/isPartiallyVerified.svg',
    },
  };

  const variantMap: VariantMap = {
    isActive: 'contained',
    isInactive: 'contained',
    isPartiallyVerified: 'outlined',
    isCompletelyVerified: 'contained',
  };

  const backgroundMap: BackgroundMap = {
    isActive: 'primary.main',
    isInactive: '#ebecf0',
    isPartiallyVerified: 'transparent',
    isCompletelyVerified: '#136E64',
  };

  const colorMap: Partial<ColorMap> = {
    isPartiallyVerified: '#136E64',
    isInactive: '#000000',
  };

  const buttonLabels: { [key in FiCategory]: string } = {
    BANK: 'Banks',
    NPS: 'NPS',
    MF: 'MF',
    EQUITIES: 'Stocks',
    INSURANCE_POLICIES: 'Insurance',
    GSTR: 'GST'
  };

  return (
    <Box
      component={'div'}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'scroll',
        width: '100%',
        maxWidth: 500,
        gap: '14px',
        margin: 'auto',
        position: 'fixed',
        top: '70px',
        left: 'auto',
        padding: '15px 16px',
        backgroundColor: '#f5f8f8',
        zIndex: 1100,
        '&:hover': {
          ' &::-webkit-scrollbar': {
            width: '1px',
            height: '8px',
          },
        },
        ' &::-webkit-scrollbar': {
          width: '0px',
          height: '0px',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          background: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,0.1)',
        },
      }}
    >
      {categories.map((cat) => {
        const status = statusMap[cat];
        const border = status === 'isPartiallyVerified' ? '1px solid #136E64' : '';

        const image = imageMap[cat][status];
        const backgroundColor = backgroundMap[status];
        const buttonVariant = variantMap[status];
        const color = colorMap[status];
        const label = buttonLabels[cat];

        const ref = activeCategory === cat ? activeButton : null;

        return (
          <Box key={cat} ref={ref}>
            <CategoryButton
              backgroundColor={backgroundColor}
              border={border}
              color={color ? color : ''}
              onClick={() => {
                dispatch({ type: 'CHANGE_ACTIVE_CATEGORY', body: { category: cat } });
              }}
              startIcon={<img src={image} alt="fi-category" />}
              key={cat}
              title={label}
              variant={buttonVariant}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export type ImageMap = {
  [key in FiCategory]: {
    [key in Status]: `/images/${string}.svg`;
  };
};

export type VariantMap = {
  [key in Status]: 'outlined' | 'contained';
};

export type BackgroundMap = {
  [key in Status]: `#${string}` | 'transparent' | 'primary.main';
};

export type ColorMap = {
  [key in Status]: `#${string}`;
};

export default MultiCategoryFi;
