import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useField } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { activeCategorySelector } from '../../../store/selectors/multiCategory';

export type FormOTPInputProps = TextFieldProps & {
  name: string;
  label?: string;
  message?: string;
  otpLength?: any;
  isVerified?: boolean;
  hasError?: boolean;
  onSubmit?: () => void;
  isAlphaNumeric?: boolean;
  maxLength?: number;
};

export function FormOTPInput({
  name,
  label = 'Enter OTP',
  otpLength = 6,
  isVerified = false,
  message = '',
  hasError = false,
  onSubmit = () => {},
  isAlphaNumeric = false,
  maxLength = 6
}: FormOTPInputProps) {
  const [field, meta, { setValue }] = useField<string>(name);
  const errorText = (meta.error && meta.touched ? meta.error : '') || message;
  const hasError1 = !!errorText && hasError;
  const { value } = field;

  const paddedValue = value.padEnd(otpLength, ' ');

  const otp = paddedValue.split('').map((s) => s.trim());
  const ref = useRef<any>([]);
  const [isPasteData, setIsPasteData] = useState<boolean>(false);
  //const activeCategory = useSelector(activeCategorySelector);
 // console.log("activeCategory",activeCategory)
 const inputMode = isAlphaNumeric ? "text" : "numeric";
 //const inputProps = { inputMode: {inputMode}, maxLength: {maxLength} }

  const useStyles = makeStyles(() => ({
    root: {
      marginRight: '12px !important',
      width: '30px',
      marginBottom: '10px !important',
      '& .MuiFilledInput-root': {
        backgroundColor: 'transparent',
        '&:hover': {
          background: 'transparent',
        },
        '&::after, &::before': {
          display: 'none',
        },
      },
      '& input::placeholder': {
        color: '#AAAAAA',
        fontSize: '30px',
        opacity: 1,
      },
      '& input': {
        color: '#333',
        fontSize: '30px',
        padding: '0 5px',
        textAlign: 'center',
        borderBottom: '1px solid #C4C4C4',
        height: 'auto',
        boxSizing: 'border-box',
      },
      '& input:focus': {
        borderBottom: '1px solid #000',
      },
      '& input[value]:not([value = ""])': {
        borderBottom:
          hasError1 && otp.map((ele: string) => ele).join('').length === otpLength
            ? '1px solid red'
            : '2px solid #000',
      },
    },
  }));

  useEffect(() => {
    if (ref.current.length) {
      ref.current[0].focus();
    }
  }, []);

  const otpChange = (value: string, index: number) => {
    const pattern = isAlphaNumeric ? /^[a-zA-Z0-9]*$/ : /^[\d ]*$/
    if (!pattern.test(value) || isPasteData) {
      setIsPasteData(false);
      return;
    }
    if ((value && !otp[index].length) || !value) {
      setValue(
        otp
          .map((ele: string, _index: number) => (_index === index ? value : ele))
          .join(''),
      );
      if (index < otpLength - 1 && value) {
        ref.current[index + 1].focus();
      }
    }
    if (
      otp.map((ele: string, _index: number) => (_index === index ? value : ele)).join('')
        .length == otpLength
    ) {
      setTimeout(() => {
        onSubmit();
        ref.current[0].focus();
      }, 500);
    }
  };

  // const handleBlur = (event: any) => {
  //   if (event.target.value === '') {
  //     enteredValue = false;
  //   } else {
  //     enteredValue = true;
  //   }
  // };

  const handleKeyDown = (index: number) => (event: any) => {
    // let index = otp.filter((ele: string) => !!ele).length;
    if (event.key == 'Backspace' || event.key === 'Delete') {
      if (!otp[index]) {
        if (!(index - 1 < 0)) {
          setValue(
            otp
              .map((ele: string, _index: number) => (_index === index ? '' : ele))
              .join(''),
          );
          ref.current[index - 1].focus();
        }
      }
    }
  };

  //   const handlePaste = (event: any, index: number) => {
  //     setIsPasteData(true);
  //     event.clipboardData.items[0].getAsString((text: String) => {
  //       // do something
  //       const otpVal = (text || '').replace(/\s+/g, ' ').trim();
  //       if (otpVal.length == 6 && !isNaN(+otpVal)) {
  //         const otpArr = otpVal.split('');
  //         props.updateValue(otpArr);
  //         ref.current[5].focus();
  //       }
  //       //strip spaces & newline. check if only digit, split it into 6 digits and paste.
  //     });
  //   };
  return (
    <div className="otp-field">
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 500,
          color: '#333',
          marginBottom: '5px',
        }}
      >
        {label}
      </Typography>
      <FormControl error>
        <Box className="otp-inputs" sx={{ display: 'flex', alignItems: 'center' }}>
          {otp.map((ele: string, index: number) => (
            <TextField
              className={useStyles().root}
              inputRef={(ele) => (ref.current[index] = ele)}
              focused={ref.current === index}
              onChange={({ target: { value } }) => {
                otpChange(value, index);
              }}
              onKeyDown={handleKeyDown(index)}
              value={ele}
              key={index}
              variant="filled"
              // onPaste={(event: any) => handlePaste(event, index)}
              placeholder="&#x2022;"
              inputProps={{inputMode}}
              type="password"
              autoComplete="off"
              sx={{
                '& input': {
                  borderBottom:
                    hasError1 &&
                    otp.map((ele: string) => ele).join('').length === otpLength
                      ? '1px solid red'
                      : isVerified
                      ? '1px solid #00A859'
                      : '',
                },
              }}
            />
          ))}
        </Box>
        {hasError1 ? (
          <FormHelperText sx={{ margin: 0 }}>{errorText || message}</FormHelperText>
        ) : (
          <FormHelperText sx={{ margin: 0 }} style={{ color: 'green' }}>
            {message}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
}
