import { Alert, Icon, IconButton, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { RootStateType } from '../../store/reducers';
import { CLOSE_SNACKBAR, UIState } from '../../store/reducers/ui-reducer';

const ShowSnackbar = ({ autoHideDuration = 5000 }: any) => {
  const dispatch = useDispatch();

  const { snackbarOpen, snackbarMessage, snackbarType } = useSelector<
    RootStateType,
    UIState
  >((state) => state.ui);

  function handleClose() {
    dispatch({
      type: CLOSE_SNACKBAR,
    });
  }

  return (
    <Snackbar
      onClose={handleClose}
      open={snackbarOpen}
      autoHideDuration={autoHideDuration}
      message={snackbarMessage}
      itemType={'success'}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: snackbarType === 'success' ? '#03a95b' : '#f04e52',
          color: '#ffffff',
          fontWeight: 500,
        },
        '& .MuiAlert-icon': {
          color: '#ffffff',
        },
      }}
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
          <Icon>close</Icon>
        </IconButton>,
      ]}
    >
      <Alert onClose={handleClose} severity={snackbarType} sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default ShowSnackbar;
