/* eslint-disable no-unused-vars */
import { AnyAction } from 'redux';

import {
  AlreadyLinkedAccountsList,
  CategoryIdentifier,
  FailedFips,
  FipNewDiscoverelist,
  IndBank,
  Lst,
} from '../../api/banks';

const initialState: MultiCategory = {
  BANK: { accounts: [] },
  MF: { accounts: [] },
  NPS: { accounts: [] },
  EQUITIES: { accounts: [] },
  INSURANCE_POLICIES: { accounts: [] },
  GSTR: { accounts: [] },
};

export default function multiCategory(
  state: MultiCategory = initialState,
  action: Action,
): MultiCategory {
  const { type, body } = action;

  switch (type) {
    case 'ADD_FI_CATEGORIES': {
      const { fiTypes } = body;

      const discoveredCategories = fiTypes?.reduce<FiCategory[]>((acc, curr) => {
        if (!acc.find((cat) => cat === curr.FI_CATEGORY)) {
          acc.push(curr.FI_CATEGORY);
        }

        return acc;
      }, []);

      const isPanRequired = fiTypes?.some((category) => {
        return category.WEAK_ID1 === 'PAN' && category.WEAK_ID1_MANDATORY === 'Y';
      });

      return {
        ...state,
        discoveredCategories,
        fiTypes: fiTypes,
        isPanRequired,
        activeCategory: discoveredCategories ? discoveredCategories[0] : 'BANK',
      };
    }

    case 'ADD_ACCOUNTS': {
      const { accounts, fipId } = body;
      const { discoveredCategories = [], fiTypes = [] } = state;
      const fipIds = fipId.split(',');

      const filteredAccounts = accounts.filter((acc) =>
        fipIds.some((id) => id === acc.FIPID),
      );

      const updatedCategoryDetails = discoveredCategories.reduce<{
        [key in FiCategory]?: CategoryDetails;
      }>((accumulator, current) => {
        const currentAccounts = filterAccounts(filteredAccounts, fiTypes, current);
        accumulator[current] = {
          ...state[current],
          accounts: currentAccounts,
          isFipDiscovery: !currentAccounts.length,
        };
        return accumulator;
      }, {});

      const sortedCategories = discoveredCategories.sort((cat1, cat2) => {
        const _firstCategoryDetails = updatedCategoryDetails[cat1];
        if (_firstCategoryDetails?.accounts?.length) return -1;
        else return 1;
      });

      return {
        ...state,
        ...updatedCategoryDetails,
        discoveredCategories: sortedCategories,
        activeCategory: sortedCategories[0],
      };
    }

    case 'ADD_NEW_ACCOUNTS': {
      console.log("body",body)
      const { accounts, category, fipId } = body;
      const { fiTypes, [category]: categoryDetails } = state;
      const { accounts: oldAccounts } = categoryDetails;
      const fipIds = fipId.split(',');
      const newAccounts = accounts.filter((acc) => fipIds.some((id) => id === acc.FIPID));
      const missingFipCount = fipIds.filter((id) =>
        accounts.every((acc) => acc.FIPID !== id),
      ).length;

      const updatedAccounts = filterAccounts(
        getUniqueAccounts(oldAccounts, newAccounts),
        fiTypes,
        category,
      ).map((acc) => {
        return { ...acc, isChecked: true };
      });

      return {
        ...state,
        [category]: { ...categoryDetails, accounts: updatedAccounts, missingFipCount },
      };
    }

    case 'CHANGE_ACTIVE_CATEGORY': {
      const { category } = body;
      return { ...state, activeCategory: category };
    }
    case 'CLEAR_MISSING_FIP_COUNT': {
      const { category } = body;
      const { [category]: categoryDetails } = state;
      return { ...state, [category]: { ...categoryDetails, missingFipCount: null } };
    }

    case 'SELECT_ACCOUNT': {
      const { selectedAccount, category } = body;
      const categoryDetails = state[category];
      const accounts = categoryDetails?.accounts || [];

      const isChecked =
        accounts &&
        accounts.find((acc) => acc.FIPACCREFNUM === selectedAccount.FIPACCREFNUM)
          ?.isChecked;

      const updatedAccounts = accounts.map((acc) => {
        if (acc.FIPACCREFNUM === selectedAccount.FIPACCREFNUM) {
          return { ...acc, isChecked: !isChecked };
        }
        return acc;
      });

      return { ...state, [category]: { ...categoryDetails, accounts: updatedAccounts } };
    }

    case 'SELECT_ACCOUNTS_ON_FIRST_RENDER': {
      const { category } = body;
      const { [category]: categoryDetails } = state;
      const { accounts } = categoryDetails;
      const updatedAccounts = accounts.map((acc) => {
        return { ...acc, isChecked: true };
      });

      return {
        ...state,
        [category]: { ...categoryDetails, isTouched: true, accounts: updatedAccounts },
      };
    }

    case 'STORE_CONSENT_CAROUSEL_DETAILS': {
      return { ...state, consentDetails: body.consentDetails };
    }

    case 'TOGGLE_FIP_DISCOVERY': {
      const { category, isFipDiscovery } = body;
      const { [category]: categoryDetails } = state;
      return { ...state, [category]: { ...categoryDetails, isFipDiscovery } };
    }

    case 'UPDATE_LINKED_ACCOUNTS': {
      const { verifiedAccounts, linkedAccounts, category } = body;
      const { accounts, verifiedFip = [] } = state[category];

      const fipId = verifiedAccounts[0].FIPID;

      const filteredAccounts = linkedAccounts.filter((acc) =>
        verifiedAccounts.some(
          (verifiedAcc) => verifiedAcc.FIPACCREFNUM === acc.FIPACCREFNUM,
        ),
      );

      const updatedAccounts = accounts.map((acc) => {
        const isRecentlyVerified = filteredAccounts.find(
          (fA) => fA.FIPACCREFNUM === acc.FIPACCREFNUM,
        );
        if (isRecentlyVerified) return { ...isRecentlyVerified, isChecked: true };
        else return acc;
      });

      return {
        ...state,
        [category]: {
          ...state[category],
          accounts: updatedAccounts,
          verifiedFip: verifiedFip.includes(fipId)
            ? verifiedFip
            : [...verifiedFip, fipId],
        },
      };
    }

    case 'UPDATE_ALTERNATE_MOBILE': {
      const { category, alternateNumber } = body;
      return { ...state, [category]: { ...state[category], alternateNumber } };
    }
    case 'UPDATE_FAILED_FIPS': {
      const { failedFips } = body;
      return { ...state, failedFips: failedFips };
    }

    default:
      return state;
  }
}

export type MultiCategory = Partial<{
  activeCategory: FiCategory;
  discoveredCategories: FiCategory[];
  fiTypes: CategoryIdentifier[];
  consentDetails?: Lst | any;
  isPanRequired: boolean;
  failedFips: FailedFips
}> & { [key in FiCategory]: CategoryDetails };

export type CategoryDetails = {
  alternateNumber?: string;
  isFipDiscovery?: boolean;
  accounts: Account[];
  isTouched?: boolean;
  verifiedFip?: string[];
  missingFipCount?: number | null;
};

export interface Action extends AnyAction {
  body: {
    category: FiCategory;
    alternateNumber: string;
    fipId: string;
    fiTypes?: CategoryIdentifier[];
    isLinked: boolean;
    accounts: Account[];
    selectedAccount: IndBank | AlreadyLinkedAccountsList;
    isFipDiscovery: boolean;
    verifiedAccounts: FipNewDiscoverelist[];
    linkedAccounts: AlreadyLinkedAccountsList[];
    consentDetails: Lst;
    failedFips: FailedFips;
  };
}

export function filterAccounts<T extends Account>(
  accountsList: T[] = [],
  fiTypesResponse: CategoryIdentifier[] = [],
  category: FiCategory,
): T[] {
  const fiTypes: string[] = [];

  fiTypesResponse.forEach((catByType) => {
    const { FI_CATEGORY, FI_TYPE } = catByType;
    if (FI_CATEGORY === category && fiTypes.indexOf(FI_TYPE) === -1) {
      fiTypes.push(FI_TYPE);
    }
  });

  return accountsList.filter((acc) => {
    const { FITYPE } = acc;
    return !!fiTypes.find((fiType) => fiType === FITYPE);
  });
}

export function getUniqueAccounts<T extends Account>(
  oldAccounts: T[],
  newAccounts: T[],
): T[] {
  const combinedAccounts = oldAccounts.concat(newAccounts);

  return combinedAccounts.reduce<T[]>((previousValue: T[], currentValue: T) => {
    const index = previousValue.findIndex(
      (acc) => acc.FIPACCREFNUM === currentValue.FIPACCREFNUM,
    );
    if (index === -1) previousValue.push(currentValue);
    return previousValue;
  }, []);
}

export type Account = (AlreadyLinkedAccountsList | IndBank) & {
  isChecked: boolean;
  LOGO?: string;
  Logo?: string;
  AMCNAME?: string;
};

export type FiCategory = 'BANK' | 'NPS' | 'MF' | 'EQUITIES' | 'INSURANCE_POLICIES' | 'GSTR';
