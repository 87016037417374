import {
  FormControl,
  FormHelperText,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

function FormTextInput({
  name,
  margin = 'dense',
  fullWidth = true,
  label,
  ...rest
}: TextFieldProps & { name: string }): JSX.Element {
  const formikContext = useFormikContext();
  const { isSubmitting: disabled } = formikContext;
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  return (
    <>
      <Typography fontWeight="500">{label}</Typography>
      <FormControl fullWidth={fullWidth} margin={margin} error={hasError}>
        <TextField
          className="text-input"
          fullWidth={fullWidth}
          disabled={disabled}
          {...field}
          {...rest}
          error={hasError}
        />
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </>
  );
}

export default FormTextInput;
