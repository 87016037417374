import cryptoJs from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import ReactGA from 'react-ga4';
import * as moment from 'moment'

// Extend the Window interface
declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (message: any) => void;
    };
  }
}

import { FIPDetailsList } from '../api/banks';
import {
  DecryptResponse,
  EcRequestType,
  encryptParameters,
  EncryptRequestBody,
  EncryptResponseBody,
} from '../api/login';
import { AA_ID } from '../api/urls';
import { Account } from '../store/reducers/multiCategory';

const GA_ENABLED = import.meta.env.VITE_GA_ENABLED === 'true';

export const formatDate = (dt: string) => {
  if (!dt) return '';
  const x = new Date(dt);
  if (isNaN(x.getTime())) return '';
  return x
    .toLocaleDateString('en-IN', { month: 'short', day: '2-digit', year: '2-digit' })
    .replaceAll('/', '-');
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
};

export const browserName = (function detectBrowser() {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return 'chrome';
  }
  if (userAgent.match(/firefox|fxios/i)) {
    return 'firefox';
  }
  if (userAgent.match(/safari/i)) {
    return 'safari';
  }
  if (userAgent.match(/opr\//i)) {
    return 'opera';
  }
  if (userAgent.match(/edg/i)) {
    return 'edge';
  }
  return 'unknown';
})();

export function initializeGoogleAnalytics() {
  if (!GA_ENABLED) {
    return;
  }
  const TRACKING_ID = 'G-3TY409LFK4';
  const options = {
    trackingId: TRACKING_ID,
    debug: true,
    titleCase: false,
    gaOptions: {
      cookieDomain: 'none',
    },
  };
  ReactGA.initialize(TRACKING_ID, options);
}

export function setupGAPageView() {
  if (!GA_ENABLED) {
    return;
  }
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export function setGAUserId(userId: string) {
  if (!GA_ENABLED) {
    return;
  }
  ReactGA.set({
    userId: userId,
  });
}

export function logEvent({
  category,
  action,
  label,
  value = 1,
}: {
  category: string;
  action: string;
  label: string;
  value: 0 | 1;
}): void {
  if (!GA_ENABLED) {
    return;
  }
  console.log(category, action, label, value);
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
}

export function closeAndRedirect({
  url,
  parentStatusMessage,
  delay = true,
  decrypt,
}: {
  url?: string;
  parentStatusMessage?: 'ACCEPTED' | 'REJECTED' | 'Error while submitting consent';
  delay?: boolean;
  decrypt?: DecryptResponse;
}) {
  // Send the status to parent window.
  const statusMessageMap:any = {
    ACCEPTED: 'S',
    REJECTED: 'F',
    N: 'N',
  };

  // const messageMap = {
  //   S: 'Consent is accepted',
  //   F: 'Consent is rejected',
  //   N: 'Window is closed by user',
  // };

  const statusCode: 'S' | 'F' | 'N' = (statusMessageMap[parentStatusMessage || 'N'] ||
    'N') as 'S' | 'F' | 'N';
    if(!!window.ReactNativeWebView){
      window.ReactNativeWebView.postMessage(JSON.stringify({
        status: statusCode,
        txnid: decrypt?.txnid,
        sessionid: decrypt?.sessionid
      }));
    }
    
  if (window.opener) {
    window.opener.postMessage(
      {
        status: statusCode,
        txnid: decrypt?.txnid,
        sessionid: decrypt?.sessionid,

        // message:  messageMap[statusCode] || '' ,
      },'*');

  }
  async function doClose() {
    if (url && decrypt) {
      // redirect
      const encryptedUrl = await generateEncryptedUrl(url, decrypt, statusCode);
      window.location.replace(encryptedUrl);
    } else {
      // try to close the window only if child.
      if (window.opener) {
        window.close();
      }
    }
  }
  if (delay) {
    setTimeout(doClose, 6000);
  } else {
    doClose();
  }
}

const checkIfURLHasQueryParams = (url: string): boolean => {
  const formattedURL = new URL(url);
  return Boolean(formattedURL.search);
}

async function generateEncryptedUrl(
  url: string,
  decrypt: DecryptResponse,
  statusCode: string,
): Promise<string> {
  const { encryptResponse, reqDate } = await getEncryptedEcRes(decrypt, statusCode);
  const constructedURL = `ecres=${encryptResponse?.ecreq}&resdate=${reqDate}&fi=${Base64.stringify(
    xorEncryptWordArray(String(AA_ID), String(reqDate)),
  )}`;
  if (checkIfURLHasQueryParams(url)) {
    return `${url}&${constructedURL}`;
  }
  return `${url}?${constructedURL}`;
}

async function getEncryptedEcRes(
  decrypt: DecryptResponse,
  statusString: string,
): Promise<{
  reqDate: string;
  encryptResponse: EncryptResponseBody;
}> {
  const ecRequest: EcRequestType = {
    addfip: decrypt.addfip,
    status: statusString,
    errorCode: statusString === 'S' ? 0 : 1,
    fipid: decrypt.fipid,
    redirect: decrypt.redirect,
    sessionid: decrypt.sessionid,
    srcref: decrypt.srcref,
    txnid: decrypt.txnid,
    userid: decrypt.mobile ? decrypt.mobile + '@CAMSAA' : decrypt.userid,
  };
  const ecRequestValue = Object.keys(ecRequest).reduce(
    (currentValue, ecRequestKey, index) => {
      if (index === 0) {
        currentValue += `${ecRequestKey}=${ecRequest[ecRequestKey]}`;
      } else {
        currentValue += `&${ecRequestKey}=${ecRequest[ecRequestKey]}`;
      }
      return currentValue;
    },
    '',
  );
  const reqDate = formatDateNew();
  //console.log("reqDate",reqDate)
  const encryptReq: EncryptRequestBody = {
    fiuid: decrypt.fiuid,
    AAId: AA_ID,
    ecRequest: ecRequestValue,
    reqDate,
  };
  const encryptResponse = await encryptParameters(encryptReq);
  return { encryptResponse, reqDate };
}

function formatDateNew(){
  // const padStartZero = (len: number, str: string) => {
  //   return str.padStart(len, '0');
  // };
  // const day = padStartZero(2, String(date.getDate()));
  // const month = padStartZero(2, String(date.getMonth() + 1));
  // const year = padStartZero(2, String(date.getFullYear()));
  // const hour = padStartZero(2, String(date.getHours()));
  // const minutes = padStartZero(2, String(date.getMinutes()));
  // const millis = padStartZero(3, String(date.getMilliseconds()));
  // return Number(`${day}${month}${year}${hour}${minutes}${millis}`);
  var currentDate = new Date();

// Extracting individual components
var day = ('0' + currentDate.getDate()).slice(-2);
var month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Months are 0-based
var year = currentDate.getFullYear();
var hours = ('0' + currentDate.getHours()).slice(-2);
var minutes = ('0' + currentDate.getMinutes()).slice(-2);
var milliseconds = ('00' + currentDate.getMilliseconds()).slice(-3);
// Format the date
var formattedDate = day + month + year + hours + minutes + milliseconds;
 // const date =  moment().format('DDMMYYYYHHmmSSS')
  return formattedDate;
}

export function groupAccountsByFipId<T extends Account>(
  accountsList: T[],
): {
  [key: string]: T[];
} {
  const groupedAccounts: {
    [key: string]: T[];
  } = {};

  accountsList.forEach((acc: any) => {
    const { FIPID } = acc;
    if (groupedAccounts[FIPID]?.length) {
      groupedAccounts[FIPID].push(acc);
    } else {
      groupedAccounts[FIPID] = [acc];
    }
  });

  return groupedAccounts;
}

export const getFipDetailsList = (
  accountsList: any[],
  mobile: string,
): FIPDetailsList[] => {
  return accountsList.map((acc) => {
    return {
      CUSTID: mobile,
      FIPID: acc.FIPID,
      FIPACCREFNUM: acc.FIPACCREFNUM,
      LINKINGREFNUM: acc.FIPACCLINKREF,
      LINKEDDATE: acc.LINKEDDATE,
      FIPACCTYPE: acc.FIPACCTYPE,
      FIPACCNUM: acc.FIPACCNUM,
      FITYPE: acc.FITYPE,
      CONSENTCOUNT: accountsList.length,
      CONSENTDATE: new Date(),
      LOGO: acc?.LOGO ? acc.LOGO : '',
      FIPNAME: acc.FIPNAME,
      FIPACCLINKREF: acc.FIPACCLINKREF,
      isCardSelected: true,
      StateName: acc.StateName
    };
  });
};

export function xorEncryptWordArray(data: string, key: string): cryptoJs.lib.WordArray {
  function keyCharAt(key: string, i: number) {
    return key.charCodeAt(Math.floor(i % key.length));
  }
  function byteArrayToWordArray(ba: any) {
    const wa: any[] = [];
    for (let i = 0; i < ba.length; i++) {
      wa[(i / 4) | 0] |= ba[i] << (24 - 8 * i);
    }
    return cryptoJs.lib.WordArray.create(wa, ba.length);
  }
  const res = data.split('').map(function(c, i) {
    return c.charCodeAt(0) ^ keyCharAt(key, i);
  });
  return byteArrayToWordArray(res);
}
