import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

import BottomDrawer from '../BottomDrawer/BottomDrawer';
import ConsentBottomDrawer from '../ConsentBottomDrawer';
import { Search } from '../CustomComponents/Svg';

export default function ConsentAndFindMore({
  label,
  addFip,
  consentData,
}: {
  label?: string;
  addFip: boolean;
  consentData?: any;
}) {
  const [addAccountDrawer, toggleAddAccountDrawer] = useState<boolean>(false);
 // console.log("consentData,",consentData)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '16px',
        mx: 1,
      }}
    >
      <ConsentBottomDrawer consentData = {consentData}/>
      {addAccountDrawer ? (
        <BottomDrawer flag={addAccountDrawer} setFlag={toggleAddAccountDrawer} />
      ) : null}

      {addFip && (
        <Button
          onClick={() => {
            toggleAddAccountDrawer(true);
          }}
          startIcon={<Search />}
          sx={{
            position: 'relative',
            '&:after': {
              position: 'absolute',
              content: '""',
              width: 'calc(100% - 8px)',
              height: '1px',
              left: 4,
              bottom: '2px',
              bgcolor: 'primary.main',
            },
          }}
        >
          <Typography
            sx={{
              // textDecoration: 'underline',
              textTransform: 'none',
              fontWeight: '600',
              fontSize: '14px',
              color: 'primary.main',
            }}
          >
            Find More {label || 'Accounts'}
          </Typography>
        </Button>
      )}
    </Box>
  );
}
