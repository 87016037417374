import { Button, Dialog, Grid, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/system';
import React from 'react';
import Contents from './contents';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TCModal({
  confirm,
  setConfirm,
}: {
  confirm: boolean;
  setConfirm: any;
}) {
  return (
    <Box>
      <Dialog
        open={confirm}
        onClose={() => setConfirm(false)}
        TransitionComponent={Transition}
        sx={{
          '& .MuiPaper-root': {
            // margin: 0,
            width: '100%',
            height: '100%',
            maxWidth: '500px',
            margin: '30px auto',
            padding: '0 20px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <Box sx={{ padding: ' 0', backgroundColor: '#fff', height: '100%' }}>
          <Grid sx={{ width: '100%', margin: 0, height: '100%', position: 'relative' }}>
            <Typography
              component="h4"
              sx={{
                textAlign: 'center',
                color: '#00418C',
                fontSize: '16px',
                fontWeight: '600',
                width: '100%',
                marginBottom: '5px',
                padding: '10px 20px',
                boxShadow: '0px 4px 3px rgba(226, 226, 226, 0.5)',
              }}
            >
              Terms & Conditions
            </Typography>
            <Grid
              item
              sx={{
                padding: '0 10px 60px',
                width: '100%',
                height: 'calc(100% - 80px)',
                overflow: 'auto',
                '& a': { wordBreak: 'break-all' },
              }}
            >
              <Contents />
            </Grid>
            <Grid
              item
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                margin: 'auto',
                width: '100%',
                maxWidth: '480px',
                background: '#fff',
                padding: '10px',
                boxShadow: '0px -4px 3px rgb(226 226 226)',
              }}
            >
              <Button
                variant="contained"
                sx={{ textTransform: 'none', fontSize: '20px', width: '100%' }}
                onClick={() => {
                  setConfirm(false);
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
}
