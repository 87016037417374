import {
  Box,
  Grid,
  InputAdornment,
  Table,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AlreadyLinkedAccountsList,
  bankDetail,
  BankList,
  discoverAlreadyLinkedAccount,
  discoverBanks,
  discoverBanksRequestBody,
  LinkDescRequestBody,
} from '../../api/banks';
import { browserName } from '../../lib/helper';
import { RootState } from '../../store/reducers';
import {
  activeCategorySelector,
  alternateNumberSelector,
  discoveredCategoriesSelector,
} from '../../store/selectors/multiCategory';
import { AuthState } from '../../store/types/login';
import { FipCard, Loader, LoaderProps, SkipBottomDrawer } from '../CustomComponents';
import { CustomButton } from '../login/components';

export const FipSelection = () => {
  const dispatch = useDispatch();

  const { decrypt, dynData } = useSelector<RootState, Partial<AuthState>>(
    (globalState) => globalState.auth,
  );

  const discoveredCategories = useSelector(discoveredCategoriesSelector);
  const activeCategory = useSelector(activeCategorySelector);
  const phoneNumber = useSelector(alternateNumberSelector);

  const isFinalCategory = discoveredCategories[length - 1] === activeCategory;

  const [loader, setLoader] = useState<LoaderProps>({ info: '', isOpen: false });
  const [fipOptions, setFipOptions] = useState<(bankDetail & { isChecked: boolean })[]>(
    [],
  );
  const [isSkipDrawerOpen, toggleSkipDrawer] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');

  const handleSearch = () => {
    return fipOptions?.filter((bankName: bankDetail & { isChecked: boolean }) =>
      bankName?.FIPNAME.toLowerCase().includes(filter.toLocaleLowerCase()),
    );
  };

  const handleSelect = (fipId: string) => {
    setFipOptions((prev) => {
      return prev.map((fip) => {
        if (fip.FIPID !== fipId) return fip;
        else return { ...fip, isChecked: !fip.isChecked };
      });
    });
  };

  const skipCategory = () => {
    const index = discoveredCategories.findIndex((cat) => cat === activeCategory) + 1;
    dispatch({
      type: 'TOGGLE_ACCOUNT_DISCOVERY',
      body: { category: activeCategory, isFipDiscovery: false },
    });
    dispatch({
      type: 'CHANGE_ACTIVE_CATEGORY',
      body: { category: discoveredCategories[index] },
    });
  };

  useEffect(() => {
    const fetchBanksList = async () => {
      try {
        setLoader({ ...loader, info: '', isOpen: true });
        const requestBody = {
          I_MOBILENUMBER: phoneNumber ? phoneNumber : decrypt?.mobile,
          I_MPIN: '111111',
          I_BROWSER: browserName,
          I_asset_class_id:
            activeCategory === 'BANK'
              ? 'BANK'
              : activeCategory === 'MF'
              ? 'MF_ETF_OTHERS'
              : activeCategory === 'NPS'
              ? 'NPS'
              : activeCategory === 'EQUITIES'
              ? 'EQUITIES'
              : activeCategory === 'INSURANCE_POLICIES'
              ? 'INSURANCE'
              : 'GSTR',
          I_SEARCHKEY: '',
          I_SEARCHPAGENATION: 'All',
          I_ConsentHandle: dynData,
        };
        //@ts-ignore
        const response = await BankList(requestBody);
        const { lst } = response;
        setFipOptions(
          lst.map((acc) => {
            return { ...acc, isChecked: false };
          }),
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoader({ ...loader, isOpen: false });
      }
    };
    fetchBanksList();
  }, [activeCategory]);

  const discoverNonLinkedAccounts = async () => {
    const identifiers = decrypt!.pan
      ? [
          {
            I_Flag: 'MOBILE',
            DATA: phoneNumber ? phoneNumber : decrypt!.mobile,
            type: 'STRONG',
          },

          {
            I_Flag: 'PAN',
            DATA: decrypt!.pan,
            type: 'WEAK',
          },
        ]
      : [
          {
            I_Flag: 'MOBILE',
            DATA: phoneNumber ? phoneNumber : decrypt!.mobile,
            type: 'STRONG',
          },
        ];
    try {
      const request: discoverBanksRequestBody = {
        I_MOBILENUMBER: phoneNumber ? phoneNumber : decrypt!.mobile,
        I_BROWSER: browserName,
        I_ConsentHandle: dynData,
        I_FIPID: fipOptions
          .filter((fip) => fip.isChecked)
          .map((fip) => fip.FIPID)
          .join(','),
        I_FIPNAME: fipOptions
          .filter((fip) => fip.isChecked)
          .map((fip) => fip.FIPID)
          .join(','),
        I_Identifier: identifiers,
      };
      const response = await discoverBanks(request);
      const { fip_NewDiscoverelist, failedFips } = response;
      if (failedFips) {
        dispatch({
          type: 'UPDATE_FAILED_FIPS',
          body: {
            failedFips,
          },
        });
      }

      if (fip_NewDiscoverelist?.length) return fip_NewDiscoverelist;
      throw new Error('No Accounts Discovered');
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const discoverLinkedAccounts = async (): Promise<AlreadyLinkedAccountsList[]> => {
    try {
      const request: LinkDescRequestBody = {
        I_MOBILENUMBER: phoneNumber ? phoneNumber : decrypt!.mobile,
        I_BROWSER: browserName,
        I_ConsentHandle: dynData
      };
      const response = await discoverAlreadyLinkedAccount(request);
      const { lst } = response;
      if (lst?.length) return lst;
      throw new Error('No Linked Accounts Found');
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const findAccounts = async (): Promise<void> => {
    try {
      setLoader({ ...loader, isOpen: true, info: 'Discovering Accounts' });

      const fipId = fipOptions
        .filter((fip) => fip.isChecked)
        .map((fip) => fip.FIPID)
        .join(',');

      const linkedAccounts = await discoverLinkedAccounts();
      const nonLinkedAccounts = await discoverNonLinkedAccounts();
      dispatch({
        type: 'ADD_NEW_ACCOUNTS',
        body: {
          category: activeCategory,
          accounts: [...linkedAccounts, ...nonLinkedAccounts],
          fipId: fipId,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoader({ ...loader, isOpen: false });
      dispatch({
        type: 'TOGGLE_FIP_DISCOVERY',
        body: { isFipDiscovery: false, category: activeCategory },
      });
    }
  };

  const selectedLength = fipOptions.filter((fip) => fip.isChecked).length;

  const FooterButtons = () => {
    return (
      <Box
        style={{
          position: 'fixed',
          bottom: 24,
          left: 0,
          right: 0,
          //zIndex: 99,
          width: '100%',
          maxWidth: 500,
          margin: 'auto',
          backgroundColor: '#f5f8f8',
          padding: '20px 16px',
          marginBottom: '0',
        }}
      >
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            gap: '16px',
          }}
        >
          {length > 1 && (
            <CustomButton
              label={isFinalCategory ? 'Cancel' : 'Skip'}
              onClick={
                isFinalCategory
                  ? () => {
                      dispatch({
                        type: 'TOGGLE_FIP_DISCOVERY',
                        body: { category: activeCategory, isFipDiscovery: false },
                      });
                    }
                  : () => toggleSkipDrawer(true)
              }
              variant="outlined"
              style={{ margin: 0, maxHeight: '48px', fontSize: '16px' }}
            />
          )}
          <CustomButton
            label={`Discover Accounts : (${selectedLength}) selected`}
            onClick={findAccounts}
            style={{ margin: 0, maxHeight: '48px', fontSize: '14px' }}
            disabled={!fipOptions.filter((fip) => fip.isChecked).length}
          />
        </Grid>
      </Box>
    );
  };

  return (
    <Grid component="main">
      <Grid
        component={'section'}
        sx={{ padding: '0px 16px 150px', marginTop: '80px', backgroundColor: '#f5f8f8' }}
      >
        <Box
          sx={{
            width: '100%',
            position: 'fixed',
            top: '130px',
            left: 'auto',
            backgroundColor: '#f5f8f8',
            zIndex: 1100,
            paddingRight: '32px',
            maxWidth: '500px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            Choose{' '}
            {activeCategory === 'BANK'
              ? 'Banks'
              : activeCategory === 'NPS'
              ? 'CRAs'
              : activeCategory === 'MF'
              ? 'AMCs'
              : 'Options'}{' '}
            where you hold an account
          </Typography>
          {activeCategory === 'BANK' ? (
            <TextField
              sx={{
                '& input': { fontSize: '16px', color: '#020303' },
                padding: '4px 0px',
              }}
              variant="outlined"
              value={filter}
              fullWidth
              placeholder="Search Bank"
              color="primary"
              size="small"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img
                      src="/images/Vector.svg"
                      alt="search"
                      style={{ marginRight: '10px' }}
                    />
                  </InputAdornment>
                ),
              }}
            >
              <Typography variant="h6">Search bank</Typography>
            </TextField>
          ) : null}
        </Box>

        <TableContainer style={{ padding: '0px' }}>
          <Table aria-label="banks list" sx={{ marginTop: '40px' }}>
            {handleSearch()?.map((bank, index: number) => {
              return (
                <Box key={bank.FIPNAME + index}>
                  <FipCard
                    isChecked={bank.isChecked}
                    logo={bank.LOGO}
                    onClick={() => handleSelect(bank.FIPID)}
                    title={bank.FIPNAME}
                  />
                </Box>
              );
            })}
          </Table>
        </TableContainer>
      </Grid>
      <FooterButtons />
      <SkipBottomDrawer
        isOpen={isSkipDrawerOpen}
        textPrimary="Are you sure to skip without linking any accounts?"
        onClickPrimary={() => toggleSkipDrawer(false)}
        onClickSecondary={skipCategory}
        buttonLabelPrimary="Cancel"
        buttonLabelSecondary="Skip"
        closeDrawer={() => toggleSkipDrawer(false)}
      />
      <Loader {...loader} />
    </Grid>
  );
};
