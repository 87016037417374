import { Button } from '@mui/material';
import { ReactNode } from 'react';

export const CategoryButton = ({
  title,
  onClick,
  backgroundColor,
  border,
  color,
  startIcon,
  variant,
}: CategoryButtonProps): JSX.Element => {
  return (
    <>
      <Button
        onClick={onClick}
        startIcon={startIcon}
        variant={variant}
        sx={{
          height: '32px',
          minWidth: '60px',
          borderRadius: '16px',
          fontSize: '15px',
          lineHeight: '1.5',
          width: 'auto',
          px: '16px',
          textTransform: 'capitalize',
          backgroundColor: { backgroundColor },
          color: { color },
          border: border,
          '&:hover': {
            backgroundColor: { backgroundColor },
            border: border,
          },
        }}
      >
        {title}
      </Button>
    </>
  );
};

export type CategoryButtonProps = {
  backgroundColor: `#${string}` | 'transparent' | 'primary.main';
  border: '1px solid #136E64' | '';
  color: `#${string}` | '';
  startIcon: ReactNode;
  onClick: any;
  title: string;
  variant: 'outlined' | 'contained';
};
