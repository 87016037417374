import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

const AccountAccordion = ({ logo, title, ...props }: AccordionProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<img src="/images/Expand.svg" alt="arrow" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          border: '1px solid #136E64',
          borderRadius: '2px',
          '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        }}
      >
        <Stack>
          {logo ? (
            <img src={logo} alt="icon" />
          ) : (
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontWeight: 700,
              }}
            >
              {title}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ mr: 1 }}>
          <img
            src="/images/isPartiallyVerified.svg"
            alt="check"
            style={{ width: 12, height: 12, marginRight: '2px' }}
          />
          <Typography sx={{ fontSize: '14px', color: '#136E64', fontWeight: '500' }}>
            Verified
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

export default AccountAccordion;

export type AccordionProps = {
  logo: string;
  title: string;
} & { children: React.ReactNode };
