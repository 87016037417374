import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  bankDetail,
  BankList,
  BankListRequestBody,
  consentHandle,
  consentHandleDetailsRequestBody,
} from '../../api/banks';
import { IMAGE_BASE_URL } from '../../api/urls';
import { browserName, logEvent } from '../../lib/helper';
import { RootStateType } from '../../store/reducers';
import {
  BANK_LIST,
  BankState,
  SET_CONSUMER_DETAILS,
} from '../../store/types/bankListType';
import { AuthState } from '../../store/types/login';
import Header from '../header';
import { CustomButton, CustomHeading } from '../login/components';
import RedirectingModal from '../RedirectingModal';

const useStyles = makeStyles(() => ({
  select: {
    borderLeft: '3px solid #00418C',
  },
  unselect: {
    borderLeft: '3px solid transparent',
  },
  topUnselect: {
    border: '2px solid #d2d6d9',
  },
  topSelect: {
    border: '2px solid #00A859',
    background: '#fff',
  },
  footer: {
    marginTop: '30px',
    display: 'flex',
    height: '15vh',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

function BankCard({
  logo,
  selected = false,
  onClick,
}: {
  selected?: boolean;
  logo: string;
  onClick: () => void;
}) {
  const classes = useStyles();

  return (
    <Grid item xs={6}>
      <Box
        onClick={onClick}
        sx={{
          padding: '15px',
          borderRadius: '3px',
          position: 'relative',
          height: '72px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          cursor: 'pointer',
        }}
        className={selected ? classes.topSelect : classes.topUnselect}
      >
        <img
          style={{
            width: 'auto',
            maxWidth: '100%',
            maxHeight: '30px',
            objectFit: 'contain',
            margin: 'auto',
          }}
          src={logo}
          alt="icon"
        />
        {selected && (
          <img
            style={{ position: 'absolute', top: -10, right: -10 }}
            src="/images/green-check.svg"
            alt="check"
          />
        )}
      </Box>
    </Grid>
  );
}

export default function AddAcount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { decrypt, addNumberFlow, dynData } = useSelector<RootStateType, AuthState>(
    (state) => state.auth,
  );
  const { bankList } = useSelector<RootStateType, BankState>((state) => state.bank);
  const location = useLocation();
  const phoneNumber: string | undefined = (location.state as any)?.number;

  const [loader, setLoader] = useState({
    status: false,
    info: '',
    subInfo: '',
    moreInfo: '',
  });
  const classes = useStyles();
  const [selectedBank, setSelectedBank] = useState<bankDetail>();

  // From GETCONSENTHANDLEDETAILS API
  const [consentHandleData, setConsentHandle] = useState<any>({});

  const { consumer } = useSelector<RootStateType, BankState>((state) => state.bank);

  const [filter, setFilter] = useState('');
  const handleSearch = () => {
    return bankList?.lst.filter((bankName: bankDetail) =>
      bankName?.FIPNAME.toLowerCase().includes(filter.toLocaleLowerCase()),
    );
  };

  const handleSelect = (bank: bankDetail) => {
    if (selectedBank?.FIPID === bank.FIPID) {
      setSelectedBank(undefined);
    } else {
      setSelectedBank(bank);
    }
  };

  useEffect(() => {
    async function callApi(bankListRequestBody: BankListRequestBody) {
      try {
        const banks = await BankList(bankListRequestBody);
        dispatch({ type: BANK_LIST, body: banks });
      } catch (err) {
        console.error(err);
      }
    }

    const bankListRequestBody: BankListRequestBody = {
      I_MOBILENUMBER: decrypt!.mobile,
      I_MPIN: '111111',
      I_BROWSER: browserName,
      I_asset_class_id: 'BANK',
      I_SEARCHKEY: '',
      I_ConsentHandle: dynData,
      I_SEARCHPAGENATION: 'All',
    };

    callApi(bankListRequestBody);
    callhandleDetailsAPI();
  }, []);

  const callhandleDetailsAPI = async () => {
    const handleBody: consentHandleDetailsRequestBody = {
      I_MOBILENUMBER: decrypt!.mobile,
      I_BROWSER: browserName,
      I_ConsentHandle: decrypt!.srcref || '',
    };
    const consentResponse = await consentHandle(handleBody);
    if (consentResponse.RESULT_CODE === '200') {
      dispatch({
        type: SET_CONSUMER_DETAILS,
        body : {
        logo: consentResponse.lst[0].LOGO,
        consumer_id: consentResponse.lst[0].DATACONSUMERID,
        purpose: consentResponse.lst[0].PURPOSEDESC,
      }
      });
      setConsentHandle(consentResponse);
    }
  };

  const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);

    return (
      <span>
        {parts.filter(String).map((part, i) => {
          return regex.test(part) ? (
            <mark
              key={i}
              style={{
                fontSize: '16px',
                color: '#020303',
                backgroundColor: 'rgba(0, 65, 140, 0.3)',
              }}
            >
              {part}
            </mark>
          ) : (
            <span key={i} style={{ fontSize: '16px', color: '#020303' }}>
              {part}
            </span>
          );
        })}
      </span>
    );
  };

  return (
    <Grid component={'main'}>
      <Header title="Add Account" />
      <Grid component={'section'} sx={{ padding: '24px 16px 150px' }}>
        <Box style={{ marginBottom: '10px' }}>
          <CustomHeading info="Select Bank" />
          <Typography sx={{ fontSize: 14, color: '#333', marginBottom: '10px' }}>
            Select a bank associated with your registered{' '}
            {phoneNumber ? phoneNumber : 'mobile number'}
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {bankList?.lst
              .filter((bank: bankDetail) => bank.POPULARBANK === 'Y')
              .slice(0, 4)
              .map((bank: bankDetail) => {
                return (
                  <BankCard
                    key={bank.FIPID}
                    logo={IMAGE_BASE_URL + bank.LOGO}
                    selected={bank.FIPID === selectedBank?.FIPID}
                    onClick={() => handleSelect(bank)}
                  />
                );
              })}
          </Grid>
        </Box>
        <Box sx={{ marginTop: '20px' }}>
          <Paper elevation={1} sx={{ boxShadow: 'none' }}>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: '#00418C',
                  padding: 1,
                  fontSize: '18px',
                  fontWeight: 500,
                }}
              >
                List of All Banks
              </Typography>
              <TextField
                sx={{ '& input': { fontSize: '16px', color: '#020303' } }}
                variant="standard"
                value={filter}
                fullWidth
                placeholder="Search bank"
                color="primary"
                size="medium"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src="/images/Vector.svg"
                        alt="search"
                        style={{ marginLeft: '10px' }}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                <Typography variant="h6">Search bank</Typography>
              </TextField>
            </Box>
            <Box>
              <TableContainer style={{ height: '250px', paddingTop: '10px' }}>
                <Table aria-label="banks list">
                  <TableBody>
                    {handleSearch()?.map((bank: bankDetail) => (
                      <Box
                        key={bank.FIPID}
                        className={
                          bank.FIPID === selectedBank?.FIPID
                            ? classes.select
                            : classes.unselect
                        }
                        style={{ padding: '8px 16px', cursor: 'pointer' }}
                        onClick={() => handleSelect(bank)}
                      >
                        <TableRow>
                          <Box style={{ display: 'flex', flexDirection: 'row' }}>
                            <Highlighted text={bank.FIPNAME} highlight={filter} />

                            {bank === selectedBank && (
                              <img
                                src="/images/green-check.svg"
                                alt="check"
                                style={{ marginLeft: '20px' }}
                              />
                            )}
                          </Box>
                        </TableRow>
                      </Box>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Grid
        sx={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          background: '#fff',
          boxShadow: '0px -4px 3px rgb(226 226 226)',
          paddingTop: '10px',
          right: 0,
          width: '100%',
          maxWidth: '500px',
          margin: 'auto',
        }}
      >
        <Grid sx={{ padding: '20px 16px' }}>
          <CustomButton
            label="Discover Account"
            onClick={() => {
              if (!addNumberFlow) {
                if (decrypt?.fipid) {
                  logEvent({
                    category: 'Select Bank Screen',
                    action: 'Positive Flow - FIP selection',
                    label:
                      'Unique Customer user_id/session id on selecting a bank and clicking on Discover Account button',
                    value: 1,
                  });
                } else {
                  logEvent({
                    category: 'Select Bank Screen',
                    action: 'Negative Flow - FIP selection',
                    label:
                      'Unique Customer user_id/session id on selecting a bank and clicking on Discover Account button',
                    value: 0,
                  });
                }
              } else {
                logEvent({
                  category: 'Select Bank Screen',
                  action: 'FIP selection - addfip = True',
                  label:
                    'Unique Customer user_id/session id on selecting a bank and clicking on Discover Account button',
                  value: 1,
                });
              }
              setLoader({ ...loader, status: true, info: 'Discovering Account' }),
                navigate('/link-account', {
                  state: { choosedBank: selectedBank, phoneNumber },
                });
            }}
          />
        </Grid>
      </Grid>
      <RedirectingModal info={loader.info} opened={loader.status} />
    </Grid>
  );
}
