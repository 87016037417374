import { AlertColor } from '@mui/material';
import { AnyAction } from 'redux';

export type UIState = {
  snackbarOpen: boolean;
  snackbarMessage: string;
  snackbarType: AlertColor;
  hideDuration?: number;
};
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

const initialState: Partial<UIState> = {
  snackbarOpen: false,
  snackbarMessage: '',
  snackbarType: 'success',
  hideDuration: 5000,
};

function uiReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.snackbarMessage,
        snackbarType: action.snackbarType,
        hideDuration: action.hideDuration ? action.hideDuration : 5000,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        snackbarMessage: '',
        snackbarType: 'success',
        hideDuration: 5000,
      };
    default:
      return state;
  }
}

export default uiReducer;
