// @ts-nocheck
import { Box, styled, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import AliceCarousel, { slideTo } from 'react-alice-carousel';

import { Lst } from '../../api/banks';
import { capitalizeFirstLetter, formatDate } from '../../lib/helper';
import { Carousel1, Carousel2, Carousel3, Carousel4 } from '../CustomComponents/Svg';
// import { CustomHeading } from '../login/components';

export type CarouselProps = {
  consentHandle: [];
};

const StyledBox = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
}));

const BoxWithStyledIndicators = styled(Box)(({ theme }) => ({
  '& .alice-carousel__dots': {
    margin: 0,
    '& li': {
      backgroundColor: '#C4C4C4',
      '&.__active': {
        backgroundColor: theme.palette.primary.main,
      },
      '&.__custom': {
        backgroundColor: '',
      },
    },
  },
}));

export default function Carousel({ consentHandle }: CarouselProps) {
  const percent = 0.08;
  const section = useRef(null);
  const [padding, setPadding] = useState(0);

  const syncState = () => {
    const { current } = section;
    if (current) {
      setPadding((current as any).offsetWidth * percent);
    }
  };
  console.log("consentHandle", consentHandle)
  useEffect(syncState, []);

  const responsive = {
    0: {
      items: 1,
    },
  };
  const container = {
    // padding: '10px 30px 10px 20px',
    padding: 'auto',
    marginLeft: '10%',
    height: '100%',
  };

  const consentParameters = [
    {
      image: <Carousel1 />,
      title: `${capitalizeFirstLetter(consentHandle.FETCHTYPE)} Data Fetch`,
      description: `Your information (${consentHandle.CONSENTTYPES.toLowerCase()}) will be fetched ${
        consentHandle.FETCHTYPE === 'ONETIME'
          ? capitalizeFirstLetter(consentHandle.FETCHTYPE)
          : 'Periodically upto ' +
            consentHandle.FREQUENCYVALUE +
            ' times per ' +
            consentHandle.FREQUENCYUNIT
      }`,
    },
    {
      image: <Carousel2 />,
      title: 'Data Fetching Period',
      description: `Your statement duration will be restricted to the date range within \n ${formatDate(
        consentHandle.FIDATAFROMDATE,
      )} to ${formatDate(consentHandle.FIDATATODATE)}`,
    },
    {
      image: <Carousel3 />,
      title: 'Consent Expiry Date',
      description: `Your consent will expire by ${formatDate(
        consentHandle.MCONSENTEXPIRYDATETIME,
      )}`,
      subDesc: `Request date: ${formatDate(consentHandle.MCONSENTSTARTDATETIME)}`,
    },
    {
      image: <Carousel4 />,
      title: 'Data Purge',
      description: `Your data life expiry period is ${
        consentHandle.DATALIFEVALUE
      } ${consentHandle.DATALIFEUNIT.toLowerCase()}`,
    },
  ];

  return (
    <BoxWithStyledIndicators
      sx={{
        width: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '30px',
        '& .alice-carousel__stage': {
          display: 'flex',
        },
        '& .alice-carousel__stage-item': {
          flex: '1 0 auto',
          height: 'auto',
        },
        '& .item': {
          background: '#ccc',
          height: '100%',
        },
        '& .alice-carousel__dots': {
          margin: 0,
        },
      }}
      ref={section}
    >
      <AliceCarousel
        autoPlayInterval={4000}
        animationDuration={1000}
        disableButtonsControls
        responsive={responsive}
        paddingRight={padding}
        onResized={syncState}
        autoPlay
        infinite
        touchTracking
        mouseTracking
        autoPlayStrategy="none"
      >
        {consentParameters.map((consent, index) => {
          return (
            <Box sx={container} key={index}>
              <StyledBox
                sx={{
                  // borderWidth: '1px',
                  // borderStyle: 'solid',
                  // display: 'flex',
                  borderRadius: '5px',
                  paddingTop: '24px',
                  paddingBottom: '16px',
                  // flexDirection: 'coloumn',
                  // justifyContent: 'space-between',
                  height: '100%',
                  // alignItem='center',
                }}
              >
                <center>
                  <Box sx={{ margin: 'auto', marginRight: '15px' }}>{consent.image}</Box>
                  <StyledBox
                    sx={{
                      // borderWidth: '1px',
                      // borderStyle: 'solid',
                      display: 'flex',
                      // borderRadius: '5px',
                      // padding: '10px',
                      flexDirection: 'row',
                      // justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: '15px', fontWeight: 500, color: '#333' }}
                        >
                          {consent.title}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: '13px', fontWeight: 400, color: '#666' }}
                        >
                          {consent.description}
                        </Typography>
                        {consent.subDesc && (
                          <Typography
                            variant="body1"
                            sx={{ fontSize: '11px', fontWeight: 300, color: '#666' }}
                          >
                            {consent.subDesc}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    {/* <StyledBox
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              marginLeft: 1,
            }}
            >
            {`${index + 1}/${len}`}
          </StyledBox> */}
                  </StyledBox>
                </center>
              </StyledBox>
            </Box>
          );
        })}
      </AliceCarousel>
    </BoxWithStyledIndicators>
  );
}
