import { Box, CardMedia, Divider, Grid, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AlreadyLinkedAccountsList,
  ConsentArtefact,
  ConsentArtefactRequestBody,
  FipNewDiscoverelist,
  LinkAccountRequestBody,
  LinkBankAccount,
} from '../../api/banks';
import { IMAGE_BASE_URL } from '../../api/urls';
import {
  browserName,
  closeAndRedirect,
  getFipDetailsList,
  groupAccountsByFipId,
} from '../../lib/helper';
import { RootState } from '../../store/reducers';
import { Account, FiCategory, MultiCategory } from '../../store/reducers/multiCategory';
import {
  activeCategorySelector,
  consolidatedAccountsSelector,
  discoveredCategoriesSelector,
  nonLinkedCategorySelector,
} from '../../store/selectors/multiCategory';
import { AuthState } from '../../store/types/login';
import ConsentAndFindMore from '../ConsentAndFindMore';
import {
  Account as AccountCard,
  AccountHeader,
  Loader,
  LoaderProps,
  SendOtpButton,
  SkipBottomDrawer,
} from '../CustomComponents';
import AccountAccordion from '../CustomComponents/AccountAccordion';
import { CustomButton } from '../login/components';
import OtpDrawer, { OtpDrawerProps } from './OtpDrawer';

const OTP_TITLES: { [key in FiCategory]: string } = {
  BANK: 'Account(s)',
  EQUITIES: 'Demat(s)',
  INSURANCE_POLICIES: 'Policy(s)',
  MF: ' Folio(s)',
  NPS: 'PRAN',
  GSTR: 'Account(s)'
};

const AccountSelection = ({ toggleRejectDrawer }: { toggleRejectDrawer: any }) => {
  const dispatch = useDispatch();

  const { decrypt, dynData,consentData } = useSelector<RootState, Partial<AuthState>>(
    (globalState) => globalState.auth,
  );

  const activeCategory = useSelector(activeCategorySelector);
  const {
    [activeCategory]: { accounts, isTouched, verifiedFip, missingFipCount }, consentDetails
  } = useSelector<RootState, MultiCategory>((globalState) => globalState.multiCategory);
  console.log("consentDetails",consentDetails)
  const consolidatedAccounts = useSelector(consolidatedAccountsSelector) || [];

  const discoveredCategories = useSelector(discoveredCategoriesSelector);

  const nonLinkedCategories = useSelector(nonLinkedCategorySelector);

  const length = discoveredCategories.length;
  const isFinalCategory = discoveredCategories[length - 1] === activeCategory;
  const isFirstCategory = discoveredCategories[0] === activeCategory;
  const groupedAccounts = groupAccountsByFipId(accounts);
  const fipIds = Object.keys(groupedAccounts);
  const addFip = activeCategory !== 'NPS' && decrypt?.addfip !== 'false';

  const [loader, setLoader] = useState<LoaderProps>({ isOpen: false, info: '' });
  const [otpDrawerOpen, setOtpDrawerOpen] = useState<boolean>(false);

  const [submitDrawerOpen, setSubmitDrawerOpen] = useState<boolean>(false);

  const [otpProps, setOtpProps] = useState<OtpDrawerProps>({
    checkedBank: [],
    isOpen: otpDrawerOpen,
    phoneNumber: '',
    refNumber: '',
    selectedFIP: '',
    setIsOpen: setOtpDrawerOpen,
    updateLinkedAccounts: null,
  });
  const [consentdata, setConsentData] = useState<any>([])

  const selectAccount = (acc: Account) => {
    dispatch({
      type: 'SELECT_ACCOUNT',
      body: { selectedAccount: acc, category: activeCategory },
    });
  };

  const linkAccounts = async (fipId: string) => {
    try {
      setLoader({ ...loader, isOpen: true, info: 'Sending OTP' });
      const accountsToBeLinked = accounts
        .filter((acc) => acc.FIPID === fipId)
        .filter((acc) => acc.isChecked && !acc.LINKEDDATE)
        .map((acc) => {
          // eslint-disable-next-line no-unused-vars
          const { isChecked, ...rest } = acc;
          return rest;
        });
      const ACCOUNTS_TO_LINK = accountsToBeLinked.map((acc) => {
        const { FIPACCNUM, FIPACCTYPE, FITYPE, FIPID, Logo = '', FIPACCREFNUM } = acc;
        return {
          FIPACCNUM,
          FIPACCTYPE,
          FIPTYPE: FITYPE,
          FIPID,
          Logo,
          FIPACCREFNUM,
        };
      });
      const requestBody: LinkAccountRequestBody = {
        I_MOBILENUMBER: decrypt!.mobile,
        I_BROWSER: browserName,
        I_FIPID: fipId,
        I_ConsentHandle: dynData,
        ACCOUNTS_TO_LINK: ACCOUNTS_TO_LINK,
      };
      const responseBody = await LinkBankAccount(requestBody);
      if (responseBody.RESULT_CODE !== '200') throw new Error('Account Linking Failed');
      setOtpDrawerOpen(true);

      setOtpProps({
        //@ts-ignore
        checkedBank: accountsToBeLinked,
        setIsOpen: setOtpDrawerOpen,
        phoneNumber: decrypt?.mobile,
        refNumber: responseBody.RefNumber,
        selectedFIP: fipId,
        updateLinkedAccounts: updateLinkedAccounts,
        isOpen: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoader({ ...loader, isOpen: false });
    }
  };

  const updateLinkedAccounts = ({
    verifiedAccounts,
    linkedAccounts,
  }: {
    verifiedAccounts: FipNewDiscoverelist[];
    linkedAccounts: AlreadyLinkedAccountsList[];
  }) => {
    const updatedAccounts = accounts.map((acc) => {
      const isRecentlyVerified = linkedAccounts.find((fA) => fA.FIPACCREFNUM === acc.FIPACCREFNUM);
      if (isRecentlyVerified) return { ...isRecentlyVerified, isChecked: acc.isChecked };
      else return acc;
    });
    isSingleFipAndCategory?handleLinkSubmit(updatedAccounts):null;
  };

  const navigateToNextCategory = () => {
    const index = discoveredCategories.findIndex((cat) => cat === activeCategory);
    const nextCategory = discoveredCategories[index + 1];
    dispatch({
      type: 'CHANGE_ACTIVE_CATEGORY',
      body: { category: nextCategory },
    });
  };

  const LinksubmitConsent = async (accountsToSubmit:any,consentvalue: any) => {
    try {
      setLoader({ isOpen: true, info: 'Submitting Consent' });
      let submitAccounts: any= []
      let accounts =  getFipDetailsList(
        accountsToSubmit.filter((acc: any) => !!acc.LINKEDDATE && acc.isChecked),
        decrypt?.mobile,
      )
      console.log("accounts",accounts)
      let filterAccounts =  consentDetails?.filter((value:any) => value.CONSENTHANDLE === consentvalue)
      console.log("filterAccounts",filterAccounts)
      let AccountsToSubmit = accounts.filter((acc: any)=> filterAccounts.map((value: any) => {
      console.log("value.FITYPES",value.FITYPES)
      console.log("acc.FITYPE",acc.FITYPE)
      let fivalue= value.FITYPES.match(acc.FITYPE)
      // let fivalue = value.FITYPES.split(",").map((fitype: any)=> fitype === acc.FITYPE  ) 
      console.log(" fivalue ",fivalue)
      if(fivalue){
        submitAccounts.push(acc)
      }
    }))
      const consentBody: ConsentArtefactRequestBody = {
        I_MOBILENUMBER: decrypt!.mobile,
        I_MPIN: '111111',
        I_BROWSER: browserName,
        I_ConsentHandle: consentvalue,
        FIPDetailsList: submitAccounts,
      };
      const response = await ConsentArtefact(consentBody);
      if (response.RESULT_CODE !== '200') throw new Error('Consent Rejected');
      setLoader({
        ...loader,
        isOpen: true,
        info: 'Thank You!',
        moreInfo: 'Consent is received successfully.',
        subInfo: `You will be led to ${
          decrypt!.fiuName
        } website to continue your journey.`,
      });
      closeAndRedirect({
        url: decrypt?.redirect,
        parentStatusMessage: 'ACCEPTED',
        decrypt,
      });
    } catch (error) {
      //TODO: Undo this change after demo
      setLoader({
        ...loader,
        isOpen: true,
        info: 'Thank You!',
        moreInfo: 'Consent is received successfully.',
        subInfo: `You will be led to ${
          decrypt!.fiuName
        } website to continue your journey.`,
      });
      closeAndRedirect({
        url: decrypt?.redirect,
        parentStatusMessage: 'ACCEPTED',
        decrypt,
      });
    }
  };

  
  const handleLinkSubmit = async (updatedAccounts: any) => {
    const requests = consentData.map((consentvalue: any)=> {
      return LinksubmitConsent(updatedAccounts,consentvalue);
    })
   // console.log("requests",requests)
    const responses = await Promise.all(requests)
   // console.log("response",responses)
    let success = responses?.filter((response: any)=>{
      return response.RESULT_CODE === "200"
    })
    if(success.length >= 1){
      setLoader({
        ...loader,
        isOpen: true,
        info: 'Thank You!',
        moreInfo: 'Consent is received successfully.',
        subInfo: `You will be led to ${
          decrypt!.fiuName
        } website to continue your journey.`,
      });
      closeAndRedirect({
        url: decrypt?.redirect,
        parentStatusMessage: 'ACCEPTED',
        decrypt,
      });
    }else{
      // throw new Error('Consent Rejected');
      closeAndRedirect({
        parentStatusMessage: 'REJECTED',
        delay: true,
        decrypt,
        url: decrypt?.redirect,
       });
    
    }
  }

  const handleSubmit = async (type: any) => {
    const requests = consentData.map((consentvalue: any)=> {
      return submitConsent(type,consentvalue);
    })
   // console.log("requests",requests)
    const responses = await Promise.all(requests)
   // console.log("response",responses)
    let success = responses?.filter((response: any)=>{
      if(!!response){
        return response.RESULT_CODE === "200"
      }
    })
    if(success.length >= 1){
      setLoader({
        ...loader,
        isOpen: true,
        info: 'Thank You!',
        moreInfo: 'Consent is received successfully.',
        subInfo: `You will be led to ${
          decrypt!.fiuName
        } website to continue your journey.`,
      });
      closeAndRedirect({
        url: decrypt?.redirect,
        parentStatusMessage: 'ACCEPTED',
        decrypt,
      });
    }else{
      // throw new Error('Consent Rejected');
      closeAndRedirect({
        parentStatusMessage: 'Error while submitting consent',
        delay: true,
        decrypt,
        url: decrypt?.redirect,
       });
    
    }
  }

  const submitConsent = async (type: 'active' | 'consolidated',consentValue: any) => {
    // const accountsToSubmit = type === 'active' ? accounts : consolidatedAccounts;
    const accountsToSubmit = consolidatedAccounts;
    try {
      setLoader({ isOpen: true, info: 'Submitting Consent' });
      let submitAccounts: any= []
      let accounts =  getFipDetailsList(
        accountsToSubmit.filter((acc) => !!acc.LINKEDDATE && acc.isChecked),
        decrypt?.mobile,
      )
      let filterAccounts =  consentDetails?.filter((value:any) => value.CONSENTHANDLE === consentValue)
      let AccountsToSubmit = accounts.filter((acc: any)=> filterAccounts.map((value: any) => {
      let fivalue= value.FITYPES.match(acc.FITYPE)
      // let fivalue = value.FITYPES.split(",").map((fitype: any)=> fitype === acc.FITYPE  ) 
      console.log(" fivalue ",fivalue)
      if(fivalue){
        submitAccounts.push(acc)
      }
    }))
      const consentBody: ConsentArtefactRequestBody = {
        I_MOBILENUMBER: decrypt!.mobile,
        I_MPIN: '111111',
        I_BROWSER: browserName,
        I_ConsentHandle: consentValue,
        FIPDetailsList: submitAccounts,
      };
      if(submitAccounts.length > 0){
        const response = await ConsentArtefact(consentBody);
        return response;
      }
      // if (response.RESULT_CODE !== '200') throw new Error('Consent Rejected');
      // setLoader({
      //   ...loader,
      //   isOpen: true,
      //   info: 'Thank You!',
      //   moreInfo: 'Consent is received successfully.',
      //   subInfo: `You will be led to ${
      //     decrypt!.fiuName
      //   } website to continue your journey.`,
      // });
      // closeAndRedirect({
      //   url: decrypt?.redirect,
      //   parentStatusMessage: 'ACCEPTED',
      //   decrypt,
      // });
    } catch (error) {
      //TODO: Undo this change after demo
      // setLoader({
      //   ...loader,
      //   isOpen: true,
      //   info: 'Thank You!',
      //   moreInfo: 'Consent is received successfully.',
      //   subInfo: `You will be led to ${
      //     decrypt!.fiuName
      //   } website to continue your journey.`,
      // });
      // closeAndRedirect({
      //   url: decrypt?.redirect,
      //   parentStatusMessage: 'ACCEPTED',
      //   decrypt,
      // });
    }
  };

  const isSingleFipAndCategory =
    fipIds.length === 1 &&
    discoveredCategories.length === 1 &&
    groupedAccounts[fipIds[0]].filter((acc) => acc.isChecked && !acc.LINKEDDATE).length;

  const FooterButtons = () => {
    const disableSubmit = !consolidatedAccounts.find(
      (acc) => acc.isChecked && acc.LINKEDDATE,
    );
    const primaryLabel = isFinalCategory ? 'Reject' : 'Submit Consent';
    const secondaryLabel = isSingleFipAndCategory
      ? 'Link and Submit'
      : isFinalCategory
      ? 'Submit Consent'
      : 'Next';
    return (
      <Box
        style={{
          position: 'fixed',
          bottom: 24,
          left: 0,
          right: 0,
          //zIndex: 99,
          width: '100%',
          maxWidth: 500,
          margin: 'auto',
          backgroundColor: '#f5f8f8',
          padding: '20px 16px',
          marginBottom: '0',
        }}
      >
        <Typography
          sx={{
            marginBottom: '5px',
            textAlign: 'center',
            fontSize: '0.8rem',
          }}
        >
          Consent can be submitted only for Linked Account(s)
        </Typography>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            gap: '16px',
          }}
        >
          <CustomButton
            label={primaryLabel}
            variant="outlined"
            disabled={isFinalCategory ? false : disableSubmit}
            style={{
              margin: 0,
              maxHeight: '48px',
              fontSize: '16px',
              width: isFinalCategory ? '55%' : '',
            }}
            onClick={
              isFinalCategory
                ? toggleRejectDrawer
                : nonLinkedCategories?.length
                ? () => setSubmitDrawerOpen(true)
                : () => handleSubmit(isFirstCategory ? 'active' : 'consolidated')
               // submitConsent(isFirstCategory ? 'active' : 'consolidated')
            }
          />
          <CustomButton
            label={secondaryLabel}
            disabled={isFinalCategory && !isSingleFipAndCategory ? disableSubmit : false}
            style={{ margin: 0, maxHeight: '48px', fontSize: '16px' }}
            onClick={
              isFinalCategory
                ? isSingleFipAndCategory
                  ? () => linkAccounts(fipIds[0])
                  : nonLinkedCategories?.length
                  ? () => setSubmitDrawerOpen(true)
                  : () => handleSubmit('consolidated')
                  //submitConsent('consolidated')
                : navigateToNextCategory
            }
          />
        </Grid>
      </Box>
    );
  };

  useEffect(() => {
    let Fivalue = ""
    let consentData = []
    if(activeCategory === 'BANK'){
      Fivalue = "DEPOSIT"
      consentData = consentDetails.filter((consent: any) => consent.FITYPES.match(Fivalue))
    }else if(activeCategory === 'NPS'){
      Fivalue = "NPS"
      consentData = consentDetails.filter((consent: any) => consent.FITYPES.match(Fivalue))
    }else if(activeCategory === 'MF'){
      Fivalue = "MUTUAL_FUNDS"
      consentData = consentDetails.filter((consent: any) => consent.FITYPES.match(Fivalue))
    }else if(activeCategory === 'EQUITIES'){
      Fivalue = "EQUITIES"
      consentData = consentDetails.filter((consent: any) => consent.FITYPES.match(Fivalue))
    }else if(activeCategory === 'INSURANCE_POLICIES'){
      Fivalue = "INSURANCE_POLICIES"
      consentData = consentDetails.filter((consent: any) => consent.FITYPES.match(Fivalue))
    }
    else if(activeCategory === 'GSTR'){
      Fivalue = "GSTR1_3B"
      consentData = consentDetails.filter((consent: any) => consent.FITYPES.match(Fivalue))
    }
    else{
      consentData = consentDetails
    }
    setConsentData(consentData)
    if (isTouched) return;
    dispatch({
      type: 'SELECT_ACCOUNTS_ON_FIRST_RENDER',
      body: { category: activeCategory },
    });
  }, [activeCategory]);

  return (
    <Grid
      component={'main'}
      sx={{ padding: '0px 16px 150px', backgroundColor: '#f5f8f8' }}
    >
      {missingFipCount ? (
        <Box
          onClick={() => {
            dispatch({
              type: 'CLEAR_MISSING_FIP_COUNT',
              body: { category: activeCategory },
            });
          }}
          sx={{
            display: 'flex',
            marginY: '10px',
            gap: '5px',
            cursor: 'pointer',
          }}
        >
          <img src="/images/Caution.svg" alt="" />
          <Typography color="#F1742D">{missingFipCount} account(s) not found</Typography>
          <img src="/images/Close_small.svg" alt="close" />
        </Box>
      ) : null}
      {fipIds.map((id) => {
        const _fipAccounts : any = [
          ...groupedAccounts[id].filter((acc) => !acc.LINKEDDATE),
          ...groupedAccounts[id].filter((acc) => !!acc.LINKEDDATE),
        ];
        const logo = _fipAccounts[0]?.LOGO || _fipAccounts[0]?.Logo || '';
        const subLogo = _fipAccounts[0]?.AMCLOGO || _fipAccounts[0]?.LOGO || _fipAccounts[0]?.Logo || '';
        const title = _fipAccounts[0]?.FIPNAME || '';
        const amcName = _fipAccounts[0]?.AMCNAME || '';
        const isOtpButtonDisabled = !groupedAccounts[id].filter(
          (acc) => acc.isChecked && !acc.LINKEDDATE,
        ).length;

        const isRecentlyVerified =
          !!verifiedFip?.find((fipId) => fipId === id) &&
          _fipAccounts.every((acc:any) => acc.LINKEDDATE);

          const MFbankList :any[]= []
          const bankDict:any = {}
          _fipAccounts.forEach((item: any, index: any) => {
           if (!MFbankList.includes(item.AMCNAME)) {
            MFbankList.push(item.AMCNAME)
             bankDict[item.AMCNAME] = []
             bankDict[item.AMCNAME].push(item)
           } else {
             bankDict[item.AMCNAME].push(item)
           }
         })
         console.log('Bank Dict:', bankDict)
         console.log('Bank List:', MFbankList)
        return (
          <Box
            key={id}
            style={{
              boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.1)',
              margin: '0 0 15px',
            }}
          >
            {isRecentlyVerified ? (
              <AccountAccordion logo="" title={title}>
                <Box sx={{ maxHeight: '250px', overflowY: 'scroll' }}>
                  {_fipAccounts.map((acc:any, index:any) => {
                    return (
                      <AccountCard
                        account={acc}
                        key={acc.FIPACCREFNUM}
                        accountName={acc.FIPACCTYPE}
                        accountNumber={acc.FIPACCNUM}
                        isLinked={!!acc.LINKEDDATE}
                        onClick={() => selectAccount(acc)}
                        isChecked={acc.isChecked}
                        isLast={index === _fipAccounts.length - 1}
                      />
                    );
                  })}
                </Box>
              </AccountAccordion>
            ) : (
              <Fragment>
                <AccountHeader title={title} logo={logo} />
                <Divider />

                <Box
                  sx={{
                    maxHeight: '250px',
                    overflow: activeCategory === 'MF' ? '' : 'scroll',
                    bgcolor: 'white',
                    overflowY: activeCategory === 'MF' ? 'scroll' : ''
                  }}
                >
                  {activeCategory === 'MF' ? (

                  MFbankList.map((item:any) =>{
                   return (<Box
                      sx={{
                        boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.2);',
                        borderRadius: 1.25,
                        p: 1,
                        m: 1,
                        backgroundColor: '#FAFAFA',
                        maxHeight: '250px',
                        overflowY: 'scroll'
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <CardMedia
                            component="img"
                            image={subLogo ? IMAGE_BASE_URL + subLogo : '/images/Bank Icon.svg'}
                            alt="logo"
                            sx={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                              ml: 1,
                              objectFit: 'contain',
                            }}
                          />
                          <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
                            <Typography
                              sx={{
                                color: 'text.primary',
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              {item}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: 'text.secondary',
                              }}
                            >
                              {bankDict[item].length} {OTP_TITLES[activeCategory]} Found!
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: 'text.secondary',
                              mr: 4,
                            }}
                          >
                            MF
                          </Typography>
                        </Box>
                      </Box>

                      <Divider sx={{ mt: 1 }} />
                      <Box sx={{ maxHeight: '175px', overflowY: 'scroll' }}>
                        {bankDict[item].map((acc:any, index:any) => {
                          return (
                            <AccountCard
                              key={acc.FIPACCREFNUM}
                              account={acc}
                              accountName={acc.FIPACCTYPE}
                              accountNumber={acc.FIPACCNUM}
                              isLinked={!!acc.LINKEDDATE}
                              onClick={() => selectAccount(acc)}
                              isChecked={acc.isChecked}
                              isLast={index === bankDict[item].length - 1}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  )})
                  ) : activeCategory === "GSTR" ? (
                    _fipAccounts.map((acc:any, index:any) => {
                      return (
                        <AccountCard
                          account= {acc}
                          key={acc.FIPACCREFNUM}
                          accountName={acc.StateName}
                          accountNumber={acc.FIPACCNUM}
                          isLinked={!!acc.LINKEDDATE}
                          onClick={() => selectAccount(acc)}
                          isChecked={acc.isChecked}
                          isLast={index === _fipAccounts.length - 1}
                        />
                      );
                    })
                  ):
                  ( _fipAccounts.map((acc:any, index:any) => {
                      return (
                        <AccountCard
                          account= {acc}
                          key={acc.FIPACCREFNUM}
                          accountName={acc.FIPACCTYPE}
                          accountNumber={acc.FIPACCNUM}
                          isLinked={!!acc.LINKEDDATE}
                          onClick={() => selectAccount(acc)}
                          isChecked={acc.isChecked}
                          isLast={index === _fipAccounts.length - 1}
                        />
                      );
                    })
                  )}
                </Box>
                {!(fipIds.length === 1 && discoveredCategories.length === 1) && (
                  <SendOtpButton
                    onClick={() => linkAccounts(id)}
                    disabled={isOtpButtonDisabled}
                    title={
                      activeCategory === 'MF'
                        ? 'Send OTP'
                        : 'Send OTP For Linking ' + OTP_TITLES[activeCategory]
                    }
                  />
                )}
              </Fragment>
            )}

            {otpDrawerOpen ? (
              <OtpDrawer
                {...otpProps}
                onLinkAndSubmit={(linkedAccounts:any,verifiedAccounts:any) => {
                   dispatch({
                    type: 'UPDATE_LINKED_ACCOUNTS',
                    body: {
                      linkedAccounts,
                      verifiedAccounts,
                      category: activeCategory,
                    },
                  })
                }}
              />
            ) : null}
          </Box>
        );
      })}

      <ConsentAndFindMore
        addFip={addFip}
        label={activeCategory === 'MF' ? 'Folios' : 'Accounts'}
        consentData = {consentdata}
      />
      <Loader {...loader} />
      <FooterButtons />
      <SkipBottomDrawer
        buttonLabelPrimary="Link More Accounts"
        buttonLabelSecondary="Submit Consent"
        isOpen={submitDrawerOpen}
        textPrimary={`${nonLinkedCategories?.join(',')} accounts are not linked.`}
        onClickPrimary={() => {
          setSubmitDrawerOpen(false);
        }}
       // onClickSecondary={submitConsent}
       onClickSecondary={handleSubmit}
        textSecondary={'Link more accounts to manage your wealth effectively.'}
        closeDrawer={() => setSubmitDrawerOpen(false)}
      />
    </Grid>
  );
};

export default AccountSelection;
