import React from 'react';

const Contents = () => {
  return (
    <span style={{ fontSize: '12px' }}>
      <p>
        These terms and conditions (<b>“Terms”</b>) govern the access to and use of the
        CAMSfinserv mobile application and website (<b>“Platform”</b>) and the Services
        obtained thereof (defined below).
      </p>
      <p>
        These Terms also include our privacy policy, available at www.camsfinserv.com (
        <b>“Privacy Policy”</b>), and any guidelines, additional terms, policies, or
        disclaimers made available or issued by us from time to time. We may amend these
        Terms following the provisions hereunder, and the Terms shall apply to you as
        amended from time to time. You may always review the current version of these
        Terms on the website (
        <a href="http://www.camsfinserv.com" target="_blank" rel="noreferrer">
          www.camsfinserv.com
        </a>
        ).
      </p>
      <p>
        These Terms constitute a binding and enforceable legal contract between CAMS
        Financial Information Services Pvt Ltd (<b>“Company”</b>,<b>“we”</b>, or{' '}
        <b>“us”</b>) and any end-user of the Services (<b>“you”</b>).
      </p>
      <p>
        You represent and warrant that you (a) have total legal capacity and authority to
        agree and bind yourself to these Terms, (b) are eighteen years of age or older,
        and (c) is an Indian Citizen. In addition, if you represent an entity,
        organization, or any other legal person, you confirm and represent that you have
        the necessary power and authority to bind such entity, organization, or legal
        person to these Terms.
      </p>
      <p>
        {`Using the Services, you agree that you have read, understood, and bound by these
        Terms and comply with the requirements listed here. To agree to these terms,
        kindly click "Agree" if you do agree to the terms, kindly do not click "Agree" and
        do not use the services`}
      </p>
      <p>1. DEFINITIONS</p>
      <p>
        (a) <b>{`"AA Master Directions"`}</b> shall mean the Master Direction -
        Non-Banking Financial Company - Account Aggregator (Reserve Bank) Directions, 2016
        as amended, revised or updated from time to time;
      </p>
      <p>
        (b) <b>{`"Account Aggregation Services"`}</b> refers to the services provided by
        us following AA Master Directions which enables the following:
      </p>
      <ul>
        <li>
          (i) Retrieving, viewing, consolidating, organizing, and sharing Financial
          Information that is available with Financial Information Providers; and
        </li>
        <li>
          (ii) Presenting your Financial Information collected from the Financial
          Information Provider to Financial Information Users.{' '}
        </li>
      </ul>
      <p>
        (c) <b>{`"End-User / Customer"`}</b> shall mean the individual User accessing our
        portal/web application / mobile app via which he interacts/engages in our platform
        and has consented to these terms and conditions.
      </p>
      <p>
        (d) <b>{`"Financial Information"`}</b> shall have the same meaning ascribed to it
        in the AA Master Directions;
      </p>
      <p>
        (e) <b>{`"Financial Information Provider/FIP"`}</b> shall mean a bank, banking
        company, non-banking financial company, asset management company, depository,
        depository participant, insurance company, insurance repository, pension fund, or
        such other entity as may be identified as a{' '}
        <b>{`"financial information provider"`}</b> in the AA Master Directions;
      </p>
      <p>
        (f) <b>{`"Financial Information User/FIU"`}</b> shall mean an entity registered
        with and regulated by any Financial Sector Regulator, or such other entity as may
        be identified as a <b>{`"financial information user"`}</b> in the AA Master
        Directions;
      </p>
      <p>
        (g) <b>{`"Financial Sector Regulator"`}</b> shall have the same meaning ascribed
        to it in the AA Master Directions;
      </p>
      <p>
        (h) <b>{`"RBI"`}</b> shall mean the Reserve Bank of India.
      </p>
      <p>
        Words that are capitalized within the Terms but not defined in this Clause 1 shall
        have the meaning ascribed to them in the specific clause.
      </p>
      <p>2. SCOPE &amp; AUTHORISATIONS</p>
      <p>
        (a) Through the Platform, you may avail of the Account aggregator services and
        provide the consent as may be required from you as a user.
      </p>
      <p>
        (b) For us to render the Account Aggregator Services, you, as a result of this,
        authorize and appoint us as your agent with limited power to access, with your
        consent, your Financial Information, sensitive personal information details from
        Financial Information Providers.
      </p>
      <p>
        (c) We reserve the right to charge you for the Services following the applicable
        laws. Should we intend to charge for the Services, the fees shall align with our
        pricing policy made available on our platform. Additionally, any fees in the
        future charged are made public in our digital properties as deemed appropriate
      </p>
      <p>3. PROFILE CREATION</p>
      <p>
        (a) To avail of the Services, We request you to create a profile on the platform (
        <b>{`"Profile"`}</b>) and set up the initial username and password, and furnish
        the specific details, includes the phone number and other personal information.
        You warrant that all information furnished in connection with your Profile is and
        shall remain accurate and true, and you agree that you shall promptly update your
        details on the platform in the event of any change to or modification of this
        information
      </p>
      <p>
        (b) You are solely responsible for maintaining the security and confidentiality of
        your username and password and agree to immediately notify us of any disclosure or
        unauthorized use of your Profile or any other breach of security concerning your
        Profile in the email id:{' '}
        <a href="mailto:info@camsfinserv.com">info@camsfinserv.com</a>
      </p>
      <p>
        (c) You expressly agree to be liable and accountable for all activities that take
        place through your Profile. We shall in no manner be liable for any unauthorized
        access to your Profile.
      </p>
      <p>4.COLLECTION OF USER DATA</p>
      <p>
        We collect your personal information through our digital properties. The Data
        Collected includes personally identifiable information like Name, Contact details,
        PAN No. These details are primarily used to map the account details of the user
        held at various financial institutions.
      </p>
      <b>The information collected may also be used for the following purposes:</b>
      <p>
        a. Personalize your experience and better our responses to match your
        requirements, wherever possible.
      </p>
      <p>
        b. Improve our digital properties based on visitor profiles, servicing patterns,
        and their exact requirements,
      </p>
      <p>c. To improve customer service based on the feedback provided by the User.</p>
      <p>
        d. Use analytics to track the traffic patterns, sources of traffic, user
        preferences, referrals, etc., and use such analytics to improve the services.
      </p>
      <p>
        e. To send periodic emails/SMS to email address/phone numbers provided. These
        details are used to send you information and updates about your order or request,
        in addition to receiving periodic news/updates/promotions / related products or
        services provided by CAMSfinserv and its group companies/subsidiaries and
        informative and educative material(s) that we deem appropriate.
      </p>
      <p>
        f. It is our commitment and promise that your phone number/email IDs and other
        personal information you share on our digital properties are very confidential and
        safe with CAMSfinserv.
      </p>
      <p>5. COOKIE POLICY</p>
      <p>
        {`a. Please note that "Cookies" are small pieces of data stored in text files, often
        including unique identifiers sent by web servers to web browsers and may then be
        sent back to the server each time the browser requests a page from the server.`}
      </p>
      <p>
        {`b. "Cookies" are widely used to "remember" you and your preferences, either for a
        single visit (through a "session cookie") or for multiple repeat visits (using a
        "persistent cookie"), to ensure a consistent experience for the users.`}
      </p>
      <p>
        c. We may use cookie and tracking technology depending on the features offered.
      </p>
      <p>
        d. No Personal Data collected via cookies and other tracking technology; however,
        cookies may be tied to such information if you previously provided Personal Data.
      </p>
      <p>6. CONSENT TO USE DATA</p>
      <p>
        (a) Notwithstanding anything, contrary hereunder and following the AA Master
        Directions, we do not store any Financial Information about you, except to the
        extent necessary to make the Financial Information available to you or, upon your
        consent, to a Financial Information user to perform the specific services agreed
        between you and Financial information user.
      </p>
      <p>
        (b) The provision of our Services enables you to provide your consent to disclose
        and transfer your Financial Information. Consent is collected and confirmed using
        a digital consent artifact, which requires you to confirm the following datasets:
      </p>
      <ul>
        <li>(i) Your identity and contact information;</li>
        <li>(ii) The nature of the information requested;</li>
        <li>(iii) Purpose of collecting such information;</li>
        <li>(iv) The identity of the recipients of the information;</li>
        <li>
          (v) The consent creation date, the date of expiry of the consent, and your
          signature/digital signature;
        </li>
        <li>(vi) Any other information that may be required under applicable laws</li>
      </ul>
      <p>
        (c) You may, at any point in time, revoke all or any of your consent(s) provided
        to us through the consent artifact available in the online platform to obtain any
        Financial Information that relates to you. Upon revocation, you shall initiate a
        new consent artifact while availing of the services at a future date.
      </p>
      <p>
        (d) You may, at any point in time, access a record of the consents provided by you
        and the Financial Information of Users with whom the information has been shared
        on the platform.
      </p>
      <p>
        (e) You agree that we may follow our Privacy Policy, collect and use your personal
        information, technical data, and related information.
      </p>
      <p>
        {`(f) We may use information and data about your use of our Services for analytics,
        trends' identification, and statistics to further enhance the effectiveness and
        efficiency of the platform.`}
      </p>
      <p>
        (g) Subject to applicable laws, we may be directed by law enforcement agencies or
        the government and related bodies to disclose data related to you in connection
        with any proceedings. You understand and agree that we shall have the right to
        share such data with relevant agencies or bodies in such instances without seeking
        any further consent or approval from you.
      </p>
      <p>7. PRIVACY SHIELD</p>
      <p>
        This app does not intend to use personal information made available by
        Google/AppStore/Service providers that directly or indirectly identify an
        individual.
      </p>
      <p>8. DATA PROTECTION &amp; NONDISCLOSURE OF PERSONAL OR SENSITIVE USER DATA</p>
      <p>
        {`AA Portal never publicly discloses personal or sensitive user data related to
        financial or payment activities or any government identification numbers and does
        not publish or disclose people's non – public contacts. The data collected may be
        shared with Employees on a need-to-know basis for completing the transaction
        initiated/requested by the User. Any personal information supplied by the User
        while using the AA Portal may be stored and used by CAMSfinserv following the
        organization's privacy practices.`}
      </p>
      <p>9. MALICIOUS BEHAVIOR</p>
      <p>
        {`The AA Portal doesn't allow steal data, secretly monitor or harm users, or are
        otherwise malicious. The following are expressly prohibited:`}
      </p>
      <ul>
        <li>
          a. Viruses, trojan horses, malware, spyware, or any other malicious software
        </li>
        <li>
          b. Apps that link to or facilitate the distribution or installation of malicious
          software
        </li>
        <li>
          c. Apps or SDKs that download executable code, such as dex files or native code,
          from a source other than Google Play
        </li>
        <li>d. Apps that introduce or exploit security vulnerabilities</li>
        <li>
          {`e. Apps that steal a user's authentication information (such as usernames or
          passwords) or that mimic other apps or websites to trick users into disclosing
          personal or authentication information`}
        </li>
        <li>
          {` f. Apps that install other apps on a device without the User's prior consent`}
        </li>
        <li>
          g. Apps designed to collect device usage, such as commercial spyware apps
          secretly
        </li>
      </ul>
      <p>10. YOUR RESPONSIBILITIES</p>
      <p>
        (a) You represent and warrant that all information provided through or about the
        Services is complete, accurate, and correct on the date of agreeing to these Terms
        and shall continue to be complete, authentic, and correct while you avail the
        Services. Should any information you provide change during the existence of these
        Terms, you undertake to bring such change to our notice immediately. We do not
        accept any responsibility or liability for any loss or damage that you may suffer
        or incur if any information, documentation, material, or data provided to avail
        the Services is incorrect, incomplete, inaccurate, or misleading, or if you fail
        to disclose any material fact. Additionally, we reserve the right to initiate
        legal proceedings, including criminal proceedings against fraud perpetrators.
      </p>
      <p>
        (b) You shall be solely responsible for ensuring compliance with applicable laws
        and shall be solely liable for any liability that may arise due to a breach of
        your obligations in this regard, including periodic KYC identification or any
        other such compliances as deemed necessary by the respective FIP and FIU
      </p>
      <p>
        (c) You shall extend complete cooperation to CAMSFINSERV to defend any proceedings
        that may be initiated against us due to a breach of your obligations or covenants
        under these Terms.
      </p>
      <p>
        (d) You shall not use the Services in any manner except as expressly permitted in
        these Terms. Without limiting the generality of the preceding sentence, you shall
        not:
      </p>
      <ul>
        <li>
          (i) Use the Services to transmit any data or send or upload any material that
          contains any malware or any other harmful programs or similar computer code
          designed to affect the operation of any computer software or hardware adversely;
        </li>
        <li>
          (ii) Use any robot, spider, another automated device, or manual process to
          monitor or copy the Platform or any portion thereof;
        </li>
        <li>
          (iii) Engage in the systematic retrieval of content from the platform to create
          or compile, directly or indirectly, a collection, compilation, database, or
          directory;
        </li>
        <li>
          (iv) Use the Services in any unlawful manner, for fraudulent or malicious
          activities, or in any manner inconsistent with these Terms; or
        </li>
        <li>(v) Violate applicable laws in any manner.</li>
      </ul>
      <p>
        (e) You warrant that you shall not engage in any activity that interferes with or
        disrupts access to the platform.
      </p>
      <p>
        (f) You shall not attempt to gain unauthorized access to any portion or feature of
        the platform, any other systems or networks connected to the platform, to any of
        our servers, or through the platform, by hacking, password mining, or any other
        illegitimate means.
      </p>
      <p>11. INTELLECTUAL PROPERTY</p>
      <p>
        (a) All rights, title, interest in the Platform and Services, intellectual
        property rights arising from the Platform, and Services are owned by or otherwise
        licensed to us. Subject to compliance with these Terms, we grant you a
        non-exclusive, non-transferable, non-sublicensable, royalty-free, revocable, and
        limited license to use the Platform and Services following these Terms and its
        written instructions issued from time to time.
      </p>
      <p>
        (b) We may request you to submit suggestions and other feedback, including bug
        reports, relating to the Platform or Services from time to time. We may freely
        use, copy, disclose, publish, display, distribute, and exploit the feedback
        without any payment of royalty, acknowledgment, prior consent, or any other form
        of restriction arising out of your intellectual property rights.
      </p>
      <p>
        {`(c) Except as stated in these Terms, nothing in these Terms should be construed as
        conferring any right in or license to our or any third party's intellectual
        property rights`}
      </p>
      <p>12.SYSTEM REQUIREMENTS</p>
      <p>
        To use the application, you must have a compatible computer or mobile device,
        internet access.
      </p>
      <p>13. REGISTRATION</p>
      <p>
        User Signup confirmation is based on your confirmation of the authentication email
        sent to your registered email id /OTP sent to the registered mobile number. On
        registration, you are advised to change the password immediately during the first
        login and recommend maintaining the password in a secured manner.
      </p>
      <p>14. SERVICES</p>
      <p>
        a. CAMSfinserv shall endeavor to provide Account Aggregator Services to the User
        through AA Portal/AA App, subject to Reserve Bank of India approvals from time to
        time. CAMSfinserv, at its sole discretion, shall add /delete the services offered
        to the User through&nbsp;AA Portal/AA App.
      </p>
      <p>
        b. Availing of the services through the AA Portal/AA App is subject to the
        following conditions
      </p>
      <ul>
        <li>
          {`i. The User with this declares that he/she authorized or eligible to avail the
          Account Aggregator services from CAMSfinserv by authorizing his / her FIP to
          furnish the account information as per the User's direction.`}
        </li>
        <li>
          ii. The User acknowledges that the agreement terms with their respective mobile
          phone/device network provider will continue to apply when using the AA Portal/AA
          App. As a result, the User may be charged by the mobile phone/device network
          provider for access to network connection services for the duration of the
          connection while accessing the AA Portal/AA App or any such third-party charges
          as may arise. The User accepts responsibility for any such charges that arise.
        </li>
        <li>
          iii. The User accepts that transaction instructions entered on non-business days
          will be processed on the following business day.
        </li>
        <li>
          iv. The User accepts that the information provided to the FIU’s through&nbsp;the
          AA Portal/AA App may not be updated on areal-time basis. CAMSfinserv shall not
          be liable for any loss that the User may suffer by relying on or acting on such
          information.
        </li>
        <li>
          v. The User accepts that the use of the&nbsp;AA Portal/AA App shall be subject
          to the terms and conditions of any applicable agreements between CAMSfinserv and
          its Service Providers.
        </li>
      </ul>
      <p>15. CONSIDERATION</p>
      <p>
        There is no specific Consideration payable by the User to CAMSfinserv for availing
        Account Aggregator Services from CAMSfinserv. However, it is the discretion of
        CAMSfinserv to fix consideration at any point in time.
      </p>
      <p>16. RESTRICTED ACCESS TO&nbsp;AA PORTAL/AA App</p>
      <p>
        Based on the terms of service, the User is authorized to access AA Portal/AA App.
        In case if the User allows any other person to have access to&nbsp;AA Portal/AA
        App on behalf of the User, the User grants express irrevocable authority to
        CAMSfinserv to act upon any instruction given by the User through&nbsp;AA
        Portal/AA App as may be permitted through the application from time to time and
        until valid termination of&nbsp;AA Portal/AA App service by the User. CAMSfinserv
        shall have no obligation to verify any request(s) received through&nbsp;AA
        Portal/AA App or purporting to have been sent by the User via&nbsp;AA Portal/AA
        App other than verifying the login credentials.
      </p>
      <p>
        The display shown at the time of operation of&nbsp;the AA Portal/AA App in the
        mobile phone/device is a proof of record that the operation was made with the use
        of the mobile phone/device and shall not be construed as a final record of the
        transaction. The records maintained by CAMSfinserv through computer systems are
        deemed final and conclusive, binding evidence for all purposes.
      </p>
      <p>
        Therefore, the User shall not share the access details or login credentials with
        others.
      </p>
      <p>17. TERM AND TERMINATION</p>
      <p>
        (a) These Terms shall remain in effect unless terminated following the terms
        hereunder.
      </p>
      <p>
        (b) CAMS Finserve may terminate the access given to you for the usage of services
        at any point in time if we found a violation or breach towards any of the
        obligation, responsibilities, or covenants under these Terms or when you cease to
        become a user of our Services.
      </p>
      <p>
        {' '}
        {`(c) Upon termination of these Terms, your user Profile will expire, and the
        Services will "time-out"; and you shall cease to be a customer of our services.
        The terms of services shall be terminated except the clauses that expressly or
        intended to survive termination or expiry`}
      </p>
      <p>18. DISCLAIMERS AND WARRANTIES</p>
      <p>
        (a) We do not warrant the accuracy, suitability, or correctness of any Financial
        Information made available on or through the Services.
      </p>
      <p>
        {`(b) The Platform and the Services are provided by us on an "as is" basis without
        warranty of any kind, express, implied, statutory, or otherwise, including the
        implied warranties of title, non-infringement, merchantability, or fitness for a
        purpose. Without limiting the preceding, we make no warranty that:`}
      </p>
      <ul>
        <li>
          (i) The Platform or the Services shall meet your requirements or expectations,
          or that your use of the Services will be uninterrupted, timely, secure, or
          error-free;
        </li>
        <li>(ii) Any errors or defects in the platform will be corrected.</li>
      </ul>
      <p>
        (c) No advice or information, whether oral or written, obtained by you from us
        shall create any warranty not expressly stated in the Terms.
      </p>
      <p>
        (d) You hereby accept full responsibility for any consequences that may arise from
        your use of the Services and expressly agree and acknowledge that we shall have
        absolutely no liability in this regard.
      </p>
      <p>
        (e) To the fullest extent permissible by law, we, our affiliates, and related
        parties each disclaim all liability towards you for any loss or damage arising out
        of or due to:
      </p>
      <ul>
        <li>
          (i) Your use of, inability to use, or availability or unavailability of the
          Services;
        </li>
        <li>
          (ii) The occurrence or existence of any defect, interruption, or delays in the
          operation or transmission of information to, from, or through the Services,
          communications failure, theft, destruction, or unauthorized access to our
          records, programs, services, server, or other infrastructure relating to the
          Services; or
        </li>
        <li>(iii) The failure of the Services to remain operational for any period.</li>
      </ul>
      <p>
        (f) Notwithstanding anything to the contrary contained herein, neither we nor any
        of our affiliates or related parties shall have any liability to you or any third
        party for any indirect, incidental, special, or consequential damages or any loss
        of revenue or profits arising under, directly or indirectly, or relating, in any
        manner whatsoever, to these Terms or the Services. To the maximum extent permitted
        by law, you agree to waive, release, discharge, and hold harmless us, our
        affiliated and subsidiary companies, their parent companies, and each of their
        directors, officers, employees, and agents, from all claims, losses, damages,
        liabilities, expenses, and causes of action arising out of the Services.
      </p>
      <p>19. LIMITATION OF LIABILITY</p>
      <p>
        In no event will CAMSfinserv or their associates or any person connected with it
        be liable for any direct, indirect, special, punitive, exemplary, or consequential
        losses or damages of whatsoever kind arising out of your use or access to the
        application, including loss of profit or the like, whether or not in the
        contemplation of the parties, whether based on breach of contract, tort (including
        negligence) or otherwise. CAMSfinserv acts as a consent management platform,
        passing through encrypted data to the data-seeking entities. In case of a data
        breach caused due to the data-seeking entities in a relationship with CAMSfinserv,
        CAMSfinserv is not liable for the damage or its consequent action.
      </p>
      <p>20. INDEMNITY</p>
      <p>
        {`You shall indemnify, defend at our option, and hold us, our parent company,
        subsidiaries, affiliates, officers, employees, directors, agents, and
        representatives, harmless from and against any claim, demand, lawsuits, judicial
        proceeding, losses, liabilities, damages. And costs (including, without
        limitation, all damages, liabilities, settlements, and attorneys' fees) due to or
        arising out of your access to the Services, use of the Services, violation of
        these Terms, or any infringement of these Terms by any third party who may use
        your Profile to access the Services.`}
      </p>
      <p>21. ACCESS TO CUSTOMER INFORMATION IS LIMITED TO EMPLOYEES AND THIRD PARTIES</p>
      <p>
        CAMSfinserv limits access to personally identifiable information to those
        employees with a business reason for knowing such information about customers. We
        regularly educate our employees on their responsibility to protect the
        confidentiality of customer information and hold them accountable if they violate
        this privacy policy. We ensure to enter into practical terms with third-party
        service providers to protect client confidentiality and follow the non-disclosure
        agreement (NDA).
      </p>
      <p>22.DATA RETENTION &amp; ERASURE</p>
      <p>
        {` We ensure that we meet the 'data minimization and 'storage limitation' principles
        and that personal information is stored, archived, and destroyed compliantly and
        ethically. We have documented and practiced secure erasure procedures to meet the
        new 'Right to Erasure' obligation and are aware of when this and other data
        subject's rights apply, along with any exemptions, response timeframes, and
        notification responsibilities. CAMSfinserv may retain the data as may be provided
        by the client till it may be provided under the law and as required to provide
        service to the clients efficiently.`}
      </p>
      <p>23.INCIDENT MANAGEMENT</p>
      <p>
        Our Incident Reporting procedures ensure that we have safeguards and controls to
        identify, assess, investigate and report any personal data breach at the earliest
        possibility. Our procedures are robust and have been disseminated to all
        employees, who know the reporting lines and steps to follow.
      </p>
      <p>24. MODIFICATION</p>
      <p>
        We reserve the right to add, modify or discontinue, temporarily or permanently,
        the Services (or any part thereof) with or without cause. We shall not be liable
        for any such addition, modification, suspension, or discontinuation of the
        Services.
      </p>
      <p>25. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION</p>
      <p>
        {`(a) These Terms shall be governed by and construed and enforced according to
        India's applicable laws. Subject to other provisions in this clause, courts in
        Chennai shall have exclusive jurisdiction over all issues arising out of these
        Terms or the use of the Services.`}
      </p>
      <p>
        (b) Any controversies, conflicts, disputes, or differences arising out of these
        Terms shall be resolved by arbitration in Chennai following the Arbitration and
        Conciliation Act, 1996 for the time being in force, which is deemed to be
        incorporated by reference in this clause. The tribunal shall consist of 1 (one)
        arbitrator appointed by us. The language of the arbitration shall be English.
      </p>
      <p>
        (c) The parties to the arbitration shall keep the arbitration confidential and not
        disclose to any person, other than on a need to basis or legal advisors unless
        required to do so by law. The decision of the arbitrator shall be final and
        binding on all the parties hereto.
      </p>
      <p>
        (d) Each party to the arbitration shall bear its costs concerning any dispute.
      </p>
      <p>
        (e) CAMSfinserv is a signatory to the Eco System Participation terms of Sahamati
        (a self-organised Industry Alliance for the Account Aggregator ecosystem) and is
        governed by such terms and conditions including amendments carried therein from
        time to time- The Terms are available at{' '}
        <a
          href="https://drive.google.com/file/d/1HD_xEC5cr-kilmCE-lEO85Prc9ugFNOo/view"
          target="_blank"
          rel="noreferrer"
        >
          Click Here
        </a>{' '}
        and you are requested make yourself aware of these.{' '}
      </p>
      <p>26. GRIEVANCE REDRESSAL</p>
      <p>
        (a) You may contact our designated Grievance Redressal Officer with any complaints
        or queries relating to the Services or these Terms through registered post or
        email, details provided as below:
      </p>
      <p>
        <b>Email Address:&nbsp;</b>
        <a href="mailto:info@camsfinserv.com">info@camsfinserv.com</a>
      </p>
      <a
        href="https://www.camsfinserv.com/grievance/index.html"
        target="_blank"
        rel="noreferrer"
      >
        Click Here
      </a>
      <p>
        <b>
          Registered Address:&nbsp; CAMS Financial Information Services Private Limited
          (CAMSfinserv), New No.10 (Old No.178), MGR Salai, Nungambakkam, Chennai-600034
        </b>
      </p>
      <p>
        (b) We shall ensure that your complaint is resolved within 30 (thirty) days from
        the date of the complaint. You may go ahead and appeal to the RBI If the complaint
        is not redressed within the given period of 30 (thirty) days.
      </p>
      <p>27. MISCELLANEOUS PROVISIONS</p>
      <p>
        a)&nbsp;Modification:&nbsp;We reserve the right at any time to modify these Terms
        and to add new or additional terms or conditions on the use of the Services. Such
        modifications and additional terms and conditions communicated to you and, unless
        expressly rejected (in which these Terms shall terminate), will be effective
        immediately and incorporated into these Terms. In the event you refuse to accept
        such changes, these Terms will terminate.
      </p>
      <p>
        (b)&nbsp;Severability:&nbsp;If any provision of these Terms is determined by any
        court or other competent authority to be unlawful or unenforceable, the other
        provisions of these Terms will continue in effect. If any unlawful or
        unenforceable provision would be lawful or enforceable if part of it were deleted,
        that part will be deemed to be deleted, and the rest of the provision will
        continue in effect (unless that would contradict the clear intention of the
        clause, in which case the entirety of the relevant provision will be deemed to be
        deleted).
      </p>
      <p>
        (c)&nbsp;Assignment:&nbsp;You shall not license, sell, transfer, or assign their
        rights, obligations, or covenants under these Terms in any manner without our
        prior written consent. We may grant or withhold this consent at our sole
        discretion and subject to any conditions we deem appropriate. We may assign our
        rights to any of our affiliates, subsidiaries, parent companies, or any successor
        in interest of any business associated with the Services without prior notice to
        you.
      </p>
      <p>
        (d)&nbsp;Notices:&nbsp;All notices, requests, demands, and determinations for us
        under these Terms (other than routine operational communications) shall be sent to{' '}
        <a href="mailto:info@camsfinserv.com">info@camsfinserv.com</a>
      </p>
      <p>
        (e)&nbsp;Third Party Rights:&nbsp;No third party shall have any rights to enforce
        any terms contained herein.
      </p>
      <p>
        (f)&nbsp;Force Majeure:&nbsp;We shall have no liability to you if we are prevented
        from or delayed in performing our obligations or from carrying on our business by
        acts, events, omissions, or accidents beyond our reasonable control, including,
        without limitation, strikes, failure of a utility service or telecommunications
        network, the act of God, war, riot, civil commotion, pandemic situations,
        malicious damage, compliance with any law or governmental order, rule, regulation,
        or direction.
      </p>
    </span>
  );
};

export default Contents;
