import { browserName } from '../lib/helper';
import { store } from '../store';
import { FiCategory } from '../store/reducers/multiCategory';
import { _request } from './requests';

export type BankListRequestBody = {
  I_MOBILENUMBER: string;
  I_MPIN: string;
  I_BROWSER: string;
  I_asset_class_id: 'MF_ETF_OTHERS' | 'BANK' | 'NPS' | 'INSURANCE' | 'EQUITIES';
  I_SEARCHKEY: string;
  I_SEARCHPAGENATION: string;
  I_ConsentHandle: string;
};

export type bankDetail = {
  FIPID: string;
  FIPNAME: string;
  LOGO: string;
  POPULARBANK: string;
};

export type BankListResponseBody = {
  lst: bankDetail[];
  uuid: string;
  resulT_CODE: string;
  message: string;
  sessioN_ID: string;
};

export type discoverBanksRequestBody = {
  I_MOBILENUMBER: string;
  I_BROWSER: string;
  // For 0, 1, multiple fip ids
  // I_FIPID: string | string[];
  I_FIPID: string;
  I_FIPNAME: string;
  I_Identifier: { I_Flag: string; DATA: string; type: string }[];
  I_ConsentHandle: string;
};

export type consentHandleDetailsRequestBody = {
  I_MOBILENUMBER: string;
  I_BROWSER: string;
  I_ConsentHandle: string;
};

export type ACCOUNTSTOLINK = {
  FIPACCNUM: string;
  FIPACCREFNUM: string;
  FIPACCTYPE: string;
  FIPTYPE: string;
  FIPID: string;
  Logo: string;
};

export type LinkRequestBody = {
  I_MOBILENUMBER: string;
  I_BROWSER: string;
  I_USERID: string;
  I_FIPID: string;
  ACCOUNTS_TO_LINK: ACCOUNTSTOLINK[];
  I_ConsentHandle: string;
};

export interface GetFiCategoryRequestBody {
  I_MOBILENUMBER: string;
  I_FIPTYPES: string[];
  I_BROWSER: string;
  I_SESSION: string | null;
}

export interface GetFiCategoryResponseBody {
  SESSION_ID: string;
  RESULT_CODE: string;
  MESSAGE: string;
  UUID: string;
  FICategories: CategoryIdentifier[];
}

export interface CategoryIdentifier {
  ID: string;
  FI_CATEGORY: FiCategory;
  FI_TYPE: string;
  ISACTIVE: string;
  STRONG_ID1: string;
  WEAK_ID1: string;
  WEAK_ID2: string;
  WEAK_ID1_MANDATORY: string;
  WEAK_ID2_MANDATORY: string;
}

export type Category = {
  type: string;
};

export type Purpose = {
  code: string;
  refUri: string;
  text: string;
  Category: Category;
};

export type Datafilter = {
  type: string;
  operator: string;
  value: string;
};

export type Lst = {
  FIUID: string;
  FIUNAME: string;
  LOGO: string;
  PURPOSECODE: string;
  REFURI: string;
  PURPOSEDESC: string;
  PURPOSE: Purpose;
  CUSTID: string;
  FIDATAFROMDATE: string;
  FIDATATODATE: string;
  CONSENTSTARTDATETIME: string;
  CONSENTEXPIRYDATETIME: string;
  CONSENTHANDLE: string;
  DATACONSUMERID: string;
  DATACONSUMERTYPE: string;
  CONSENTID: string;
  MFIDATAFROMDATE: string;
  MFIDATATODATE: string;
  MCONSENTSTARTDATETIME: string;
  MCONSENTEXPIRYDATETIME: string;
  DATAFILTER: Datafilter[];
  DATALIFEUNIT: string;
  DATALIFEVALUE: number;
  FREQUENCYUNIT: string;
  FREQUENCYVALUE: number;
  FETCHPERIOD: string;
  FITYPES: string;
  CONSENTMODE: string;
  CONSENTTYPES: string;
  FETCHTYPE: string;
  DATAPROVIDERID: string;
  DATAPROVIDERTYPE: string;
  PURPOSECODE1: string;
};

export type consentHandleDetailsResponse = {
  lst: Lst[];
  UUID: string;
  RESULT_CODE: string;
  MESSAGE?: any;
  SESSION_ID: string;
};

export type discoverBanksResponseBody = {
  fip_Namelist: string;
  linked_Mobile: string;
  sessioN_ID: string;
  accountCount: string | number;
  i_Mobile: string;
  refNumber: string;
  i_Email: string;
  i_PAN: string;
  i_AADHAR: string;
  RESULT_CODE: string;
  message: string;
  uuid: string;
  fipName: string;
  identifiers: string;
  fip_NewDiscoverelist: IndBank[];
  result: string;
  failedFips: FailedFips;
};

export type SelectedBankType = {
  [key: string]: IndBank[];
};

export type BankType = {
  FIPACCREFNUM: string;
  FIPACCTYPE: string;
  FIPACCNUM: string;
  FIPID: string;
  FITYPE: string;
  FIPNAME?: string;
  FIPACCLINKREF: string;
  StateName: string;
};

export type IndBank = BankType & {
  LINKEDDATE: string;
  CR_Status: string;
  Id: string;
  ACCDISCOVERYDATE: string;
  Logo: string;
};

export type LinkAccountRequestBody = {
  I_MOBILENUMBER: string;
  I_BROWSER: string;
  I_FIPID: string;
  ACCOUNTS_TO_LINK: ACCOUNTSTOLINK[];
  I_ConsentHandle: string;
};

export type LinkAccountResponseBody = {
  fip_Namelist?: any;
  Linked_Mobile?: any;
  SESSION_ID?: any;
  AccountCount: number;
  I_Mobile?: any;
  RefNumber: string;
  I_Email?: any;
  I_PAN?: any;
  I_AADHAR?: any;
  RESULT_CODE: string;
  MESSAGE: string;
  UUID: string;
  FIPName?: any;
  Identifiers?: any;
  fip_NewDiscoverelist?: any;
  Result?: any;
};

export type AuthenticateTokenRequestBody = {
  I_MOBILENUMBER: string;
  I_BROWSER: string;
  I_FIPID: string;
  I_MOBOTP: string;
  I_FIPACCREFNUM: string;
  I_ConsentHandle: string;
};

export type FipNewDiscoverelist = {
  CR_Status?: any;
  Id?: any;
  FIPID: string;
  FITYPE?: any;
  ACCDISCOVERYDATE?: any;
  FIPACCTYPE: string;
  FIPACCREFNUM: string;
  FIPACCNUM: string;
  FIPACCLINKREF: string;
  LINKEDDATE?: any;
  Logo: string;
};

export type AuthenticateTokenResponseBody = {
  fip_Namelist?: any;
  Linked_Mobile?: any;
  SESSION_ID: string;
  AccountCount: number;
  I_Mobile?: any;
  RefNumber?: any;
  I_Email?: any;
  I_PAN?: any;
  I_AADHAR?: any;
  RESULT_CODE: string;
  MESSAGE: string;
  UUID: string;
  FIPName?: any;
  Identifiers?: any;
  fip_NewDiscoverelist: FipNewDiscoverelist[];
  Result?: any;
};

export type FIPDetailsList = AlreadyLinkedAccountsList & {
  isCardSelected: boolean;
};

export type ConsentArtefactRequestBody = {
  I_MOBILENUMBER: string;
  I_MPIN: string;
  I_BROWSER: string;
  FIPDetailsList: FIPDetailsList[];
  I_ConsentHandle: string;
};

export type GetMobilesRequestBody = {
  I_MOBILENUMBER: string;
  I_BROWSER: string;
  I_ConsentHandle: string;
};

export type GenerateOTPRequestBody = {
  I_BROWSER: string;
  I_MOBILENUMBER: string;
  I_SECONDARY_MOBILE_NUMBER: string;
  I_ConsentHandle: string;
};

export type AddNewNumberRequestBody = {
  I_MOBILENUMBER: string;
  I_SECONDARY_MOBILE_NUMBER: string;
  I_MOBOTP: string;
  I_BROWSER: string;
  I_Flag: string;
  I_ConsentHandle: string;
};

export type ConsentRejectRequestBody = {
  I_MOBILENUMBER: string;
  I_BROWSER: string;
  I_ConsentId: string;
  I_STATUS: string;
};

export type ConsentArtefactResponseBody = {
  ExistUser: boolean;
  ConsentCount?: any;
  ConsentId: string;
  PHOTO?: any;
  UUID: string;
  USER_ID?: any;
  RESULT_CODE: string;
  MESSAGE: string;
  SMSRESULT_CODE?: any;
  SMSMESSAGE?: any;
  USERNAME?: any;
  MOBILE?: any;
  EMAIL?: any;
  SESSION_ID?: any;
  LASTACCTIME?: any;
};

export type MobileList = {
  MobileNumber: string;
};

export type GetMobilesResponseBody = {
  lst: MobileList[];
  UUID: string;
  RESULT_CODE: string;
  MESSAGE: string;
  SESSION_ID: string;
};

export type GenerateOTPResponseBody = {
  ExistUser: boolean;
  ConsentCount?: any;
  ConsentId?: any;
  PHOTO?: any;
  UUID: string;
  USER_ID?: any;
  RESULT_CODE: string;
  MESSAGE: string;
  SMSRESULT_CODE?: any;
  SMSMESSAGE?: any;
  USERNAME?: any;
  MOBILE?: any;
  EMAIL?: any;
  SESSION_ID?: any;
  LASTACCTIME?: any;
};

export type AddNewNumberResponseBody = {
  ExistUser: boolean;
  ConsentCount?: any;
  ConsentId?: any;
  PHOTO?: any;
  UUID: string;
  USER_ID?: any;
  RESULT_CODE: string;
  MESSAGE: string;
  SMSRESULT_CODE?: any;
  SMSMESSAGE?: any;
  USERNAME?: any;
  MOBILE?: any;
  EMAIL?: any;
  SESSION_ID?: any;
  LASTACCTIME?: any;
};

export type ConsentRejectResponseBody = {
  lst?: any;
  UUID: string;
  RESULT_CODE: string;
  MESSAGE: string;
  SESSION_ID: string;
};

export type LinkDescRequestBody = {
  I_MOBILENUMBER: string;
  I_BROWSER: string;
  I_ConsentHandle: string;
};

export type selectedLinkedBank = {
  [key: string]: AlreadyLinkedAccountsList[];
};

export type AlreadyLinkedAccountsList = BankType & {
  LINKEDDATE: string;
  CUSTID: string;
  LINKINGREFNUM: string;
  CONSENTCOUNT: number;
  CONSENTDATE: Date;
  LOGO: string;
};

export type LinkDescResponseBody = {
  lst?: AlreadyLinkedAccountsList[];
  UUID: string;
  RESULT_CODE: string;
  MESSAGE?: any;
  SESSION_ID: string;
};

export type FailedFips = {
  fipNames: string;
  fipIds: string;
};

export function discoverAlreadyLinkedAccount(body: LinkDescRequestBody) {
  const responseBody = _request<LinkDescResponseBody>({
    relativeUrl: `/LINKDESC`,
    method: 'POST',
    body,
    sendUserId: true,
    sendUUID: true,
    // fixtureEnabled: false,
    // json: 'linkDesc.json',
  });
  return responseBody;
}

export async function BankList(body: BankListRequestBody) {
  const responseBody = _request<BankListResponseBody>({
    relativeUrl: `/SearchFIP`,
    method: 'POST',
    body,
    sendUserId: true,
    sendUUID: true,
    fixtureEnabled: false,
    json: 'banklist.json',
  });
  return responseBody;
}
export function $wait(time = 500) {
  return new Promise((res: any) => {
    setTimeout(() => {
      res();
    }, time);
  });
}

async function discover(body: discoverBanksRequestBody) {
  try {
    const responseBody = await _request<discoverBanksResponseBody>({
      relativeUrl: `/DISCOVER`,
      method: `POST`,
      body,
      fixtureEnabled: false,
      json: `discoveredAccounts.json`,
    });
    return { responseBody, error: null, fipid: body.I_FIPID, fipname: body.I_FIPNAME };
  } catch (e) {
    return { responseBody: null, error: e, fipid: body.I_FIPID, fipname: body.I_FIPNAME };
  }
}
export async function discoverBanks(body: discoverBanksRequestBody) {
  const { I_FIPID, I_FIPNAME, ...rest } = body;
  const {
    auth: { decrypt },
  } = store.getState();

  const fipIds = I_FIPID.split(',');
  const fipNames = I_FIPNAME.split(',');

  const requests = fipIds.map((fipId, index) => {
    const requestBody = {
      ...rest,
      I_FIPID: fipId,
      I_FIPNAME: fipNames[index],
      I_FITYPE: decrypt?.fIType || '',
    };
    const response = discover(requestBody);
    return response;
  });

  const responses = await Promise.all(requests);

  const failedFips = responses
    .filter(
      (response) =>
        response.responseBody?.RESULT_CODE !== '200' ||
        response.error ||
        response.responseBody === null ||
        response.responseBody?.fip_NewDiscoverelist === null ||
        response.responseBody?.fip_NewDiscoverelist?.length === 0,
    )
    .reduce(
      (result, item) => {
        result.fipIds += item.fipid + ',';
        result.fipNames += item.fipname + ',';
        return result;
      },
      { fipIds: '', fipNames: '' },
    );
  failedFips.fipIds = failedFips.fipIds.slice(0, -1);
  failedFips.fipNames = failedFips.fipNames.slice(0, -1);
  let fip_Namelist = responses.reduce<Partial<discoverBanksResponseBody>>(
    (accumulator, response) => {
      const { responseBody } = response;
      if (responseBody) {
        const updatedFipDiscoveryList: discoverBanksResponseBody['fip_NewDiscoverelist'] =
          [
            ...(responseBody?.fip_NewDiscoverelist || []),
            ...(accumulator?.fip_NewDiscoverelist || []),
          ];
        return {
          ...accumulator,
          fip_NewDiscoverelist: updatedFipDiscoveryList,
        };
      }
      console.error(response.error);
      return accumulator;
    },
    {},
  );
  return { ...fip_Namelist, failedFips };
}

export async function consentHandle(body: consentHandleDetailsRequestBody) {
  const responseBody = _request<consentHandleDetailsResponse>({
    relativeUrl: `/GETCONSENTHANDLEDETAILS`,
    method: 'POST',
    body,
    json: 'consentHandleDetails.json',
    fixtureEnabled: false,
  });
  return responseBody;
}

export async function LinkBankAccount(body: LinkAccountRequestBody) {
  const responseBody = _request<LinkAccountResponseBody>({
    relativeUrl: `/Link`,
    method: 'POST',
    body,
  });
  return responseBody;
}

export async function AuthenticateToken(body: AuthenticateTokenRequestBody) {
  const responseBody = _request<AuthenticateTokenResponseBody>({
    relativeUrl: `/AuthenticateToken`,
    method: 'POST',
    body,
  });
  return responseBody;
}

export async function ConsentArtefact(body: ConsentArtefactRequestBody) {
  const responseBody = _request<ConsentArtefactResponseBody>({
    relativeUrl: `/ConsentArtefact_V1`,
    method: 'POST',
    body,
  });
  return responseBody;
}

export async function getMobileNumbers(body: GetMobilesRequestBody) {
  const responseBody = _request<GetMobilesResponseBody>({
    relativeUrl: `/GETMOBILENUMBERS`,
    method: 'POST',
    body,
  });
  return responseBody;
}

export async function generateOTP(body: GenerateOTPRequestBody) {
  const responseBody = _request<GenerateOTPResponseBody>({
    relativeUrl: `/GENERATEOTP`,
    method: 'POST',
    body,
  });
  return responseBody;
}

export async function addNewMobile(body: AddNewNumberRequestBody) {
  const responseBody = _request<AddNewNumberResponseBody>({
    relativeUrl: `/ADDNEWMOBILE`,
    method: 'POST',
    body,
  });
  return responseBody;
}

export async function rejectConsent(body: ConsentRejectRequestBody) {
  const responseBody = _request<ConsentRejectResponseBody>({
    relativeUrl: `/ConsentStatusNotification`,
    method: 'POST',
    body,
  });
  return responseBody;
}

export async function getFiCategoryByType(body: GetFiCategoryRequestBody) {
  const responseBody = _request<GetFiCategoryResponseBody>({
    relativeUrl: '/GetFICategoryByType',
    method: 'POST',
    fixtureEnabled: false,
    json: 'categoryList.json',
    body,
  });
  return responseBody;
}

export async function getFipNames() {
  const responseBody = _request<any>({
    relativeUrl: '/GETFIPDisplayName',
    method: 'POST',
    mode: 'no-cors',
    body: {
      I_BROWSER: browserName,
    },
    sendSessionID_last_call: false,
    sendSessionID_login: false,
    sendSessionID_decrypt: false,
    sendUserId: false,
    sendConsentHandle: false,
  });
  return responseBody;
}
