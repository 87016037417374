import { combineReducers } from 'redux';

import { BankState } from '../types/bankListType';
import { AuthState } from '../types/login';
import authReducer from './auth-reducer';
import authenticatedBanksReducer from './authenticatedBanksReducer';
import { AuthBankState } from './authenticatedBanksReducer';
import bankListReducer from './bankListReducer';
import multiCategoryReducer, { MultiCategory } from './multiCategory';
import uiReducer, { UIState } from './ui-reducer';

export interface RootStateType {
  auth: AuthState;
  bank: BankState;
  ui: UIState;
  authBanks: AuthBankState;
  multiCategory: MultiCategory;
}

export const rootReducer = combineReducers({
  auth: authReducer,
  bank: bankListReducer,
  ui: uiReducer,
  authBanks: authenticatedBanksReducer,
  multiCategory: multiCategoryReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
