import { AnyAction } from 'redux';

import {
  AES256_XOR_DECRYPT_SUCCESS,
  AuthState,
  LOGOUT_SUCCESS,
  UPDATE_LOGIN_SESSION_ID,
  UPDATE_PAN,
  UPDATE_SESSION_ID,
  USER_HANDLE_SUCCESS,
  USER_REG_MOBILE,
  USER_REG_MOBILE_SUCCESS,
  MULTI_CONSERN,
  MULTI_consent_Array
} from '../types/login';

const initialState: Partial<AuthState> = {
  sessionId_decrypt: null,
  sessionId_login: null,
  sessionId_last_call: null,
  decrypt: null,
  loginResponse: null,
  addNumberFlow: false,
};

function authReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case AES256_XOR_DECRYPT_SUCCESS: {
      const { fIType } = action.body;
      return {
        ...state,
        decrypt: {
          ...action.body,
          userid: action.body.userid.split('@')[0],
          ...(fIType?.length ? { fiTypes: fIType.split(',') } : {}),
        },
        sessionId_decrypt: action.body.sessionid,
        addNumberFlow:
          action.body.addfip === ''
            ? true
            : action.body.addfip.toLowerCase() === 'false'
            ? false
            : true,
      };
    }
    case UPDATE_PAN: {
      const { pan } = action.body;
      return {
        ...state,
        decrypt: {
          ...state.decrypt,
          pan: pan,
        },
      };
    }
    case UPDATE_SESSION_ID: {
      return {
        ...state,
        sessionId_last_call: action.sessionid,
      };
    }
    case UPDATE_LOGIN_SESSION_ID: {
      return {
        ...state,
        sessionId_login: action.sessionid,
      };
    }
    case USER_REG_MOBILE_SUCCESS:
    case USER_REG_MOBILE:
    case USER_HANDLE_SUCCESS:
      return {
        ...state,
        handle: action.body,
        sessionId: action.body.sessionid,
      };
      case MULTI_CONSERN: {
        return{
          ...state,
          dynData: action.body.dynData
        }
      }
      case MULTI_consent_Array: {
        return{
          ...state,
          consentData: action.body.consentData
        }
      }
    case LOGOUT_SUCCESS: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}

export default authReducer;
