/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  consentHandle as getConsentCarouselDetails,
  consentHandleDetailsRequestBody,
  getFiCategoryByType,
} from '../../api/banks';
import {
  decryptParameters,
  DecryptRequestBody,
  getIPAddress,
  RegisterLoginRequestBody,
  registerLoginUser,
  ValidateOTPRequestBody,
  VerifyLoginOtp,
} from '../../api/login';
import { useAppThemeContext } from '../../AppTheme';
import { useTimer } from '../../hooks/useTimer';
import { browserName, logEvent, setGAUserId } from '../../lib/helper';
import { RootStateType } from '../../store/reducers';
import {
  AES256_XOR_DECRYPT_SUCCESS,
  AuthState,
  MULTI_CONSERN,
  MULTI_consent_Array,
  UPDATE_LOGIN_SESSION_ID,
} from '../../store/types/login';
import MemoizedConsentDetails from '../ConsentBottomDrawer/consentDetails';
import { FormOTPInput } from '../forms/FormOTPInput';
import FormSubmitButton from '../forms/FormSubmitButton';
import { ResendOtp } from '../otpField';
import RedirectingModal from '../RedirectingModal';
import TCModal from '../TC';
import { LoginBanner, LoginLogo, LoginParagraph } from './components';
import { SET_CONSUMER_DETAILS } from '../../store/types/bankListType';
import { useNavigate } from 'react-router-dom';

interface Values {
  otp: string;
}

const initialValues: Values = { otp: '' };

const validationSchema = yup.object().shape({
  otp: yup.string().min(6, `OTP should be 6 characters`).required('OTP is required'),
});

export default function VerifyUser(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState('');
  const [isConsentDrawerVisible, toggleConsentDrawer] = useState(false);
  const { decrypt, dynData } = useSelector<RootStateType, AuthState>((state) => state.auth);
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState({
    status: false,
    info: 'We have partnered with CAMSfinserv, an RBI registered Account Aggregator for fetching your data, you will shortly receive an OTP from CAMSfinserv',
    subInfo: '',
    moreInfo: '',
    showDemo: false,
  });
  let consentFI: any = ""
  let consentValue = ""
  const { timer, startTimer, stopTimer, resetTimer } = useTimer({});
  const { updateColorScheme } = useAppThemeContext();

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, []);

  useEffect(() => {
    async function callApi(decryptRequestBody: DecryptRequestBody) {
      try {
        const decryptResponse = await decryptParameters(decryptRequestBody);
        dispatch({ type: AES256_XOR_DECRYPT_SUCCESS, body: decryptResponse });
        updateColorScheme(decryptResponse.fiuColourScheme);
        setGAUserId(decryptResponse.userid);
        logEvent({
          category: 'Landing Page',
          action: '1 FIP Redirection',
          label: 'Unique Customers/Sessions on the landing page',
          value: 1,
        });
      
        if(typeof(decryptResponse?.srcref) === 'object'){
          let dynValue : any = [];
          decryptResponse?.srcref.map(((val : any, index: number) => {
            const newValue = val.split('|');
           // console.log(val.split('|'))
            dynValue.push(newValue[2]);
           // console.log('val',newValue[2])
            
          }))
          dispatch({
            type: MULTI_consent_Array,
            body: {consentData : dynValue},
       })
          console.log("dynValue",dynValue);
          dynValue.map((val : any, index: any) => {
             consentValue = consentValue + val 
             if(index === dynValue.length-1){
              consentValue = consentValue
             }else{
              consentValue = consentValue + ","
             }
          })
         console.log("consentValue",consentValue)
      
          dispatch({
            type: MULTI_CONSERN,
            body: {dynData : consentValue},
       })
        }else{
          let dynValue : any = [];
          dynValue.push(decryptResponse?.srcref)
          dispatch({
            type: MULTI_consent_Array,
            body: {consentData : dynValue},
       })
          dispatch({
            type: MULTI_CONSERN,
            body: {dynData : decryptResponse?.srcref},
       })
        }
       
        callRegisterLoginAPI(decryptResponse);
      } catch (err) {
        console.error(err);
        // alert((err as Error).message);
        alert("Please check your internet connection and try again.");
      }
    }
   
    const decryptBody: DecryptRequestBody = {
      fiuId: !!searchParams.get('fi')!? searchParams.get('fi')!.replaceAll(/ /g,"+") : " ",
      ecRequest: searchParams.get('ecreq')!,
      reqDate: searchParams.get('reqdate')!,
    };
    if (import.meta.env.MODE !== 'prod') {
      setLoader({ ...loader, status: true, showDemo: !searchParams.get('ecreq')});
    }

    if (!decryptBody.fiuId || !decryptBody.ecRequest || !decryptBody.reqDate) {
      return;
    } else {
      callApi(decryptBody);
    }
  }, []);

  const callRegisterLoginAPI = async (xorDecryptData: any) => {
    stopTimer();
    const decryptRes = xorDecryptData ?? decrypt;
    const I_CLIENTIP = await getIPAddress();
    const registerLoginBody: RegisterLoginRequestBody = {
      I_MOBILENUMBER: decryptRes?.mobile,
      I_CLIENTIP,
      I_BROWSER: browserName,
      I_MPIN: '123456',
      I_ConsentHandle: consentValue ? consentValue : dynData
    };

    const registerResponse = await registerLoginUser(registerLoginBody);
    if (registerResponse?.RESULT_CODE === '200') {
      resetTimer();
    }
    setLoader({ ...loader, status: false });
  };

  const onResend = async () => {
    try {
      setHasError(false);
      setMessage('');
      callRegisterLoginAPI(decrypt);
    } catch (err: any) {
      alert(err.message);
    }
  };

  const callhandleDetailsAPI = async () => {
    try {
      const handleBody: consentHandleDetailsRequestBody = {
        I_MOBILENUMBER: decrypt!.mobile,
        I_BROWSER: browserName,
        I_ConsentHandle: dynData,
      };
      const consentResponse = await getConsentCarouselDetails(handleBody);
      if (consentResponse.RESULT_CODE === '200') {
        dispatch({
          type: 'STORE_CONSENT_CAROUSEL_DETAILS',
          body: { consentDetails: consentResponse.lst },
        });
        dispatch({
          type: SET_CONSUMER_DETAILS,
          body : {
          logo: consentResponse.lst[0].LOGO,
          consumer_id: consentResponse.lst[0].DATACONSUMERID,
          purpose: consentResponse.lst[0].PURPOSEDESC,
        }
        });
        consentResponse.lst.map((value: any, consentIndex: any) => {
          let FIvalue = value.FITYPES?.split(",")
          FIvalue.map((type: any, typeIndex: any)=> {
            if(!consentFI.includes(type)){
              if(consentIndex === consentResponse.lst.length-1 && typeIndex === FIvalue.length-1){
                consentFI = consentFI + type 
              }else{
              consentFI = consentFI + type + ","
            }}
          })
        })
        console.log("consentFI",consentFI)
      }
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const onSubmit = async (
    values: Values,
    { setSubmitting, resetForm }: FormikHelpers<Values>,
  ) => {
    try {
      setLoader({ ...loader, status: true, info: 'Logging User In' });
      const I_CLIENTIP = await getIPAddress();

      const payload: ValidateOTPRequestBody = {
        I_MOBILENUMBER: decrypt?.mobile || '',
        I_MOBOTP: values.otp,
        I_BROWSER: browserName,
        I_CLIENTIP,
        I_Flag: 'M',
        I_USERID:decrypt.mobile + '@CAMSAA',
        I_ConsentHandle: dynData
      };
      const VerifyOTPResponse = await VerifyLoginOtp(payload);
      if (VerifyOTPResponse.RESULT_CODE === '200') {
        setHasError(false);
        stopTimer();
        logEvent({
          category: 'Landing Page',
          action: 'LOGIN OTP',
          label: 'Unique Customers/Sessions who have entered OTP',
          value: 1,
        });
        setMessage('Verified Successfully');
        setIsVerified(true);
        dispatch({
          type: UPDATE_LOGIN_SESSION_ID,
          sessionid: VerifyOTPResponse.SESSION_ID,
        });

        await callhandleDetailsAPI();
        const getFiCategoryByTypeResponse = await getFiCategoryByType({
          I_BROWSER: browserName,
          I_FIPTYPES: decrypt?.fiTypes ? decrypt?.fiTypes : consentFI?.split(","),
          I_MOBILENUMBER: decrypt?.mobile,
          I_SESSION: VerifyOTPResponse.SESSION_ID,
        });

        const { FICategories } = getFiCategoryByTypeResponse;
        dispatch({
          type: 'ADD_FI_CATEGORIES',
          body: { fiTypes: FICategories },
        });
        navigate('/link-account');
      } else {
        logEvent({
          category: 'Landing Page',
          action: 'LOGIN OTP',
          label: 'Unique Customers/Sessions who have entered OTP',
          value: 0,
        });
        resetForm();
        setHasError(true);
        setMessage('Invalid OTP! Try again.');
      }

      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
    } finally {
      setLoader({ ...loader, status: false });
    }
  };

  return (
    <Grid container component="main">
      <Grid
        item
        xs={12}
        component={Paper}
        square
        sx={{
          padding: '50px 16px 35px',
          boxShadow: 'none',
          backgroundColor: '#f8f9fc',
        }}
      >
        <LoginLogo />
        <Grid item container>
          <Grid item xs={12} sx={{ paddingBottom: '20px' }}>
            <LoginBanner />
            <LoginParagraph>
              We have sent an OTP to your registered
              <br /> mobile number
            </LoginParagraph>
            <Typography
              sx={{
                fontSize: 21,
                fontWeight: 700,
                color: '#333',
                marginBottom: '15px',
              }}
            >
              +91 {decrypt?.mobile || ''}
            </Typography>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <FormOTPInput
                    name="otp"
                    label="Enter OTP"
                    isVerified={isVerified}
                    message={message}
                    hasError={hasError}
                    onSubmit={() => {
                      handleSubmit();
                    }}
                  />
                  {!isVerified ? (
                    <ResendOtp
                      counter={timer}
                      startTimer={startTimer}
                      onResendOtp={onResend}
                    />
                  ) : null}
                  <Grid
                    container
                    sx={{
                      textAlign: 'center',
                      maxWidth: '500px',
                      margin: 'auto',
                      padding: '5px 0px',
                      backgroundColor: '#f8f9fc',
                      '@media screen and (max-width: 600px)': {
                        position: 'relative',
                        paddingTop: '20px',
                      },
                    }}
                  >
                    {/* <MemoizedConsentDetails
                      toggleCarousel={toggleConsentDrawer}
                      carouselType="preLogin"
                      isConsentDrawerVisible={isConsentDrawerVisible}
                    /> */}

                    <FormSubmitButton label="Confirm" />
                    <Typography sx={{ fontSize: 11, color: '#333' }}>
                      By confirming, you are accepting{' '}
                      <span
                        style={{
                          color: 'blue',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          setConfirm(true);
                        }}
                      >
                        T&C
                      </span>{' '}
                      of CAMSfinserv platform. CAMSfinserv is an RBI Licensed Account
                      Aggregator.
                    </Typography>
                  </Grid>
                </Box>
              )}
            </Formik>
            <RedirectingModal
              info={loader.info}
              opened={loader.status}
              showDemo={loader.showDemo}
            />

            <TCModal confirm={confirm} setConfirm={setConfirm} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
