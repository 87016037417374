import { Box, Button, Drawer, Grid, Typography } from '@mui/material';
import * as React from 'react';

import { Close } from './Svg';

export function SkipBottomDrawer({
  isOpen,
  onClickPrimary,
  onClickSecondary,
  textPrimary,
  textSecondary,
  buttonLabelPrimary,
  buttonLabelSecondary,
  closeDrawer,
}: CommonDrawerProps) {
  return (
    <Box>
      <Drawer
        anchor={'bottom'}
        open={isOpen}
        PaperProps={{ elevation: 0, style: { borderRadius: '25px 25px 0 0' } }}
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
            margin: 'auto',
          },
        }}
      >
        <Box
          style={{
            display: 'flex',
            backgroundColor: '#EBF6FF',
            fontWeight: 700,
            padding: '12px 0',
            justifyContent: 'space-around',
          }}
        >
          <Box display="flex" width={'100%'}>
            <Box width="50%" />
            <Box display="flex" width="100%" justifyContent={'space-between'}>
              <Typography
                component="p"
                style={{
                  lineHeight: '2.5',
                  fontWeight: '600',
                  fontSize: '19px',
                }}
                color="primary.main"
              >
                Important
              </Typography>
              <Button
                sx={{
                  textTransform: 'unset',
                  color: '#F57071',
                }}
                onClick={closeDrawer}
              >
                <Close />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: '34px 30px' }}>
          <Grid
            container
            direction="column"
            display="flex"
            spacing={4}
            sx={{ width: '100%', margin: 0, marginBottom: '20px' }}
          >
            <Grid item style={{ paddingLeft: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignContent: 'center',
                  color: 'black',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    marginTop: '-24px',
                    fontWeight: '700',
                    marginBottom: '16px',
                    fontSize: '16px',
                    overflowWrap: 'anywhere',
                  }}
                  variant="body1"
                >
                  {textPrimary}
                </Typography>
                {textSecondary ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      textAlign: 'center',
                      color: '#777F88',
                    }}
                    variant="body2"
                  >
                    {textSecondary}
                  </Typography>
                ) : null}
              </Box>
            </Grid>
            <Grid item style={{ paddingLeft: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    fontSize: '15px',
                    width: '150px',
                  }}
                  onClick={onClickSecondary}
                >
                  {buttonLabelSecondary}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    fontSize: '15px',
                    width: '150px',
                    color: 'white',
                  }}
                  onClick={onClickPrimary}
                >
                  {buttonLabelPrimary}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}

export type CommonDrawerProps = {
  isOpen: boolean;
  textPrimary: string;
  textSecondary?: string;
  onClickPrimary: any;
  onClickSecondary: any;
  buttonLabelPrimary: string;
  buttonLabelSecondary: string;
  closeDrawer: () => void;
};
