import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  BankType,
  ConsentArtefact,
  ConsentArtefactRequestBody,
  FipNewDiscoverelist,
  IndBank,
} from '../../api/banks';
import { browserName, closeAndRedirect, logEvent } from '../../lib/helper';
import { RootStateType } from '../../store/reducers';
import { AuthBankState } from '../../store/reducers/authenticatedBanksReducer';
import { BankState } from '../../store/types/bankListType';
import { AuthState } from '../../store/types/login';
import BankSelectionCheckbox from '../BankSelectionCheckbox';
import BottomDrawer from '../BottomDrawer/BottomDrawer';
import ConfirmationBottomDrawer from '../BottomDrawer/ConfirmationBottomDrawer';
import Header from '../header';
import { CustomBorderedButton, CustomButton, CustomHeading } from '../login/components';
import RedirectingModal from '../RedirectingModal';

export default function Summary() {
  const [confirm, setConfirm] = useState(false);
  const location = useLocation();

  const [loader, setLoader] = useState({
    status: false,
    info: '',
    subInfo: '',
    moreInfo: '',
  });
  const [showAddNumberDrawer, setShowAddNumberDrawer] = useState(false);
  const { selectedBank, consumer } = useSelector<RootStateType, BankState>(
    (state) => state.bank,
  );
  const phoneNumber = (location.state as any).phoneNumber;
  const [consentChecked, setConsentChecked] = useState(true);
  const { addNumberFlow, decrypt } = useSelector<RootStateType, AuthState>(
    (state) => state.auth,
  );

  const { authBanks } = useSelector<RootStateType, AuthBankState>(
    (state) => state.authBanks,
  );

  // User selected bank / FIP
  const [checkedBanks, setCheckedBanks] = useState<any>([selectedBank[phoneNumber][0]]);

  const provideConsent = async () => {
    logEvent({
      category: 'Summary Page',
      action: 'Summary and Consent approval',
      label:
        'Unique Customer user_id/session id where customer selects atleast one bank account, clicks on the checkbox and then clicks on Submit Consent button',
      value: 1,
    });
    try {
      const authenticatedBanks = Object.values(selectedBank).flat() || [];
      // console.log('The auth banks are:', authBanks);
      const fipDetailsList = checkedBanks.map((bank: IndBank) => {
        return {
          CUSTID: decrypt!.mobile,
          FIPID: bank.FIPID,
          FIPACCLINKREF: authBanks.find(
            (authBank: FipNewDiscoverelist) =>
              authBank.FIPACCREFNUM === bank.FIPACCREFNUM,
          )!.FIPACCLINKREF,
          FIPACCREFNUM: bank.FIPACCREFNUM,
          LINKINGREFNUM: authBanks.find(
            (authBank: FipNewDiscoverelist) =>
              authBank.FIPACCREFNUM === bank.FIPACCREFNUM,
          )!.FIPACCLINKREF,
          LINKEDDATE: bank.LINKEDDATE,
          FIPACCTYPE: bank.FIPACCTYPE,
          FIPACCNUM: bank.FIPACCNUM,
          FITYPE: bank.FITYPE,
          CONSENTCOUNT: 1,
          CONSENTDATE: new Date(),
          LOGO: bank.Logo,
          FIPNAME:
            bank.FIPNAME ||
            authenticatedBanks.find(
              (authBank: FipNewDiscoverelist) =>
                authBank.FIPACCREFNUM === bank.FIPACCREFNUM,
            )!.FIPNAME,
          isCardSelected: true,
        };
      });

      const consentBody: ConsentArtefactRequestBody = {
        I_MOBILENUMBER: decrypt!.mobile,
        I_MPIN: '111111',
        I_BROWSER: browserName,
        I_ConsentHandle: decrypt?.srcref,
        FIPDetailsList: fipDetailsList,
      };
      setLoader({
        ...loader,
        status: true,
        info: 'Submitting Consent...',
      });
      const consentResponse = await ConsentArtefact(consentBody);

      if (consentResponse.RESULT_CODE === '200') {
        setLoader({
          ...loader,
          status: true,
          info: 'Thank you!',
          moreInfo: 'Consent is received successfully.',
          subInfo: `You will be led to ${
            decrypt!.fiuName
          } website to continue your journey.`,
        });
        closeAndRedirect({
          url: decrypt?.redirect,
          parentStatusMessage: 'ACCEPTED',
          decrypt,
        });
      } else {
        // FIXME: Confirm if the status should be REJECTED or ERROR. Also confirm if we should have the delay of 3 seconds.
        closeAndRedirect({
          parentStatusMessage: 'REJECTED',
          decrypt,
          url: decrypt?.redirect,
        });
      }
    } catch (err) {
      console.error(err);
      // FIXME: Confirm if the status should be REJECTED or ERROR. Also confirm if we should have the delay of 3 seconds.
      closeAndRedirect({
        parentStatusMessage: 'REJECTED',
        decrypt,
        url: decrypt?.redirect,
      });
    }
  };

  const onAddBank = async (status: boolean, bank: any) => {
    if (status) {
      const selectedBanks: BankType[] = [bank];
      setCheckedBanks(selectedBanks);
    } else {
      setCheckedBanks([]);
    }
  };

  return (
    <Grid component={'main'}>
      <Header title="Summary" />
      <Grid component={'section'} sx={{ padding: '24px 16px 250px' }}>
        <CustomHeading info="Selected Accounts" style={{ marginBottom: '10px' }} />
        {Object.entries(selectedBank).map(([key, value]) => (
          <>
            <Typography
              sx={{
                fontSize: '16px',
                color: '#333',
                marginBottom: '10px',
                fontWeight: 600,
              }}
            >
              +91 {key}
            </Typography>
            {(value as any).map((bank: IndBank) => {
              // const selectedBanks = selectedList[key] ? selectedList[key] : [];
              return (
                <BankSelectionCheckbox
                  key={bank.FIPACCREFNUM}
                  logo={bank.Logo}
                  bankName={bank.FIPNAME || bank.FIPID}
                  accountNo={bank.FIPACCNUM}
                  accountType={bank?.FIPACCTYPE || ''}
                  linked={bank?.LINKEDDATE ? true : false}
                  checked={
                    checkedBanks.filter(
                      (cBank: any) => cBank.FIPACCREFNUM === bank.FIPACCREFNUM,
                    ).length > 0
                  }
                  onChange={(status: boolean) => {
                    onAddBank(status, bank);
                  }}
                />
              );
            })}
          </>
        ))}

        <CustomBorderedButton
          label="+ Add More Account"
          onClick={() => {
            if (addNumberFlow) {
              logEvent({
                category: 'Summary Page',
                action: 'Add More Accounts',
                label:
                  'Unique Customer user_id/session id where customer clicks on Add More Account button',
                value: 1,
              });
            }
            setShowAddNumberDrawer(true);
          }}
        />
        <Typography
          sx={{
            fontSize: '13px',
            color: '#333',
            fontWeight: 300,
            textAlign: 'center',
            fontStyle: 'italic',
          }}
        >
          Adding more account will increase the chance of approval quickly
        </Typography>
      </Grid>
      <Grid
        sx={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          background: '#fff',
          boxShadow: '0px -4px 3px rgb(226 226 226)',
          paddingTop: '10px',
          right: 0,
          width: '100%',
          maxWidth: '500px',
          margin: 'auto',
        }}
      >
        <FormGroup style={{ padding: '0 16px' }}>
          <FormControlLabel
            sx={{
              margin: 0,
              marginBottom: '15px',
              alignItems: 'flex-start',
              '& .MuiCheckbox-root': {
                padding: 0,
                marginRight: '12px',
              },
              '& .MuiFormControlLabel-label': {
                color: '#333',
                fontSize: '14px',
                lineHeight: '19px',
                fontWeight: 400,
              },
            }}
            control={
              <Checkbox
                checked={consentChecked}
                onClick={() => {
                  setConsentChecked(!consentChecked);
                }}
              />
            }
            label={`I authorize ${decrypt!.fiuName} to use my bank statement for ${
              consumer.purpose
            }.`}
          />
        </FormGroup>
        <Grid sx={{ padding: '20px 16px' }}>
          <CustomButton
            label="Submit Consent"
            onClick={() => provideConsent()}
            disabled={!consentChecked || checkedBanks.length === 0}
          />
          <Button
            sx={{
              fontSize: '18px',
              color: 'primary.main',
              fontWeight: 400,
              textTransform: 'unset',
              display: 'flex',
              margin: 'auto',
            }}
            onClick={() => setConfirm(true)}
          >
            Reject Consent
          </Button>
        </Grid>
      </Grid>
      {addNumberFlow && (
        <BottomDrawer flag={showAddNumberDrawer} setFlag={setShowAddNumberDrawer} />
      )}
      <ConfirmationBottomDrawer
        confirm={confirm}
        setConfirm={setConfirm}
        type={'reject'}
        message={'Are you sure you want to reject the consent?'}
        sentvalue={0}
      />
      <RedirectingModal
        info={loader.info}
        moreInfo={loader.moreInfo}
        subInfo={loader.subInfo}
        opened={loader.status}
        setModal={(type: any) => setLoader({ ...loader, status: type })}
      />
    </Grid>
  );
}
