import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { ConsentDetailsIcon } from '../CustomComponents/Svg';

import MemoizedConsentDetails from './consentDetails';

export default function ConsentBottomDrawer({consentData}:{consentData: any}) {
  const [isVisible, setIsVisible] = useState(false);
  // console.log("consentData",consentData)
  return (
    <>
      <Button
        onClick={() => {
          setIsVisible(true);
        }}
        startIcon={<ConsentDetailsIcon />}
        sx={{
          position: 'relative',
          '&:after': {
            position: 'absolute',
            content: '""',
            width: 'calc(100% - 8px)',
            height: '1px',
            left: 4,
            bottom: '2px',
            bgcolor: 'primary.main',
          },
        }}
      >
        <Typography
          sx={{
            // textDecoration: 'underline',
            textTransform: 'none',
            fontWeight: '600',
            fontSize: '14px',
          }}
        >
          Consent Details
        </Typography>
      </Button>

      <MemoizedConsentDetails
        carouselType="postLogin"
        isConsentDrawerVisible={isVisible}
        toggleCarousel={setIsVisible}
        consentData={consentData}
      />
    </>
  );
}
