import 'react-alice-carousel/lib/alice-carousel.css';

import { Grid, Stack, SxProps, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Outlet, Route, Routes } from 'react-router-dom';

import { AppThemeContextProvider } from './AppTheme';
import AddAccount from './components/AddAccount/AddAcount';
import LinkAccountMulti from './components/LinkMultiCategoryFip';
import LoginRouter from './components/login';
import OtpVerification from './components/OtpVerification';
import ShowSnackbar from './components/Snackbar';
import Summary from './components/Summary';
import Demo from './Demo';
import { initializeGoogleAnalytics, setupGAPageView } from './lib/helper';
import ScrollToTop from './lib/scrollToTop';
import { store } from './store';
initializeGoogleAnalytics();

function App() {
  useEffect(() => {
    setupGAPageView();
  }, []);

  return (
    <AppThemeContextProvider>
      <CssBaseline />
      <Provider store={store}>
        <ScrollToTop />
        <ShowSnackbar />
        <AppRoutes />
      </Provider>
    </AppThemeContextProvider>
  );
}

export function PoweredBy({ sx = {} }: { sx?: SxProps }) {
  return (
    <Box sx={{ background: '#f5f8f8', textAlign: 'center', width: '100%', ...sx }}>
      <Typography
        sx={{ fontSize: 12, color: '#333', fontWeight: 500, display: 'inline-flex' }}
      >
        Powered by &nbsp;
        <img src="/images/cams-finserv.svg" alt="icon" />
      </Typography>
    </Box>
  );
}

function LayoutComponent() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        backgroundColor: 'secondary.main',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Grid
        sx={{
          minHeight: '100vh',
          width: '100%',
          maxWidth: 500,
          margin: 'auto',
          // bgcolor: '#f5f8f8',
        }}
      >
        <Outlet />
        <PoweredBy
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
            width: '100%',
            maxWidth: 500,
            margin: 'auto',
          }}
        />
      </Grid>
    </Stack>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LayoutComponent />}>
        <Route path="/" element={<LoginRouter />} />
        <Route path="/link-account" element={<LinkAccountMulti />} />
        <Route path="/add-account" element={<AddAccount />} />
        <Route path="/otp-verification" element={<OtpVerification />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="/demo" element={<Demo />} />
      </Route>
    </Routes>
  );
}

export default App;
