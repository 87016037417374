import { Box, Grid, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import {
  ACCOUNTSTOLINK,
  AuthenticateToken,
  AuthenticateTokenRequestBody,
  ConsentArtefact,
  ConsentArtefactRequestBody,
  FIPDetailsList,
  FipNewDiscoverelist,
  IndBank,
  LinkAccountRequestBody,
  LinkBankAccount,
} from '../../api/banks';
import { useTimer } from '../../hooks/useTimer';
import { browserName, closeAndRedirect, logEvent } from '../../lib/helper';
import { RootStateType } from '../../store/reducers';
import { ADD_AUTHENTICATED_BANK } from '../../store/types/bankListType';
import { AuthState } from '../../store/types/login';
import { FormOTPInput } from '../forms/FormOTPInput';
import Header from '../header';
import { CustomButton, LoginParagraph } from '../login/components';
import { ResendOtp } from '../otpField';
import RedirectingModal from '../RedirectingModal';

export default function OtpVerification() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState({
    status: false,
    info: '',
    subInfo: '',
    moreInfo: '',
  });
  const [linkResponse, setLinkResponse] = useState((location.state as any).linkResponse);
  const checkedBank = (location.state as any).checkedBank;
  const selectedFIP = (location.state as any).selectedFIP;
  const [RefNumber, setRefNumber] = useState((location.state as any).RefNumber);
  const phoneNumber = (location.state as any).phoneNumber;
  const { decrypt, addNumberFlow, dynData } = useSelector<RootStateType, AuthState>(
    (state) => state.auth,
  );
  const [isVerified, setIsVerified] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState('');

  const otpLength = (import.meta.env.VITE_EIGHT_DIGIT_OTP || '')
    .split(',')
    .includes(selectedFIP)
    ? 8 : (import.meta.env.VITE_FOUR_DIGIT_OTP || '').split(',').includes(selectedFIP) ? 4 
    : 6;

  interface Values {
    otp: string;
  }
  const initialValues: Values = { otp: '' };
  const { timer, stopTimer, resetTimer } = useTimer({});
  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .min(otpLength, `OTP should be ${otpLength} characters`)
      .required('OTP is required'),
  });

  const onSubmit = async (
    values: Values,
    { setSubmitting, resetForm }: FormikHelpers<Values>,
  ) => {
    try {
      setSubmitting(false);
      setLoader({ ...loader, status: true, info: 'Validating OTP' });
      const authenticateBody: AuthenticateTokenRequestBody = {
        I_MOBILENUMBER: phoneNumber,
        I_BROWSER: browserName,
        I_FIPID: checkedBank[0].FIPID,
        I_FIPACCREFNUM: RefNumber,
        I_MOBOTP: values.otp,
        I_ConsentHandle: dynData,
      };

      const authenticateResponse = await AuthenticateToken(authenticateBody);
      if (authenticateResponse.RESULT_CODE === '200') {
        dispatch({
          type: ADD_AUTHENTICATED_BANK,
          body: authenticateResponse.fip_NewDiscoverelist,
        });
        setIsVerified(true);
        setMessage('Verified Successfully');
        if (addNumberFlow) {
          if (decrypt.mobile === phoneNumber) {
            logEvent({
              category: 'OTP Verification by FIP page',
              action: 'Linking & Submit Consent ',
              label:
                'Unique Customer user_id/session id on entering OTP and clicking on Confirm button',
              value: 1,
            });
          }
          // logEvent({
          //   category: 'OTP Verification page',
          //   action: 'Linking & Submit Consent ',
          //   label:
          //     'Unique Customer user_id/session id on entering OTP and clicking on Confirm button',
          //   value: 1,
          // });
          navigate('/link-account');
          return;
        } else {
          logEvent({
            category: 'OTP Verification page',
            action: 'FIP Verification & Consent Submission',
            label:
              'Unique Customers/Sessions where customer enters 6-digits otp and clicks on Submit Consent button. ',
            value: 1,
          });
          const consent = await provideConsent(authenticateResponse.fip_NewDiscoverelist);

          if (consent) {
            setLoader({
              ...loader,
              status: true,
              info: 'Thank you!',
              moreInfo: 'Consent is received successfully.',
              subInfo: `You will be led to ${
                decrypt!.fiuName
              } website to continue your journey.`,
            });
            closeAndRedirect({
              url: decrypt?.redirect,
              parentStatusMessage: 'ACCEPTED',
              decrypt,
            });
          }
        }
      } else {
        if (addNumberFlow) {
          if (decrypt.mobile === phoneNumber) {
            logEvent({
              category: 'OTP Verification by FIP page',
              action: 'Linking & Submit Consent ',
              label:
                'Unique Customer user_id/session id on entering OTP and clicking on Confirm button',
              value: 0,
            });
            // } else {
            //   logEvent({
            //     category: 'OTP Verification by FIP page',
            //     action: 'New Mobile No. Verification',
            //     label:
            //       'Unique Customer user_id/session id on entering OTP and clicking on Confirm button',
            //     value: 0,
            //   });
            // }
          } else {
            logEvent({
              category: 'OTP Verification page',
              action: 'FIP Verification & Consent Submission',
              label:
                'Unique Customers/Sessions where customer enters 6-digits otp and clicks on Submit Consent button. ',
              value: 0,
            });
          }

          resetForm();
          setHasError(true);
          setMessage('Invalid OTP! Try again.');
          setLoader({ ...loader, status: false });
        }
      }
    } catch (e) {
      setLoader({ ...loader, status: false });
      setSubmitting(false);
    }
  };

  const provideConsent = async (authenticatedBanks: any) => {
    try {
      let FIPDetailsList: FIPDetailsList[] = [];

      checkedBank.forEach((bank: IndBank) => {
        FIPDetailsList.push({
          CUSTID: phoneNumber,
          FIPID: bank.FIPID,
          FIPACCREFNUM: bank.FIPACCREFNUM,
          LINKINGREFNUM: authenticatedBanks.find(
            (authBank: FipNewDiscoverelist) =>
              authBank.FIPACCREFNUM === bank.FIPACCREFNUM,
          ).FIPACCLINKREF,
          LINKEDDATE: bank.LINKEDDATE,
          FIPACCTYPE: bank.FIPACCTYPE,
          FIPACCNUM: bank.FIPACCNUM,
          FITYPE: bank.FITYPE,
          CONSENTCOUNT: checkedBank.length,
          CONSENTDATE: new Date(),
          LOGO: bank.Logo,
          FIPNAME:
            bank.FIPNAME ||
            authenticatedBanks.find(
              (authBank: FipNewDiscoverelist) =>
                authBank.FIPACCREFNUM === bank.FIPACCREFNUM,
            ).FIPNAME,
          FIPACCLINKREF: authenticatedBanks.find(
            (authBank: FipNewDiscoverelist) =>
              authBank.FIPACCREFNUM === bank.FIPACCREFNUM,
          ).FIPACCLINKREF,
          isCardSelected: true,
          StateName: bank.StateName
        });
      });

      const consentBody: ConsentArtefactRequestBody = {
        I_MOBILENUMBER: phoneNumber,
        I_MPIN: '111111',
        I_BROWSER: browserName,
        I_ConsentHandle: decrypt?.srcref,
        FIPDetailsList: FIPDetailsList,
      };
      const consentResponse = await ConsentArtefact(consentBody);
      // if (consentResponse.RESULT_CODE === '200') {
      // Do nothing
      // }
      return consentResponse.RESULT_CODE === '200';
    } catch {
      return false;
    }
  };

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, []);

  const onResend = () => {
    try {
      callLinkAPI();
    } catch (err: any) {
      alert(err.message);
    }
  };

  const callLinkAPI = async () => {
    stopTimer();
    setLoader({ ...loader, status: true, info: 'Resending OTP' });
    let ACCOUNTS_TO_LINK: ACCOUNTSTOLINK[] = [];
    checkedBank.forEach((bank: any) => {
      ACCOUNTS_TO_LINK.push({
        FIPACCNUM: bank.FIPACCNUM,
        FIPACCREFNUM: bank.FIPACCREFNUM,
        FIPACCTYPE: bank.FIPACCTYPE,
        FIPTYPE: bank.FIPTYPE,
        FIPID: bank.FIPID,
        Logo: bank.Logo,
      });
    });
    const handleBody: LinkAccountRequestBody = {
      I_MOBILENUMBER: phoneNumber,
      I_BROWSER: browserName,
      I_FIPID: checkedBank[0].FIPID,
      I_ConsentHandle: dynData,
      ACCOUNTS_TO_LINK: ACCOUNTS_TO_LINK,
    };
    const linkResponse = await LinkBankAccount(handleBody);
    if (linkResponse.RESULT_CODE === '200') {
      setLinkResponse(linkResponse);
      setRefNumber(linkResponse.RefNumber);
      resetTimer();
    }
    setLoader({ ...loader, status: false });
  };

  return (
    <Grid
      component={'main'}
      sx={{
        background: '#fff',
        minHeight: '100vh',
        maxWidth: '500px',
        margin: 'auto',
      }}
    >
      <Header title="OTP Verification" />
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => (
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <Grid component={'section'} sx={{ padding: '24px 16px 150px' }}>
              <LoginParagraph sx={{ fontWeight: 400 }}>
                You will receive an OTP from Bank/FIP
              </LoginParagraph>
              <Typography
                sx={{
                  fontSize: 21,
                  fontWeight: 700,
                  color: '#333',
                  marginBottom: '45px',
                }}
              >
                +91 {phoneNumber || ''}
              </Typography>

              <FormOTPInput
                name="otp"
                otpLength={otpLength}
                label="Enter OTP"
                isVerified={isVerified}
                message={message}
                hasError={hasError}
                onSubmit={() => {
                  handleSubmit();
                }}
              />
              <ResendOtp counter={timer} onResendOtp={onResend} />

              <Box
                sx={{
                  background: '#fff',
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
                  borderRadius: '3px',
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '40px',
                }}
              >
                <img
                  style={{ marginRight: '10px' }}
                  src="/images/bulb-icon.svg"
                  alt="hint"
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#333',
                    lineHeight: '21px',
                    fontWeight: 400,
                    padding: '5px 0px',
                  }}
                >
                  You can manage your consent anytime by downloading{' '}
                  <span style={{ fontWeight: 700 }}>CAMSfinserv App</span>, available both
                  on Play Store and App Store
                </Typography>
              </Box>
            </Grid>
            <Grid
              sx={{
                position: 'fixed',
                left: 0,
                bottom: 0,
                background: '#fff',
                boxShadow: '0px -4px 3px rgb(226 226 226)',
                paddingTop: '10px',
                right: 0,
                width: '100%',
                maxWidth: '500px',
                margin: 'auto',
              }}
            >
              <Grid sx={{ padding: '20px 16px' }}>
                <CustomButton
                  label="Confirm"
                  type="submit"
                  onClick={() => navigate('/summary')}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>

      <RedirectingModal
        info={loader.info}
        moreInfo={loader.moreInfo}
        subInfo={loader.subInfo}
        opened={loader.status}
        setModal={(type: any) => setLoader({ ...loader, status: type })}
      />
    </Grid>
  );
}
