import { Box, Button, Drawer, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import {
  ACCOUNTSTOLINK,
  AuthenticateToken,
  AuthenticateTokenRequestBody,
  discoverAlreadyLinkedAccount,
  IndBank,
  LinkAccountRequestBody,
  LinkBankAccount,
} from '../../api/banks';
import { IMAGE_BASE_URL } from '../../api/urls';
import { useTimer } from '../../hooks/useTimer';
import { browserName } from '../../lib/helper';
import { RootStateType } from '../../store/reducers';
import { AuthState } from '../../store/types/login';
import { FormOTPInput } from '../forms/FormOTPInput';
import SubmitButton from '../forms/FormSubmitButton';
import { ResendOtp } from '../otpField';
import RedirectingModal from '../RedirectingModal';
import { activeCategorySelector } from '../../store/selectors/multiCategory';

const OtpDrawer = ({
  checkedBank,
  phoneNumber,
  refNumber: refNumberProp,
  selectedFIP,
  setIsOpen,
  updateLinkedAccounts,
  isOpen,
  onLinkAndSubmit,
}: OtpDrawerProps) => {
  const initialValues: Values = { otp: '' };
  
  const { timer, startTimer, stopTimer, resetTimer } = useTimer({});
  // const dispatch = useDispatch();
  const activeCategory = useSelector(activeCategorySelector);
 // console.log("active",activeCategory)
  const isAlphaNumeric = activeCategory === 'GSTR' ? true : false
  console.log("isAlphaNumeric",isAlphaNumeric)
  const [loader, setLoader] = useState({
    status: false,
    info: '',
    subInfo: '',
    moreInfo: '',
  });

  const [isVerified, setIsVerified] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState('');

  const [refNumber, setRefNumber] = useState<string>('');

  const { addNumberFlow, dynData } = useSelector<RootStateType, AuthState>(
    (globalState) => globalState.auth,
  );

  useEffect(() => {
    setRefNumber(refNumberProp);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const otpLength = (import.meta.env.VITE_EIGHT_DIGIT_OTP || '')
    .split(',')
    .includes(selectedFIP)
    ? 8 : (import.meta.env.VITE_FOUR_DIGIT_OTP || '').split(',').includes(selectedFIP) ? 4 
    : 6 ;

  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .min(otpLength, `OTP should be ${otpLength} characters`)
      .required('OTP is required'),
  });

  interface Values {
    otp: string;
  }

  const onSubmit = async (
    values: Values,
    { setSubmitting, resetForm }: FormikHelpers<Values>,
  ) => {
    try {
      setSubmitting(false);
      setLoader({ ...loader, status: true, info: 'Validating OTP' });
      setHasError(false);
      setMessage('');
      const authenticateBody: AuthenticateTokenRequestBody = {
        I_MOBILENUMBER: phoneNumber,
        I_BROWSER: browserName,
        I_FIPID: checkedBank[0].FIPID,
        I_FIPACCREFNUM: refNumber,
        I_MOBOTP: values.otp,
        I_ConsentHandle: dynData
      };

      const authenticateResponse = await AuthenticateToken(authenticateBody);
      setLoader({ ...loader, info: 'Updating Accounts' });
      if (authenticateResponse.RESULT_CODE === '200') {
        const linkedAccounts = await discoverAlreadyLinkedAccount({
          I_BROWSER: browserName,
          I_MOBILENUMBER: phoneNumber,
          I_ConsentHandle: dynData
        });
        const verifiedAccounts = authenticateResponse.fip_NewDiscoverelist;
        updateLinkedAccounts({
          verifiedAccounts,
          linkedAccounts: linkedAccounts.lst,
        });

        setIsVerified(true);
        setMessage('Verified Successfully');
        onLinkAndSubmit?.(verifiedAccounts,linkedAccounts.lst);
        setIsOpen(false);
      } else {
        if (addNumberFlow) {
          resetForm();
          setHasError(true);
          setMessage('Invalid OTP! Try again.');
          setLoader({ ...loader, status: false });
        }
      }
    } catch (e) {
      setLoader({ ...loader, status: false });
      setSubmitting(false);
    } finally {
      setLoader({ ...loader, status: false });
    }
  };

  const onResend = () => {
    try {
      setHasError(false);
      callLinkAPI();
    } catch (err: any) {
      alert(err.message);
    }
  };

  const callLinkAPI = async () => {
    try {
      stopTimer();
      setLoader({ ...loader, status: true, info: 'Resending OTP' });
      let ACCOUNTS_TO_LINK: ACCOUNTSTOLINK[] = [];
      checkedBank.forEach((bank: any) => {
        ACCOUNTS_TO_LINK.push({
          FIPACCNUM: bank.FIPACCNUM,
          FIPACCREFNUM: bank.FIPACCREFNUM,
          FIPACCTYPE: bank.FIPACCTYPE,
          FIPTYPE: bank.FITYPE,
          FIPID: bank.FIPID,
          Logo: bank.Logo,
        });
      });

      const handleBody: LinkAccountRequestBody = {
        I_MOBILENUMBER: phoneNumber,
        I_BROWSER: browserName,
        I_FIPID: checkedBank[0].FIPID,
        I_ConsentHandle: dynData,
        ACCOUNTS_TO_LINK: ACCOUNTS_TO_LINK,
      };

      const linkResponse = await LinkBankAccount(handleBody);
      if (linkResponse.RESULT_CODE === '200') {
        setRefNumber(linkResponse.RefNumber);
        resetTimer();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader({ ...loader, status: false });
    }
  };

  return (
    <Drawer
      open={isOpen}
      anchor="bottom"
      PaperProps={{
        elevation: 0,
        style: { borderRadius: '25px 25px 0 0', maxWidth: '500px' },
      }}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '500px',
          margin: 'auto',
        },
      }}
    >
      <RedirectingModal
        info={loader.info}
        moreInfo={loader.moreInfo}
        subInfo={loader.subInfo}
        opened={loader.status}
      />
      <Box
        style={{
          display: 'flex',
          backgroundColor: '#EBF6FF',
          fontWeight: 700,
          padding: '12px 0',
          justifyContent: 'space-around',
        }}
      >
        <Box display="flex" width={'100%'}>
          <Box width="50%" />
          <Box display="flex" width="100%" justifyContent={'space-between'}>
            <Typography
              component="p"
              style={{
                lineHeight: '2.5',
                color: '#00418C',
                fontWeight: '600',
                fontSize: '19px',
              }}
            >
              OTP Verification
            </Typography>
            <Button
              sx={{
                textTransform: 'unset',
                color: '#F57071',
              }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img
                src="/images/Close.svg"
                style={{ color: '#00418C' }}
                alt="Close Drawer"
              />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box style={{ padding: '0 10px' }}>
        <Box>
          <img
            src={
              checkedBank[0].Logo
                ? IMAGE_BASE_URL + checkedBank[0].Logo
                : '/images/Bank Icon.svg'
            }
            alt="Bank"
            width="100px"
            height={'75px'}
            style={{ padding: '10px', marginTop: '10px' }}
          />
        </Box>
        <Typography
          color="InfoText"
          align="justify"
          sx={{ fontWeight: '500', m: 1.5, mb: 0 }}
        >
          OTP has been sent to your mobile number
        </Typography>
        <Typography
          variant="h6"
          color="InfoText"
          sx={{ fontWeight: '700' }}
          align="left"
          padding="10px"
        >
          {`+91 ${phoneNumber}`}
        </Typography>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              style={{ padding: '5px' }}
            >
              <FormOTPInput
                name="otp"
                label="Enter OTP"
                isVerified={isVerified}
                otpLength={otpLength}
                message={message}
                hasError={hasError}
                isAlphaNumeric={isAlphaNumeric}
                maxLength={1}
                onSubmit={() => {
                  handleSubmit();
                }}
              />
              <ResendOtp counter={timer} startTimer={startTimer} onResendOtp={onResend} />
              <Box sx={{ mt: 2 }}>
                <SubmitButton
                  label="Confirm"
                  onSubmit={() => handleSubmit()}
                  disabled={true}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '4px',
                  }}
                >
                  <img
                    src="/images/lock.svg"
                    alt="encrypted"
                    style={{ paddingBottom: '2px' }}
                  />
                  <Typography
                    sx={{
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    All your information will be end-to-end encrypted
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
    </Drawer>
  );
};

export default OtpDrawer;

export type OtpDrawerProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  checkedBank: Array<IndBank>;
  selectedFIP: string;
  phoneNumber: string;
  refNumber: string;
  updateLinkedAccounts: any;
  onLinkAndSubmit?: any;
};
