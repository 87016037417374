import { Box, Drawer, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ConsentRejectRequestBody, rejectConsent } from '../../api/banks';
import { browserName, closeAndRedirect, logEvent } from '../../lib/helper';
import { RootStateType } from '../../store/reducers';
import { AuthState } from '../../store/types/login';
import { CustomButton } from '../login/components';
import RedirectingModal from '../RedirectingModal';

export default function RejectBottomDrawer({
  confirm,

  setConfirm,
  type,
  sentvalue,
}: {
  confirm: boolean;
  type: string;
  sentvalue: 0 | 1;
  setConfirm: any;
  closeDrawer: any;
}) {
  const { decrypt, consentData } = useSelector<RootStateType, AuthState>((state) => state.auth);

  const [loader, setLoader] = React.useState({
    status: false,
    info: '',
    subInfo: '',
    moreInfo: '',
  });

  const handleReject = async () => {
    const requests = consentData.map((consentvalue: any)=> {
      return rejectConsentCall(consentvalue);
    })
    console.log("requests",requests)
    const responses = await Promise.all(requests)
    console.log("response",responses)
    let success = responses?.filter((response: any)=>{
      return response.RESULT_CODE === "200"
    })
    if(success.length >= 1){
      closeAndRedirect({
        parentStatusMessage: 'REJECTED',
        delay: true,
        decrypt,
        url: decrypt?.redirect,
       });
    }
  }

  const rejectConsentCall = async (consentvalue: any) => {
    const rejectBody: ConsentRejectRequestBody = {
      I_MOBILENUMBER: decrypt!.mobile,
      I_BROWSER: browserName,
      I_ConsentId: consentvalue,
      I_STATUS: 'REJECTED',
    };
    const rejectResponse = await rejectConsent(rejectBody);
    return rejectResponse;
    // if (rejectResponse) {
    //   closeAndRedirect({ parentStatusMessage: 'REJECTED', delay: true, decrypt, url : decrypt?.redirect });
    // }
  };

  return (
    <Box>
      <Drawer
        anchor={'bottom'}
        open={confirm}
        onClose={() => {
          setConfirm(false);
        }}
        PaperProps={{ elevation: 0, style: { borderRadius: '25px 25px 0 0' } }}
        sx={{
          '& .MuiPaper-root': {
            // margin: 0,
            width: '100%',
            maxWidth: '500px',
            margin: 'auto',
          },
        }}
      >
        <Box sx={{ padding: '34px 30px' }}>
          <Grid
            container
            direction="column"
            display="flex"
            spacing={4}
            sx={{ width: '100%', margin: 0, marginBottom: '20px' }}
          >
            <Grid item style={{ paddingLeft: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignContent: 'center',
                  color: 'black',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    marginTop: '-24px',
                    fontWeight: '700',
                    marginBottom: '16px',
                    fontSize: '19px',
                  }}
                  variant="body1"
                >
                  Are you sure to reject consent?
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                    color: '#777F88',
                  }}
                  variant="body2"
                >
                  By rejecting consent, you are required to upload a proof document for
                  verification which is not recommended to process your application
                  quickly.
                </Typography>
              </Box>
            </Grid>
            <Grid item style={{ paddingLeft: 0 }}>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  gap: '16px',
                }}
              >
                <CustomButton
                  label="Reject"
                  variant="outlined"
                  disabled={false}
                  style={{
                    margin: 0,
                    maxHeight: '48px',
                    fontSize: '16px',
                    width: '55%',
                  }}
                  onClick={() => {
                    setLoader({
                      ...loader,
                      status: true,
                      info: type === 'reject' ? 'Your consent has been rejected' : '',
                      moreInfo: '',
                      subInfo: `You will be redirected to ${decrypt!.fiuName}.`,
                    });
                    if (sentvalue === 1) {
                      logEvent({
                        category: 'Link Account Page',
                        action: 'Consent Rejection',
                        label:
                          'Unique Customer user_id/session id where customer clicks on the Reject button and then chooses "Yes,Reject" option on the pop-up',
                        value: 1,
                      });
                    } else {
                      logEvent({
                        category: 'Summary Page',
                        action: 'Consent Rejection',
                        label:
                          'Unique Customer user_id/session id where customer clicks on the Reject button and then chooses "Yes,Reject" option on the pop-up',
                        value: 0,
                      });
                    }
                    setConfirm(false);
                    if (type === 'reject') {
                      handleReject();
                    } else {
                      closeAndRedirect({
                        parentStatusMessage: 'REJECTED',
                        delay: false,
                        decrypt,
                        url : decrypt?.redirect
                      });
                    }
                  }}
                />
                <CustomButton
                  label={'Cancel'}
                  style={{ margin: 0, maxHeight: '48px', fontSize: '16px' }}
                  onClick={() => {
                    setConfirm(false);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <RedirectingModal
        info={loader.info}
        moreInfo={loader.moreInfo}
        subInfo={loader.subInfo}
        opened={loader.status}
        setModal={(type: any) => setLoader({ ...loader, status: type })}
      />
    </Box>
  );
}
