import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConsentArtefact,
  ConsentArtefactRequestBody,
  FIPDetailsList,
} from '../../api/banks';
import {
  browserName,
  closeAndRedirect,
  getFipDetailsList,
  logEvent,
} from '../../lib/helper';
import { RootStateType } from '../../store/reducers';
import {
  activeCategorySelector,
  consolidatedAccountsSelector,
  discoveredCategoriesSelector,
} from '../../store/selectors/multiCategory';
import { AuthState } from '../../store/types/login';
import BottomDrawer from '../BottomDrawer/BottomDrawer';
import { Loader, LoaderProps } from '../CustomComponents';
import { CustomButton } from '../login/components';

export const NoAccounts = ({
  retryDiscovery: retryDiscovery,
}: {
  // eslint-disable-next-line no-unused-vars
  retryDiscovery: (arg1: boolean, arg2: boolean) => Promise<void>;
}) => {
  const dispatch = useDispatch();

  const { decrypt } = useSelector<RootStateType, AuthState>((state) => state.auth);
  const activeCategory = useSelector(activeCategorySelector);
  const discoveredCategories = useSelector(discoveredCategoriesSelector);
  const consolidatedAccounts = useSelector(consolidatedAccountsSelector) || [];

  const selectedAndLinkedAccounts = consolidatedAccounts.filter(
    (acc) => acc.LINKEDDATE && acc.isChecked,
  );

  const [loader, setLoader] = useState<LoaderProps>({
    isOpen: false,
    info: '',
  });

  const length = discoveredCategories.length;
  const isFinalCategory = discoveredCategories[length - 1] === activeCategory;

  const retry = async () => {
    await retryDiscovery(true, true);
  };

  const skip = () => {
    const nextCategoryIndex =
      discoveredCategories?.findIndex((cat) => cat === activeCategory) + 1;

    dispatch({
      type: 'CHANGE_ACTIVE_CATEGORY',
      body: { category: discoveredCategories[nextCategoryIndex] },
    });
  };

  const submitConsent = async () => {
    setLoader({
      isOpen: true,
      info: 'Submitting Consent',
    });
    const FIPDetailList: FIPDetailsList[] = getFipDetailsList(
      consolidatedAccounts,
      decrypt?.mobile,
    );

    const consentBody: ConsentArtefactRequestBody = {
      I_MOBILENUMBER: decrypt!.mobile,
      I_MPIN: '111111',
      I_BROWSER: browserName,
      I_ConsentHandle: decrypt?.srcref,
      FIPDetailsList: FIPDetailList,
    };
    const consentResponse = await ConsentArtefact(consentBody);
    if (consentResponse.RESULT_CODE === '200') {
      logEvent({
        category: 'Link Account Page',
        action: 'Provide Consent',
        label:
          'Unique Customer user_id/session id where customer clicks on Provide Consent button if no accounts are available for given mob no and only linked accounts are found',
        value: 1,
      });
      setLoader({
        isOpen: true,
        info: 'Thank You!',
        moreInfo: 'Consent is received successfully.',
        subInfo: `You will be led to ${
          decrypt!.fiuName
        } website to continue your journey.`,
      });
      closeAndRedirect({
        url: decrypt?.redirect,
        parentStatusMessage: 'ACCEPTED',
        decrypt,
      });
    } else {
      closeAndRedirect({ parentStatusMessage: 'REJECTED', decrypt });
    }
  };

  const [drawer, toggleDrawer] = useState<boolean>(false);

  return (
    <Grid component={'section'} sx={{ padding: '24px 16px 0px', marginTop: '100px' }}>
      <Loader {...loader} />
      <Grid sx={{ textAlign: 'center', marginTop: '0px' }}>
        <img
          style={{ margin: 'auto', marginBottom: '5px' }}
          src="/images/no-account.svg"
          alt="icon"
        />
        <Typography
          sx={{
            fontSize: '21px',
            color: '#204288',
            fontWeight: 700,
            marginBottom: '5px',
          }}
        >
          Oops!
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#666', fontWeight: 400 }}>
          Either server is down or
          <br />
          below mobile number is not associated with your{' '}
          {activeCategory === 'BANK'
            ? 'bank'
            : activeCategory === 'MF'
            ? 'folios'
            : activeCategory === 'NPS'
            ? 'NPS account'
            : 'Account'}
          <p style={{ fontWeight: 700 }}>+91 {decrypt?.mobile}</p>
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop="5px"
      >
        <Button
          sx={{
            borderRadius: '3px',
            border: '2px solid #00418C',
            maxWidth: '500px',
            color: '#00418C',
            boxShadow: 'none',
            marginBottom: '10px',
            textTransform: 'unset',
            textAlign: 'center',
            backgroundColor: '#fff',
            padding: '16px 16px',
            height: '32px',
            '&:disabled': {
              backgroundColor: '#B3BAC5',
              color: 'white',
            },
          }}
          startIcon={<img src="/images/refresh.svg" alt="refresh" />}
          onClick={retry}
        >
          Try Again
        </Button>
      </Grid>

      <Box
        style={{
          position: 'fixed',
          bottom: 10,
          left: 0,
          right: 0,
          //zIndex: 99,
          width: '90%',
          maxWidth: 460,
          margin: 'auto',
          paddingBottom: '20px',
        }}
      >
        {isFinalCategory && !!consolidatedAccounts.length ? (
          <Button
            style={{ marginLeft: '110px', marginBottom: '20px' }}
            onClick={submitConsent}
            disabled={!selectedAndLinkedAccounts.length}
          >
            {'Submit Consent'}
          </Button>
        ) : !isFinalCategory ? (
          <Button
            style={{ marginLeft: '144px', marginBottom: '20px', marginTop: '20px' }}
            onClick={skip}
          >
            {'Skip >>'}
          </Button>
        ) : null}
        <CustomButton
          label="Discover with Alternate Mobile Number"
          onClick={() => toggleDrawer(true)}
          style={{ fontSize: '16px' }}
        />
      </Box>

      <BottomDrawer setFlag={toggleDrawer} flag={drawer} />
    </Grid>
  );
};
