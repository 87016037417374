import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  Grid,
  SvgIcon,
  SvgIconProps,
  Typography,
} from '@mui/material';

import { IMAGE_BASE_URL } from '../../api/urls';

export function CheckboxUncheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="17" height="17" viewBox="0 0 17 17">
      <circle cx="8.66524" cy="8.33467" r="7.83467" fill="white" stroke="#AAAAAA" />
    </SvgIcon>
  );
}

export function CheckboxCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="17" height="17" viewBox="0 0 17 17" fill="none">
      {/* <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /> */}
      <circle cx="8.66524" cy="8.33467" r="8.33467" />
      <path
        d="M4.17822 8.30196L7.1914 11.3151L13.1523 5.35425"
        stroke="white"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default function BankSelectionCheckbox({
  logo,
  bankName,
  accountNo,
  accountType = 'savings',
  checked,
  linked = false,
  onChange,
}: {
  logo: string;
  bankName: string;
  accountNo: string;
  accountType?: string;
  checked?: boolean;
  linked?: boolean;
  onChange?: any;
}) {
  return (
    <Box
      sx={{
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#fff',
        borderRadius: '3px',
        padding: '15px 10px',
        marginBottom: '20px',
      }}
      onClick={() => onChange(!checked)}
    >
      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
        <Card
          sx={{
            width: '100%',
            maxWidth: '72px',
            height: '40px',
            margin: 'auto 0',
            padding: '12px 8px',
            borderRadius: '3px',
            background: '#FFFFFF',
            boxShadow: '0px 0px 1px  rgba(0, 0, 0, 0.25)',
            marginRight: '15px',
          }}
        >
          <CardMedia
            sx={{
              margin: 'auto',
              objectFit: 'contain',
              width: 'auto',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
            component="img"
            image={IMAGE_BASE_URL + logo}
            alt="logo"
          />
        </Card>
        <Grid sx={{ flexGrow: 1 }}>
          <Typography
            sx={{
              fontSize: '16px',
              color: '#333',
              fontWeight: 500,
              marginBottom: '7px',
              lineHeight: '18px',
            }}
          >
            {bankName}{' '}
            {linked && (
              <span
                style={{
                  fontSize: '14px',
                  color: '#00A859',
                  fontWeight: 400,
                  display: 'inline-block',
                }}
              >
                {' '}
                (Already linked)
              </span>
            )}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              color: '#666',
              fontWeight: 400,
              marginBottom: 0,
              lineHeight: '16px',
            }}
          >
            {accountType === 'SAVINGS' ? 'Savings ' : 'Current '} Acc. - {accountNo}
          </Typography>
        </Grid>
        <Grid>
          <Checkbox
            icon={<CheckboxUncheckedIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
            checked={checked}
            color="primary"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
