import { Button, ButtonProps, CardMedia, Grid, Typography } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';

import { IMAGE_BASE_URL } from '../../api/urls';
import { RootState, RootStateType } from '../../store/reducers';
import { BankState } from '../../store/types/bankListType';
import { AuthState } from '../../store/types/login';

const Item = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 36,
  [theme.breakpoints.up('sm')]: {
    fontSize: 48,
  },
  color: theme.palette.common.white,
}));

export const LoginParagraph = styled(Typography)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: 16,
  color: '#333333',
  marginBottom: 8,
  lineHeight: '22px',
  fontWeight: 500,
});

export const LoginLogo = (): JSX.Element => {
  const { decrypt } = useSelector<RootStateType, AuthState>((state) => state.auth);
  const { consumer } = useSelector<RootState, BankState>((state) => state.bank);
  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        display: 'flex',
        mb:4,
      }}
    >
      {/* <CardMedia
        sx={{
          height: 24,
          objectFit: 'unset',
          width: '45%',
          margin: 'auto',
          mb: 4,
        }}
        component="img"
        image={consumer.logo ? IMAGE_BASE_URL + consumer.logo : '/images/Dummy Logo.svg'}
        alt="logo"
      />

      <CardMedia
        sx={{
          height: 24,
          objectFit: 'unset',
          margin: 'auto',
          mb: 4,

          width: '10%',
        }}
        component="img"
        image="/images/Divider.svg"
        alt="logo"
      /> */}
      {/* <CardMedia
        sx={{
          height: '48px',
          objectFit: 'unset',
          width: '45%',
          margin: 'auto',
          mb: 4,
        }}
        component="img"
        image={decrypt?.logo ? IMAGE_BASE_URL + decrypt?.logo : "/images/logo.svg"}
        alt="logo"
      /> */}
       <img src={decrypt?.logo ? IMAGE_BASE_URL + decrypt?.logo : "/images/logo.svg"} alt=""
       className='multi-img' />
    </Box>
  );
};

export const LoginBanner = (): JSX.Element => {
  return (
    <Grid container item direction="column" justifyContent="center">
      <Grid item container xs="auto" direction="column">
        <Item>
          <CardMedia
            sx={{
              objectFit: 'unset',
              maxWidth: 250,
              margin: 'auto',
              mb: 2,
            }}
            component="img"
            image="/images/login-banner.svg"
            alt="logo"
          />
        </Item>
      </Grid>
    </Grid>
  );
};

export function PoweredBy() {
  return (
    <Box sx={{ background: '#f5f8f8', textAlign: 'center', padding: '5px', width: '100%' }}>
      <Typography
        sx={{ fontSize: 12, color: '#333', fontWeight: 500, display: 'inline-flex' }}
      >
        Powered by &nbsp;
        <img src="/images/cams-finserv.svg" alt="icon" />
      </Typography>
    </Box>
  );
}

export function CustomHeading({ info, style }: { info: string; style?: any }) {
  return (
    <Typography
      variant="h6"
      sx={{ color: 'primary.main', fontSize: 18, fontWeight: 500 }}
      style={style}
    >
      {info}
    </Typography>
  );
}

export function CustomButton({
  label = 'submit',
  variant = 'contained',
  ...props
}: ButtonProps & { label: string }) {
  return (
    <Button
      variant={variant}
      {...props}
      sx={{
        borderRadius: '3px',
        border: variant === 'outlined' ? '2px solid primary.main' : '',
        display: 'block',
        width: '100%',
        maxWidth: '500px',
        margin: 'auto',
        padding: '10px 16px',
        color: variant === 'outlined' ? 'primary.main' : '#fff',
        boxShadow: 'none',
        marginBottom: '10px',
        textTransform: 'unset',
        fontSize: '18px',
        fontWeight: 700,
        textAlign: 'center',
        backgroundColor: variant === 'outlined' ? 'transparent' : 'primary.main',
        '&:disabled': {
          color: '#B3BAC5',
          border: '1px solid',
        },
        '&:hover': {
          border: variant === 'outlined' ? '2px solid' : '',
        },
      }}
    >
      {label}
    </Button>
  );
}

export function CustomBorderedButton({
  label = 'submit',
  ...props
}: ButtonProps & { label: string }) {
  return (
    <Button
      {...props}
      sx={{
        background: '#fff',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'primary.main',
        borderRadius: '3px',
        display: 'block',
        width: '100%',
        maxWidth: '500px',
        margin: 'auto',
        padding: '10px 16px',
        color: 'primary.main',
        boxShadow: 'none',
        marginBottom: '10px',
        textTransform: 'unset',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'center',
        '&:hover': {
          background: '#fff',
        },
      }}
    >
      {label}
    </Button>
  );
}
