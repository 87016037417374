/* eslint-disable no-unused-vars */
import { RootState } from '../reducers';
import { Account, FiCategory } from '../reducers/multiCategory';

export const activeCategorySelector = (globalState: RootState) => {
  const { multiCategory } = globalState;
  const { activeCategory = 'BANK' } = multiCategory;
  return activeCategory;
};

export const consolidatedAccountsSelector = (globalState: RootState) => {
  const { multiCategory } = globalState;
  const { discoveredCategories } = multiCategory;
  const consolidatedAccounts = discoveredCategories
    ?.map((cat) => multiCategory[cat])
    .flatMap((cat) => cat.accounts);
  return consolidatedAccounts;
};

export const alternateNumberSelector = (globalState: RootState) => {
  const { multiCategory } = globalState;
  const { activeCategory = 'BANK' } = multiCategory;
  const { alternateNumber = '' } = multiCategory[activeCategory];
  return alternateNumber;
};

export const discoveredCategoriesSelector = (globalState: RootState) => {
  const { multiCategory } = globalState;
  const { discoveredCategories = [] } = multiCategory;
  return discoveredCategories;
};

export const categoryStatusSelector = (globalState: RootState) => {
  const { multiCategory } = globalState;
  const { discoveredCategories = [], activeCategory } = multiCategory;

  const initialState: { [key in FiCategory]: Status } = {
    BANK: 'isInactive',
    MF: 'isInactive',
    NPS: 'isInactive',
    EQUITIES: 'isInactive',
    INSURANCE_POLICIES: 'isInactive',
    GSTR: 'isInactive',
  };
  const categoryStatus: { [key in FiCategory]: Status } = discoveredCategories.reduce(
    (accumulator, current) => {
      const categoryDetails = multiCategory[current];
      const { accounts } = categoryDetails;
      const nonLinkedAccounts = accounts.filter((acc) => !acc.LINKEDDATE);
      const linkedAccounts = accounts.filter((acc) => !!acc.LINKEDDATE);
      const selectedAccounts = accounts.filter((acc) => acc.isChecked && acc.LINKEDDATE);

      const status =
        current === activeCategory
          ? 'isActive'
          : !nonLinkedAccounts.length &&
            linkedAccounts.length === selectedAccounts.length &&
            !!linkedAccounts.length
          ? 'isCompletelyVerified'
          : !selectedAccounts.length
          ? 'isInactive'
          : 'isPartiallyVerified';
      accumulator[current] = status;
      return accumulator;
    },
    initialState,
  );

  return categoryStatus;
};

export const nonLinkedCategorySelector = (globalState: RootState) => {
  const { multiCategory } = globalState;
  const { discoveredCategories } = multiCategory;
  const emptyCategories = discoveredCategories?.filter((cat) => {
    const selectedAccounts = multiCategory[cat].accounts.filter(
      (acc) => acc.isChecked && acc.LINKEDDATE,
    );
    return !selectedAccounts.length;
  });
  return emptyCategories;
};

export type Status =
  | 'isActive'
  | 'isPartiallyVerified'
  | 'isCompletelyVerified'
  | 'isInactive';
