import { Search, WarningAmberRounded } from '@mui/icons-material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Button, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ConsentArtefact, ConsentArtefactRequestBody, FailedFips } from '../../api/banks';
import { browserName, closeAndRedirect, getFipDetailsList } from '../../lib/helper';
import { RootState, RootStateType } from '../../store/reducers';
import { MultiCategory } from '../../store/reducers/multiCategory';
import {
  activeCategorySelector,
  alternateNumberSelector,
  consolidatedAccountsSelector,
  nonLinkedCategorySelector,
} from '../../store/selectors/multiCategory';
import { AuthState } from '../../store/types/login';
import BottomDrawer from '../BottomDrawer/BottomDrawer';
import { Loader, LoaderProps, SkipBottomDrawer } from '../CustomComponents';
import { FooterButtons } from '../CustomComponents/FooterButtons';

export const PanRequired = ({
  retryDiscovery: retryDiscovery,
  noNewAccounts = false,
  toggleRejectDrawer,
}: {
  noNewAccounts: boolean;
  // eslint-disable-next-line no-unused-vars
  retryDiscovery: (arg1?: FailedFips) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  toggleRejectDrawer: (open: boolean) => void;
}) => {
  const dispatch = useDispatch();

  const { decrypt } = useSelector<RootStateType, AuthState>((state) => state.auth);
  const { pan } = decrypt;

  const { failedFips } = useSelector<RootStateType, MultiCategory>(
    (state) => state.multiCategory,
  );
  const phoneNumber = useSelector(alternateNumberSelector);

  const prevDecryptPanRef = useRef(pan);
  const activeCategory = useSelector(activeCategorySelector);
  const {
    [activeCategory]: { accounts },
  } = useSelector<RootState, MultiCategory>((globalState) => globalState.multiCategory);
  const consolidatedAccounts = useSelector(consolidatedAccountsSelector) || [];
  const nonLinkedCategories = useSelector(nonLinkedCategorySelector);

  const [loader, setLoader] = useState<LoaderProps>({
    isOpen: false,
    info: '',
  });

  const CATEGORY_OPTIONS: any = [
    { label: 'Mutual Funds', value: 'MF' },
    { label: 'Stocks', value: 'EQUITIES' },
    { label: 'GST', value:'GSTR' }
  ];

  const retry = async () => {
    await retryDiscovery(failedFips);
  };

  const submitConsent = async (type: 'active' | 'consolidated') => {
    // const accountsToSubmit = type === 'active' ? accounts : consolidatedAccounts;
    const accountsToSubmit = consolidatedAccounts;
    try {
      setLoader({ isOpen: true, info: 'Submitting Consent' });
      const consentBody: ConsentArtefactRequestBody = {
        I_MOBILENUMBER: decrypt!.mobile,
        I_MPIN: '111111',
        I_BROWSER: browserName,
        I_ConsentHandle: decrypt?.srcref,
        FIPDetailsList: getFipDetailsList(
          accountsToSubmit.filter((acc) => !!acc.LINKEDDATE && acc.isChecked),
          decrypt?.mobile,
        ),
      };
      const response = await ConsentArtefact(consentBody);
      if (response.RESULT_CODE !== '200') throw new Error('Consent Rejected');
      setLoader({
        ...loader,
        isOpen: true,
        info: 'Thank You!',
        moreInfo: 'Consent is received successfully.',
        subInfo: `You will be led to ${
          decrypt!.fiuName
        } website to continue your journey.`,
      });
      closeAndRedirect({
        url: decrypt?.redirect,
        parentStatusMessage: 'ACCEPTED',
        decrypt,
      });
    } catch (error) {
      //TODO: Undo this change after demo
      setLoader({
        ...loader,
        isOpen: true,
        info: 'Thank You!',
        moreInfo: 'Consent is received successfully.',
        subInfo: `You will be led to ${
          decrypt!.fiuName
        } website to continue your journey.`,
      });
      closeAndRedirect({
        url: decrypt?.redirect,
        parentStatusMessage: 'ACCEPTED',
        decrypt,
      });
    }
  };

  const validationSchema = Yup.object({
    // not equal to previous pan
    pan: Yup.string()
      .matches(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/, 'Invalid PAN')
      .max(10, 'Max 10 characters allowed')
      .required('Required')
      .test('pan', 'Please Enter a new PAN', (value) => {
        if (value === prevDecryptPanRef.current) {
          return false;
        }
        return true;
      }),
  });

  const [drawer, toggleDrawer] = useState<boolean>(false);
  const [submitDrawerOpen, setSubmitDrawerOpen] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    await addToLocation(values.pan);
  };

  const addToLocation = async (pan: string) => {
    dispatch({
      type: 'UPDATE_PAN',
      body: { pan: pan },
    });
  };

  useEffect(() => {
    if (pan) {
      if (pan !== prevDecryptPanRef.current) {
        retry();
      }
    }
    prevDecryptPanRef.current = pan;
  }, [pan]);

let categorylabel : any;
let labelName : any;
 if(activeCategory === "MF" || activeCategory === "EQUITIES" || activeCategory === "GSTR"){
  categorylabel = CATEGORY_OPTIONS.filter((category:any) => activeCategory === category.value)
  labelName = categorylabel[0].label
  //console.log("labelName",labelName)
 }

  return (
    <Grid component={'section'} sx={{ padding: '24px 16px 0px' }}>
      <Loader {...loader} />
      <Grid sx={{ marginTop: '0px' }}>
           <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
           Please enter the PAN which is associated with the {labelName} for the following
           mobile number.
         </Typography>
        <Typography sx={{ fontWeight: 700, fontSize: 24, mt: 1, mr: 1 }} component="span">
          +91 {phoneNumber ? phoneNumber : decrypt?.mobile}
        </Typography>
        <ModeEditOutlineOutlinedIcon
          sx={{ textDecoration: 'underline', cursor: 'pointer', pt: 0.25 }}
          onClick={() => toggleDrawer(true)}
        />
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Formik
            initialValues={{ pan: '' }}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, isValid, errors, touched }) => (
              <Form>
                <InputLabel sx={{ fontSize: 13, fontWeight: 600 }}>
                  Enter your PAN
                </InputLabel>
                <Field name="pan">
                  {({ field }: { field: any }) => (
                    <TextField
                      fullWidth
                      name="pan"
                      variant="standard"
                      size="medium"
                      placeholder="e.g. ABCDE12345F"
                      required
                      {...field}
                      // error={meta.touched && !!meta.error}
                      error={touched.pan && !!errors.pan}
                      // helperText={
                      //   noNewAccounts
                      //     ? 'MF folio not found against your PAN!'
                      //     : meta.touched && meta.error
                      // }
                      InputProps={{
                        disableUnderline: true,
                        maxLength: 10,
                      }}
                      sx={{
                        p: 1,
                        bgcolor: 'white',
                        borderRadius: 1,
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        border: 0,
                        mt: 1,
                      }}
                    />
                  )}
                </Field>

                {touched.pan && errors.pan ? (
                  <Typography color="error" sx={{ fontSize: 12, my: 1 }}>
                    {errors.pan}
                  </Typography>
                ) : noNewAccounts ? (
                  <Typography color="error" sx={{ fontSize: 12, my: 1, display: 'flex' }}>
                    <WarningAmberRounded sx={{ fontSize: 16, mr: 0.5 }} />
                    MF folio not found against your PAN!
                  </Typography>
                ) : null}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  startIcon={<Search />}
                  disabled={isSubmitting || !isValid}
                  sx={{
                    mt: 2,
                    textTransform: 'none',
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  {activeCategory === 'MF' ? 'Search Folios' : 'Search Accounts'} 
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>

      <FooterButtons
        submitConsent={submitConsent}
        toggleRejectDrawer={toggleRejectDrawer}
        setSubmitDrawerOpen={setSubmitDrawerOpen}
      />
      <SkipBottomDrawer
        buttonLabelPrimary="Link More Accounts"
        buttonLabelSecondary="Submit Consent"
        isOpen={submitDrawerOpen}
        textPrimary={`${nonLinkedCategories?.join(',')} accounts are not linked.`}
        onClickPrimary={() => {
          setSubmitDrawerOpen(false);
        }}
        onClickSecondary={submitConsent}
        textSecondary={'Link more accounts to manage your wealth effectively.'}
        closeDrawer={() => setSubmitDrawerOpen(false)}
      />

      <BottomDrawer setFlag={toggleDrawer} flag={drawer} />
    </Grid>
  );
};
