import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { groupAccountsByFipId } from '../../lib/helper';
import { RootState } from '../../store/reducers';
import { MultiCategory } from '../../store/reducers/multiCategory';
import {
  activeCategorySelector,
  consolidatedAccountsSelector,
  discoveredCategoriesSelector,
  nonLinkedCategorySelector,
} from '../../store/selectors/multiCategory';
import { CustomButton } from '../login/components';

export const FooterButtons = ({
  submitConsent,
  setSubmitDrawerOpen,
  toggleRejectDrawer,
}: {
  // eslint-disable-next-line no-unused-vars
  submitConsent: any;
  setSubmitDrawerOpen: (open: boolean) => void; // eslint-disable-line no-unused-vars
  toggleRejectDrawer: any;
}) => {
  const consolidatedAccounts = useSelector(consolidatedAccountsSelector) || [];
  const discoveredCategories = useSelector(discoveredCategoriesSelector);
  const activeCategory = useSelector(activeCategorySelector);
  const nonLinkedCategories = useSelector(nonLinkedCategorySelector);
  const dispatch = useDispatch();
  const {
    [activeCategory]: { accounts },
  } = useSelector<RootState, MultiCategory>((globalState) => globalState.multiCategory);

  const groupedAccounts = groupAccountsByFipId(accounts);

  const fipIds = Object.keys(groupedAccounts);

  const isSingleFipAndCategory =
    fipIds.length === 1 &&
    discoveredCategories.length === 1 &&
    groupedAccounts[fipIds[0]].filter((acc) => acc.isChecked && !acc.LINKEDDATE).length;

  const isFinalCategory = discoveredCategories[length - 1] === activeCategory;
  const isFirstCategory = discoveredCategories[0] === activeCategory;
  const disableSubmit = !consolidatedAccounts.find(
    (acc) => acc.isChecked && acc.LINKEDDATE,
  );
  const primaryLabel = isFinalCategory ? 'Reject' : 'Submit Consent';
  const secondaryLabel = isSingleFipAndCategory
    ? 'Link and Submit'
    : isFinalCategory
    ? 'Submit Consent'
    : 'Next';

  const navigateToNextCategory = () => {
    const index = discoveredCategories.findIndex((cat) => cat === activeCategory);
    const nextCategory = discoveredCategories[index + 1];
    dispatch({
      type: 'CHANGE_ACTIVE_CATEGORY',
      body: { category: nextCategory },
    });
  };
  return (
    <Box
      style={{
        position: 'fixed',
        bottom: 24,
        left: 0,
        right: 0,
        //zIndex: 99,
        width: '100%',
        maxWidth: 500,
        margin: 'auto',
        backgroundColor: '#f5f8f8',
        //backgroundColor: 'primary.main',
        padding: '20px 16px',
        marginBottom: '0',
      }}
    >
      <Typography
        sx={{
          marginBottom: '5px',
          textAlign: 'center',
          fontSize: '0.8rem',
        }}
      >
        Consent can be submitted only for Linked Account(s)
      </Typography>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          gap: '16px',
        }}
      >
        <CustomButton
          label={primaryLabel}
          variant="outlined"
          disabled={isFinalCategory ? false : disableSubmit}
          style={{
            margin: 0,
            maxHeight: '48px',
            fontSize: '16px',
            width: isFinalCategory ? '55%' : '',
          }}
          onClick={
            isFinalCategory
              ? toggleRejectDrawer
              : nonLinkedCategories?.length
              ? () => setSubmitDrawerOpen(true)
              : () => submitConsent(isFirstCategory ? 'active' : 'consolidated')
          }
        />
        <CustomButton
          label={secondaryLabel}
          disabled={isFinalCategory && !isSingleFipAndCategory ? disableSubmit : false}
          style={{ margin: 0, maxHeight: '48px', fontSize: '16px' }}
          onClick={
            isFinalCategory
              ? isSingleFipAndCategory
                ? () => submitConsent('active') //re
                : nonLinkedCategories?.length
                ? () => setSubmitDrawerOpen(true)
                : () => submitConsent('consolidated')
              : navigateToNextCategory
          }
        />
      </Grid>
    </Box>
  );
};
